import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, ButtonToolbar, Col, Form } from 'react-bootstrap';
import { useAppDispatch } from '../../hooks';
import {
  addWarning,
  CreateNotificationModel,
} from '../../notifications/notificationSlice';
import { useHistory } from 'react-router';
import qs from 'qs';

export interface OrganizationUserSearchQuery {
  userEmail: string;
  userName: string;
}

interface OrganizationUserSearchFormProps {
  onSearch: (queryParameters: OrganizationUserSearchQuery) => void;
}

export const isValidEmailCharacters = (email: string) => {
  const validEmail = /^[a-zA-Z0-9@._-]*$/i;
  if (email.match(validEmail)) {
    return true;
  } else {
    return false;
  }
};

export const isValidNameCharacters = (userName: string) => {
  const validName = /^[a-zA-Z0-9_.-]*$/i;
  if (userName.match(validName)) {
    return true;
  } else {
    return false;
  }
};

export const OrganizationUserSearchForm = ({
  onSearch,
}: OrganizationUserSearchFormProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [autoRunFilter, setAutoRunFilter] = useState(false);
  const [organizationUserQuery, setOrganizationUserQuery] = useState(
    {} as OrganizationUserSearchQuery,
  );

  useEffect(() => {
    const filterParams = history.location.search.substring(1);
    const filtersFromParams = qs.parse(filterParams);
    if (
      filtersFromParams.userEmail?.toString() ||
      filtersFromParams.userName?.toString()
    ) {
      setOrganizationUserQuery({
        userEmail: filtersFromParams.userEmail?.toString() ?? '',
        userName: filtersFromParams.userName?.toString() ?? '',
      });
      setAutoRunFilter(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (autoRunFilter === true) {
      setAutoRunFilter(false);
      performQuerySearch();
    }
    // eslint-disable-next-line
  }, [organizationUserQuery]);

  const handleQueryUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    let inputField = event.target.name;
    let inputValue = event.target.value;
    setOrganizationUserQuery({
      ...organizationUserQuery,
      [inputField]: inputValue,
    });
  };

  const handleQuerySearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    performQuerySearch();
  };

  const performQuerySearch = async () => {
    if (
      isValidEmailCharacters(organizationUserQuery.userEmail ?? '') &&
      isValidNameCharacters(organizationUserQuery.userName ?? '')
    ) {
      let queryParameters = {
        userEmail: (organizationUserQuery.userEmail ?? '').toString(),
        userName: (organizationUserQuery.userName ?? '').toString(),
      } as OrganizationUserSearchQuery;
      onSearch(queryParameters);
    } else {
      const notification: CreateNotificationModel = {
        header: 'Organization User Search',
        message: `Please follow the field requirements.`,
      };
      dispatch(addWarning(notification));
    }
  };

  const handleClearQuery = () => {
    setOrganizationUserQuery({} as OrganizationUserSearchQuery);
  };

  return (
    <Form noValidate onSubmit={handleQuerySearch} onReset={handleClearQuery}>
      <Form.Group>
        <h6>Search Users</h6>
        <Form.Row>
          <Col>
            <Form.Group controlId="User email">
              <Form.Label>User Email</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                maxLength={100}
                placeholder="User Email"
                isInvalid={
                  !isValidEmailCharacters(organizationUserQuery.userEmail ?? '')
                }
                value={organizationUserQuery.userEmail ?? ''}
                name="userEmail"
                onChange={handleQueryUpdate}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid email characters: Can only contain
                alphanumeric characters and the following symbols: _ - . @
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="User name">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={100}
                placeholder="User Name"
                isInvalid={
                  !isValidNameCharacters(organizationUserQuery.userName ?? '')
                }
                value={organizationUserQuery.userName ?? ''}
                name="userName"
                onChange={handleQueryUpdate}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid name characters: Can only contain
                alphanumeric characters and the following symbols: _ - .
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <ButtonToolbar className="float-right">
              <Button
                className="my-1 mr-2"
                variant="outline-secondary"
                type="reset"
              >
                Clear
              </Button>
              <Button className="my-1" variant="primary" type="submit">
                Search
              </Button>
            </ButtonToolbar>
          </Col>
        </Form.Row>
      </Form.Group>
    </Form>
  );
};
