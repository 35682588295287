import { Route } from 'react-router-dom';
import Authorized from '../common/Authorized';

export function PrivateRoute(props: any) {
  return (
    <Authorized
      requiredPermissions={props.requiredPermissions}
      redirectOnUnAuthorized={props.redirectOnUnAuthorized}
    >
      <Route {...props} />
    </Authorized>
  );
}

export default PrivateRoute;
