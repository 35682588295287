import {
  faExclamationTriangle,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Overlay,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import {
  ExternalUserInfo,
  UserInfo,
} from '../../../types/AuthorizationBFFGeneratedTypes';
import Confirm from '../../common/Confirm';
import { EXTERNAL_ID_ISSUER_SGI } from '../../common/Constants';
import { EditUserIdentifierModal } from './EditUserIdentifierModal';

interface UserIdentityProps {
  user: UserInfo;
  showChangeIdentifierModal: boolean;
  canUpdateUsers: boolean;
  onCancelEditIdentifierModal: () => void;
  onClickChangeIdentifier: (event: React.SyntheticEvent) => void;
  onHideEditIdentifierModal: () => void;
  onSaveChangeIdentifier: (newIdentifier: string) => void;
  onUnlinkIdentity: (identity: ExternalUserInfo) => void;
}

export const UserIdentity = ({
  user,
  showChangeIdentifierModal,
  canUpdateUsers,
  onCancelEditIdentifierModal,
  onClickChangeIdentifier,
  onHideEditIdentifierModal,
  onSaveChangeIdentifier,
  onUnlinkIdentity,
}: UserIdentityProps) => {
  const [showChangeIdentifierTooltip, setShowChangeIdentifierTooltip] =
    useState(false);
  const changeIdentifierButtonRef = useRef(null);
  const [showConfirmUnlinkIdentity, setShowConfirmUnlinkIdentity] =
    useState(false);
  const [identityToUnlink, setIdentityToUnlink] = useState<ExternalUserInfo>();

  const handleOnClickUnlink = (identity: ExternalUserInfo) => {
    setIdentityToUnlink(identity);
    setShowConfirmUnlinkIdentity(true);
  };

  const handleOnConfirmUnlink = () => {
    identityToUnlink && onUnlinkIdentity(identityToUnlink);
    setShowConfirmUnlinkIdentity(false);
  };

  const handleOnCancelUnlink = () => {
    setIdentityToUnlink(undefined);
    setShowConfirmUnlinkIdentity(false);
  };

  const externalIdentities =
    user.externalIds?.filter(
      (identity) => identity.issuer !== EXTERNAL_ID_ISSUER_SGI,
    ) ?? [];

  const confirmUnlinkIdentityModalBody = () => {
    return (
      <>
        <Container>
          <Row>
            <Col>This will unlink the user with this identity.</Col>
          </Row>
          <Row>
            <Col>
              <ul className="my-2">
                <li>
                  Identity
                  {identityToUnlink?.id ? (
                    <>
                      {` {`}
                      <span className="font-weight-bold">
                        {identityToUnlink?.id.substring(0, 8)}
                      </span>
                      {`...} `}
                    </>
                  ) : (
                    ''
                  )}
                  of user{' '}
                  <span className="font-weight-bold">
                    {user.displayName
                      ? `${user.displayName} (${user.email})`
                      : user.email}
                  </span>
                  .
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Card border="warning" body className="my-2 py-0">
          <Container>
            <Row>
              <Col xs="auto" className="text-warning">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="2x"
                  title="Warning"
                  name="Warning"
                />
              </Col>
              <Col>
                <p className="mb-0">
                  The user will need to sign-in using the email identifier to
                  get linked with this user in the authorization system.
                </p>
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  };

  return (
    <>
      <EditUserIdentifierModal
        show={showChangeIdentifierModal}
        userIdentifier={user.email ?? ''}
        onHide={onHideEditIdentifierModal}
        onCancel={onCancelEditIdentifierModal}
        onSave={onSaveChangeIdentifier}
      />
      <Confirm
        danger
        show={showConfirmUnlinkIdentity}
        title="Unlink User Identity?"
        bodyElement={confirmUnlinkIdentityModalBody}
        confirmLabel="Unlink"
        onConfirm={handleOnConfirmUnlink}
        onCancel={handleOnCancelUnlink}
      />
      <Container>
        <Row className="mt-4">
          <Col>
            <h3>Identity</h3>
          </Col>
        </Row>
        <Row className="mb-2 align-items-center">
          <Col xs lg="auto">
            <span className="font-weight-bold">Email Identifier</span>
          </Col>
          <Col xs lg="auto">
            {
              user.externalIds?.find(
                (identity) => identity.issuer === EXTERNAL_ID_ISSUER_SGI,
              )?.id
            }
          </Col>
          {canUpdateUsers && (
            <Col xs lg="auto">
              <Button
                ref={changeIdentifierButtonRef}
                variant="outline-secondary"
                onClick={(e) => {
                  externalIdentities.length > 0
                    ? setShowChangeIdentifierTooltip(true)
                    : onClickChangeIdentifier(e);
                }}
                onBlur={() => setShowChangeIdentifierTooltip(false)}
                onMouseEnter={() =>
                  externalIdentities.length > 0 &&
                  setShowChangeIdentifierTooltip(true)
                }
                onMouseLeave={() => setShowChangeIdentifierTooltip(false)}
              >
                {externalIdentities.length > 0 && (
                  <>
                    <FontAwesomeIcon icon={faLock} name="Disabled" />{' '}
                  </>
                )}
                Change Email Identifier
              </Button>
              <Overlay
                target={changeIdentifierButtonRef.current}
                show={showChangeIdentifierTooltip}
                placement="auto"
                flip={true}
                transition={false}
              >
                {(props) => (
                  <Tooltip id="changeIdentifierTooltip" {...props}>
                    Identifier cannot be changed when an identity is linked.
                  </Tooltip>
                )}
              </Overlay>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            {externalIdentities.length > 0 && (
              <Table bordered striped responsive>
                <thead>
                  <tr>
                    <th>Issuer</th>
                    <th>Identifier</th>
                    <th colSpan={canUpdateUsers ? 2 : 1}>Identity Provider</th>
                  </tr>
                </thead>
                <tbody>
                  {externalIdentities.map((identity) => {
                    return (
                      <tr key={identity.id}>
                        <td>{identity.issuer}</td>
                        <td>{identity.id}</td>
                        <td>{identity.identityProvider}</td>
                        {canUpdateUsers && (
                          <td className="text-center align-middle">
                            <Button
                              variant="outline-danger"
                              onClick={() => handleOnClickUnlink(identity)}
                            >
                              Unlink
                            </Button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserIdentity;
