import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { ButtonGroup, Pagination, Table, ToggleButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { OrganizationUserSummaryInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import { IdentityProviderBadge } from '../../common/IdentityProviderBadge';
import { OrganizationUserStatusBadge } from '../../common/OrganizationUserStatusBadge';

const defaultPageSize = 20;

interface OrganizationUserSummaryTableProps {
  orgUsers: OrganizationUserSummaryInfo[];
  pageSize?: number;
}

export const OrganizationUserSummaryTable = ({
  orgUsers,
  pageSize = defaultPageSize,
  ...props
}: OrganizationUserSummaryTableProps) => {
  const [organizationUsersTableData, setorganizationUsersTableData] = useState([
    ...orgUsers,
  ]);

  // ===== Sorting - START =====
  const [sortField, setSortField] = useState('emailAddress');
  const [sortAscending, setSortAscending] = useState(true);
  const [hoveredSortField, setHoveredSortField] = useState('');

  const changeSortDirection = (newSortField: string) =>
    setSortAscending(sortField !== newSortField ? true : !sortAscending);

  const displaySortIcon = (field: string) => {
    if (sortField !== field && hoveredSortField === field) {
      return <FontAwesomeIcon icon={faSort} title="Sort" />;
    } else if (sortField === field) {
      return (
        <FontAwesomeIcon
          icon={sortAscending ? faSortUp : faSortDown}
          title="Sort"
        />
      );
    } else {
      return <FontAwesomeIcon icon={faSort} style={{ visibility: 'hidden' }} />;
    }
  };

  const showSortToolTip = (field: string) =>
    sortField !== field && setHoveredSortField(field);
  const hideSortToolTip = () => setHoveredSortField('');
  // ===== Sorting - END =====

  // ===== Status Filter - START =====
  const [statusFilter, setStatusFilter] = useState('all');
  const statuses = [
    { name: 'Show All', value: 'all' },
    { name: 'Enabled', value: 'enabled' },
    { name: 'Disabled', value: 'disabled' },
  ];

  useEffect(() => {
    setorganizationUsersTableData(
      orgUsers.filter((o) => {
        switch (statusFilter) {
          case 'all':
            return true;
          case 'enabled':
            return o.isEnabled;
          case 'disabled':
            return o.isEnabled === false;
          default:
            return true;
        }
      }),
    );
  }, [orgUsers, statusFilter]);
  // ===== Status Filter - END =====

  // ===== Pagination - START =====
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);

  const computeEndIndex = useCallback(
    (currentPage: number, pageSize: number): number => {
      let endIndex = pageSize * currentPage;
      let itemsCount = organizationUsersTableData.length;
      return itemsCount < endIndex ? itemsCount : endIndex;
    },
    [organizationUsersTableData.length],
  );

  const computeLastPage = useCallback(
    (): number =>
      organizationUsersTableData.length === 0
        ? 1
        : Math.ceil(organizationUsersTableData.length / pageSize),
    [organizationUsersTableData.length, pageSize],
  );

  const [endIndex, setEndIndex] = useState(
    computeEndIndex(currentPage, pageSize),
  );

  useEffect(() => {
    setStartIndex(
      currentPage === 1 ? 0 : computeEndIndex(currentPage - 1, pageSize),
    );
    setEndIndex(computeEndIndex(currentPage, pageSize));
  }, [computeEndIndex, currentPage, pageSize]);

  useEffect(() => {
    let lastPage = computeLastPage();
    if (currentPage > lastPage) {
      setCurrentPage(lastPage);
    }
  }, [computeLastPage, currentPage]);
  // ===== Pagination - END =====

  return (
    <>
      <ButtonGroup className="mb-2 float-right" toggle>
        {statuses.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="outline-secondary"
            size="sm"
            name="radio"
            value={radio.value}
            checked={statusFilter === radio.value}
            onChange={(e) => setStatusFilter(e.currentTarget.value)}
            role="button"
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <Table
        bordered
        striped
        responsive="sm"
        className={organizationUsersTableData.length > pageSize ? 'mb-2' : ''}
      >
        <thead>
          <tr>
            <th colSpan={2} className="fit-width">
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('emailAddress');
                  changeSortDirection('emailAddress');
                }}
                onMouseEnter={() => showSortToolTip('emailAddress')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Email {displaySortIcon('emailAddress')}
              </span>
            </th>
            <th>
              <span
                role="button"
                onClick={() => {
                  setSortField('displayName');
                  changeSortDirection('displayName');
                }}
                onMouseEnter={() => showSortToolTip('displayName')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Name {displaySortIcon('displayName')}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {organizationUsersTableData.length > 0 &&
            organizationUsersTableData
              .filter((o) => {
                switch (statusFilter) {
                  case 'all':
                    return true;
                  case 'enabled':
                    return o.isEnabled;
                  case 'disabled':
                    return o.isEnabled === false;
                  default:
                    return true;
                }
              })
              .sort((a: any, b: any) =>
                sortAscending
                  ? (a[sortField] ?? '').localeCompare(b[sortField] ?? '')
                  : (b[sortField] ?? '').localeCompare(a[sortField] ?? ''),
              )
              .slice(startIndex, endIndex)
              .map((user) => {
                return (
                  <tr key={'user' + user.userId}>
                    <td className="fit-width border-right-0">
                      <Link
                        to={{
                          pathname: `/Organization/${user.organizationId}/User/${user.userId}`,
                        }}
                      >
                        {user.emailAddress}
                      </Link>
                    </td>
                    <td className="fit-width border-left-0 pl-0">
                      <IdentityProviderBadge
                        externalUserInfo={user.externalIdentity ?? {}}
                      />
                      {!user.isEnabled && (
                        <>
                          {' '}
                          <OrganizationUserStatusBadge isEnabled={false} />
                        </>
                      )}
                    </td>
                    <td>{user.displayName}</td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
      {organizationUsersTableData &&
        organizationUsersTableData.length > 0 &&
        computeLastPage() > 1 && (
          <Pagination className="float-right">
            <Pagination.Item
              title="First page"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(1)}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} title="First page" />
            </Pagination.Item>
            <Pagination.Item
              title="Previous page"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              {<FontAwesomeIcon icon={faAngleLeft} title="Previous page" />}
            </Pagination.Item>
            <Pagination.Item title="Current page" active>
              {currentPage} of {computeLastPage()}
            </Pagination.Item>
            <Pagination.Item
              title="Next page"
              disabled={endIndex === organizationUsersTableData.length}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              {<FontAwesomeIcon icon={faAngleRight} title="Next page" />}
            </Pagination.Item>
            <Pagination.Item
              title="Last page"
              disabled={endIndex === organizationUsersTableData.length}
              onClick={() => setCurrentPage(computeLastPage())}
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} title="Last page" />
            </Pagination.Item>
          </Pagination>
        )}
    </>
  );
};

export default OrganizationUserSummaryTable;
