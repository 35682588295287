import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { ProfileDefaultRoleInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import ApplicationPermissionsDisplay from './ApplicationPermissionsDisplay';
import PrivilegedRoleBadge from '../../common/PrivilegedRoleBadge';

interface ProfileRoleDisplayProps {
  role: ProfileDefaultRoleInfo;
  key?: string;
}
export const ProfileRoleDisplay = ({ role }: ProfileRoleDisplayProps) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Accordion defaultActiveKey="1">
      <Card>
        <span style={{ cursor: 'pointer' }}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            {role.name}
            {role.isPrivilegedRole && <PrivilegedRoleBadge />}
            <span className="float-right">
              <FontAwesomeIcon
                icon={collapsed ? faAngleDoubleDown : faAngleDoubleUp}
                aria-label="profile-role-toggle-icon"
              />
            </span>
          </Accordion.Toggle>
        </span>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <h4>Permissions Contributed By Application</h4>
            {role.applicationPermissions?.map((app) => {
              return (
                <ApplicationPermissionsDisplay
                  application={app}
                  key={app.applicationManifestId}
                />
              );
            })}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ProfileRoleDisplay;
