import {
  Breadcrumb,
  Button,
  ButtonToolbar,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { OrganizationSearchForm } from './components/OrganizationSearchForm';
import { useGetFeatureFlagsQuery } from '../../api/featureFlagApi';
import {
  useGetAllOrganizationsQuery,
  useGetOrganizationProfileSummariesQuery,
} from '../../api/authorizationuiApi';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import {
  ApplicationProblemDetails,
  OrganizationSummaryInfo,
} from '../../types/AuthorizationBFFGeneratedTypes';
import { OrganizationSearchQuery } from './components/OrganizationSearchForm';
import OrganizationSummaryTable from './components/OrganizationSummaryTable';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import qs from 'qs';

const OrganizationsSummary = () => {
  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const { canListOrganizations, canAddOrganizations } = featureFlags ?? {};
  const history = useHistory();

  const {
    data: organizations,
    isError: isGetAllOrganizationsError,
    error: GetAllOrganizationsError,
    isLoading: isGetAllOrganizationsLoading,
  } = useGetAllOrganizationsQuery();

  const {
    data: profiles,
    isError: isGetOrganizationProfileSummariesError,
    error: GetOrganizationProfileSummariesError,
    isLoading: isGetOrganizationProfileSummariesLoading,
  } = useGetOrganizationProfileSummariesQuery();

  const [filteredOrganizationList, setFilteredOrganizationList] = useState(
    [] as OrganizationSummaryInfo[],
  );

  let filteredList = [] as OrganizationSummaryInfo[];

  const [isSearchAttemped, setIsSearchAttempted] = useState(false);

  const handleOnSearch = (queryParameters: OrganizationSearchQuery) => {
    setIsSearchAttempted(true);
    filteredList = organizations?.length
      ? organizations.filter((organization) => {
          return queryParameters.profileId.length > 0
            ? organization?.name
                ?.toLowerCase()
                .includes(queryParameters.organizationName.toLowerCase()) &&
                organization.profileIds?.includes(
                  queryParameters.profileId.toLowerCase(),
                )
            : organization?.name
                ?.toLowerCase()
                .includes(queryParameters.organizationName.toLowerCase());
        })
      : [];
    setFilteredOrganizationList(filteredList);
    history.push(`?${qs.stringify(queryParameters)}`);
  };

  useEffect(() => {
    if (isGetAllOrganizationsError) {
      apiMessageNotification(
        GetAllOrganizationsError as ApplicationProblemDetails,
      );
    }
  }, [GetAllOrganizationsError, isGetAllOrganizationsError]);

  useEffect(() => {
    if (isGetOrganizationProfileSummariesError) {
      apiMessageNotification(
        GetOrganizationProfileSummariesError as ApplicationProblemDetails,
      );
    }
  }, [
    GetOrganizationProfileSummariesError,
    isGetOrganizationProfileSummariesError,
  ]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Organizations</Breadcrumb.Item>
      </Breadcrumb>
      {isGetAllOrganizationsLoading ||
      isGetOrganizationProfileSummariesLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Container>
            <Row className="mb-2">
              <Col>
                <h2>Organizations</h2>
              </Col>
              <Col>
                <ButtonToolbar className="float-right">
                  {canAddOrganizations && (
                    <Button
                      className="my-1"
                      variant="outline-secondary"
                      onClick={() => {
                        history.push('/Organizations/add');
                      }}
                    >
                      Add Organization
                    </Button>
                  )}
                </ButtonToolbar>
              </Col>
            </Row>
            <Row>
              <Col>
                {canListOrganizations && (
                  <OrganizationSearchForm
                    profiles={profiles ?? []}
                    onSearch={handleOnSearch}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {filteredOrganizationList.length ? (
                  <OrganizationSummaryTable
                    organizations={filteredOrganizationList}
                  />
                ) : isSearchAttemped ? (
                  <span className="text-muted">No results found.</span>
                ) : (
                  []
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default OrganizationsSummary;
