import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Button, ButtonToolbar, Col, Form, Modal } from 'react-bootstrap';
import { useGetFeatureFlagsQuery } from '../../../api/featureFlagApi';
import {
  OrganizationDetailInfo,
  OrganizationProfileSummaryInfo,
  OrganizationType,
  ProfileUsageType,
} from '../../../types/AuthorizationBFFGeneratedTypes';
import { ConfirmOrganizationTypeChangeModal } from './ConfirmOrganizationTypeChangeModal';
import EditableList from './EditableList';
import { EditOrganizationProfilesTable } from './EditOrganizationProfilesTable';

const emailPrefix = '@';

export interface EditOrganizationFormProps {
  organizationDetailInfo: OrganizationDetailInfo;
  availableProfiles: OrganizationProfileSummaryInfo[];
  onInputChange: React.ChangeEventHandler<HTMLInputElement>;
  onOrganizationTypeChange: (
    newOrganizationType: OrganizationType | null,
  ) => void;
  onOrganizationTypeAndProfilesChange: (
    newOrganizationType: OrganizationType | null,
    invalidProfiles: OrganizationProfileSummaryInfo[],
  ) => void;
  onProfileSelectionChange: (profileId: string, isSelected: boolean) => void;
  onSaveOrganization: () => void;
  onCancel: (event: React.SyntheticEvent) => void;
  onEmailDomainAdded: (emailDomain: string) => void;
  onEmailDomainChanged: (emailDomain: string, index: number) => void;
  onEmailDomainDeleted: (index: number) => void;
}

const enum OrganizationInputValidationErrorMessages {
  NoName = 'Please enter the name of the organization.',
  InvalidName = "Organization name may only contain spaces, letters, numbers and the following symbols: @ & ! + . , - ' _",
  AutoEnrollEmailSuffixes = 'Please enter a valid, unique email domain.',
  OrganizationTypeInternal = 'Internal organizations cannot be associated with external profiles.',
  OrganizationTypeExternal = 'External organizations cannot be associated with internal profiles.',
  OrganizationTypeUndefined = 'Please select the type of the organization.',
  NoProfilesSelected = 'Please select at least one profile.',
}

export const isValidEmailDomain = (emailDomain: string) => {
  const validDomainFormat =
    /\b((?=[a-zA-Z0-9-]{1,63}\.)[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,63}\b$/;
  if (!emailDomain || !emailDomain.match(validDomainFormat)) {
    return false;
  } else {
    return true;
  }
};

export const isValidOrganizationName = (organizationName: string) => {
  const validOrganizationNameFormat = /^[a-zA-Z0-9\s@&!+.,'_-]*$/i;
  if (
    !organizationName ||
    !organizationName.match(validOrganizationNameFormat)
  ) {
    return false;
  } else {
    return true;
  }
};

export const getProfileIds = (
  profiles: OrganizationProfileSummaryInfo[],
): string[] => {
  return profiles.reduce<string[]>((profileIds, profile) => {
    if (profile && profile.profileId) {
      profileIds.push(profile.profileId);
    }
    return profileIds;
  }, []);
};

const getInvalidProfiles = (
  organizationType: OrganizationType,
  organizationDetailInfo: OrganizationDetailInfo,
  availableProfiles: OrganizationProfileSummaryInfo[],
): OrganizationProfileSummaryInfo[] => {
  let invalidProfiles: OrganizationProfileSummaryInfo[] = [];
  if (
    !organizationDetailInfo.profiles ||
    organizationDetailInfo.profiles.length === 0
  ) {
    return [];
  }
  const selectedProfileIds = getProfileIds(
    organizationDetailInfo.profiles ?? [],
  );
  const selectedProfiles = availableProfiles?.filter(
    (profile) =>
      profile.profileId && selectedProfileIds.includes(profile.profileId),
  );
  if (organizationType === OrganizationType.Internal) {
    invalidProfiles = selectedProfiles.filter(
      (profile) => profile.usageType !== ProfileUsageType.Internal,
    );
  } else if (organizationType === OrganizationType.External) {
    invalidProfiles = selectedProfiles.filter(
      (profile) => profile.usageType !== ProfileUsageType.ExternalOrganization,
    );
  }
  return invalidProfiles;
};

export const EditOrganizationForm = ({
  organizationDetailInfo,
  availableProfiles,
  onInputChange,
  onOrganizationTypeChange,
  onOrganizationTypeAndProfilesChange,
  onProfileSelectionChange,
  onSaveOrganization,
  onCancel,
  onEmailDomainAdded,
  onEmailDomainChanged,
  onEmailDomainDeleted,
  ...props
}: EditOrganizationFormProps) => {
  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const { isAutoEnrolledEmailDomainsFeatureEnabled } = featureFlags || {};

  const [validationErrors, setValidationErrors] = useState<Map<string, string>>(
    new Map<string, string>(),
  );
  const [isSaveAttempted, setIsSaveAttempted] = useState(false);
  const [
    showConfirmOrganizationTypeModal,
    setShowConfirmOrganizationTypeModal,
  ] = useState(false);
  const [newOrganizationType, setNewOrganizationType] =
    useState<OrganizationType | null>(null);
  const [invalidProfiles, setInvalidProfiles] = useState<
    OrganizationProfileSummaryInfo[]
  >([]);
  const [show, setShow] = useState(false);
  const handleInfoClick = () => setShow(true);
  const handleClose = () => setShow(false);

  const validateOrganizationDetailInfo = useCallback(
    (organizationDetailInfo: OrganizationDetailInfo): Map<string, string> => {
      let errors = new Map<string, string>();

      let name = organizationDetailInfo.name;
      if (!name || name.trim().length === 0) {
        errors.set('noName', OrganizationInputValidationErrorMessages.NoName);
      }
      if (name && !isValidOrganizationName(name)) {
        errors.set(
          'invalidName',
          OrganizationInputValidationErrorMessages.InvalidName,
        );
      }

      let autoEnrollEmailSuffixes =
        organizationDetailInfo.autoEnrollEmailSuffixes ?? [];
      autoEnrollEmailSuffixes.forEach((emailDomain, index) => {
        if (
          !isValidEmailDomain(emailDomain) ||
          autoEnrollEmailSuffixes?.filter((item) => item === emailDomain)
            .length > 1
        ) {
          errors.set(
            'autoEnrollEmailSuffixes.' + index,
            OrganizationInputValidationErrorMessages.AutoEnrollEmailSuffixes,
          );
        }
      });

      const organizationType = organizationDetailInfo.organizationType;
      const invalidProfiles = organizationType
        ? getInvalidProfiles(
            organizationType,
            organizationDetailInfo,
            availableProfiles,
          )
        : [];
      switch (organizationType) {
        case null:
        case undefined:
        case OrganizationType.Undefined:
          errors.set(
            'organizationType',
            OrganizationInputValidationErrorMessages.OrganizationTypeUndefined,
          );
          break;
        case OrganizationType.Internal: {
          if (invalidProfiles.length > 0) {
            errors.set(
              'organizationType',
              OrganizationInputValidationErrorMessages.OrganizationTypeInternal,
            );
          }
          break;
        }
        case OrganizationType.External: {
          if (invalidProfiles.length > 0) {
            errors.set(
              'organizationType',
              OrganizationInputValidationErrorMessages.OrganizationTypeExternal,
            );
          }
          break;
        }
        default:
          break;
      }

      if (
        organizationType &&
        (!organizationDetailInfo.profiles ||
          organizationDetailInfo.profiles?.length === 0)
      ) {
        errors.set(
          'profiles',
          OrganizationInputValidationErrorMessages.NoProfilesSelected,
        );
      }

      return errors;
    },
    [availableProfiles],
  );

  const validateAndHandleOrganizationTypeChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newSelectedOrganizationType = Number(
      event.target.value,
    ) as unknown as OrganizationType;
    const invalidProfiles = getInvalidProfiles(
      newSelectedOrganizationType,
      organizationDetailInfo,
      availableProfiles,
    );
    if (invalidProfiles.length > 0) {
      setInvalidProfiles(invalidProfiles);
      setNewOrganizationType(newSelectedOrganizationType);
      setShowConfirmOrganizationTypeModal(true);
    } else {
      await onOrganizationTypeChange(newSelectedOrganizationType);
    }
  };

  const handleConfirmOrganizationTypeChange = async (
    event: React.SyntheticEvent,
  ) => {
    await onOrganizationTypeAndProfilesChange(
      newOrganizationType,
      invalidProfiles,
    );
    setInvalidProfiles([]);
    setNewOrganizationType(null);
    const errors = new Map<string, string>(validationErrors);
    errors.delete('organizationType');
    setValidationErrors(errors);
    setShowConfirmOrganizationTypeModal(false);
  };

  const handleIsEmailDomainValid = (emailDomain: string): boolean => {
    return (
      isValidEmailDomain(emailDomain) &&
      (!organizationDetailInfo.autoEnrollEmailSuffixes ||
        organizationDetailInfo.autoEnrollEmailSuffixes?.filter(
          (item) => item === emailDomain,
        ).length === 0)
    );
  };

  const handleIsEmailDomainInListValid = (index: number): boolean => {
    let error = validationErrors.get('autoEnrollEmailSuffixes.' + index);
    return error ? false : true;
  };

  useEffect(() => {
    //revalidate form when fixing validation errors (after initial save)
    if (isSaveAttempted) {
      setValidationErrors(
        validateOrganizationDetailInfo(organizationDetailInfo),
      );
    }
  }, [isSaveAttempted, organizationDetailInfo, validateOrganizationDetailInfo]);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSaveAttempted(true);
    let errors = validateOrganizationDetailInfo(organizationDetailInfo);
    setValidationErrors(errors);

    if (errors.size === 0) await onSaveOrganization();
  };

  return (
    <>
      <Modal animation={false} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Disabling a organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            A disabled organization will only be visible to an SGI
            administrator. The organization will keep a record of its assigned
            profiles, all of its users, and its users assigned permissions
            within the organization. If the organization is re-enabled all of
            these things will be granted back to it and its users will again
            have their permissions.
          </span>
          <br />
          <span>
            If any user of a disabled organization attempts to log in they will
            not see the disabled organization in their list, and they will not
            get any permissions from the disabled organization.
          </span>
        </Modal.Body>
      </Modal>
      <ConfirmOrganizationTypeChangeModal
        show={showConfirmOrganizationTypeModal}
        newType={newOrganizationType}
        invalidProfiles={invalidProfiles}
        onHide={() => setShowConfirmOrganizationTypeModal(false)}
        onCancel={() => setShowConfirmOrganizationTypeModal(false)}
        onConfirm={handleConfirmOrganizationTypeChange}
      />
      <Form noValidate onSubmit={handleFormSubmit}>
        <Form.Group controlId="name" className="mb-4">
          <Form.Label>Name</Form.Label>
          <Form.Row>
            <Col md={10} lg={8}>
              <Form.Control
                autoFocus
                type="text"
                maxLength={100}
                placeholder=""
                value={organizationDetailInfo.name ?? ''}
                name="name"
                onChange={onInputChange}
                isInvalid={
                  !validationErrors
                    ? false
                    : !!validationErrors?.get('noName') ||
                      !!validationErrors?.get('invalidName')
                }
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.get('noName')
                  ? OrganizationInputValidationErrorMessages.NoName
                  : OrganizationInputValidationErrorMessages.InvalidName}
              </Form.Control.Feedback>
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group controlId="isEnabled" className="mb-4">
          <Form.Row className="my-2">
            <Col xs="auto">
              <Form.Check
                type="checkbox"
                label={
                  organizationDetailInfo.isEnabled
                    ? 'Organization is enabled.'
                    : ' Organization is disabled.'
                }
                checked={organizationDetailInfo.isEnabled ?? false}
                name="isEnabled"
                onChange={onInputChange}
              />
            </Col>
            <Col xs="auto">
              <FontAwesomeIcon
                onClick={() => handleInfoClick()}
                icon={faInfoCircle}
              />
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group controlId="emailDomains" className="mb-4">
          {isAutoEnrolledEmailDomainsFeatureEnabled && (
            <>
              <Form.Label>Auto-Enrolled Email Domains</Form.Label>
              <EditableList
                values={organizationDetailInfo.autoEnrollEmailSuffixes ?? []}
                prefix={emailPrefix}
                maxLength={253}
                newItemPlaceholderText="Enter to add new item."
                newItemInvalidErrorText={
                  OrganizationInputValidationErrorMessages.AutoEnrollEmailSuffixes
                }
                itemPlaceholderText="Email domain"
                itemInvalidErrorText={
                  OrganizationInputValidationErrorMessages.AutoEnrollEmailSuffixes
                }
                isItemValid={handleIsEmailDomainValid}
                isItemInListValid={handleIsEmailDomainInListValid}
                onItemAdded={onEmailDomainAdded}
                onItemChanged={onEmailDomainChanged}
                onItemDeleted={onEmailDomainDeleted}
              />
            </>
          )}
        </Form.Group>
        <Form.Group controlId="organizationType" className="mb-4">
          <Form.Label>Organization Type</Form.Label>
          <Form.Row>
            <Col>
              <Form.Control
                as="select"
                className="w-auto"
                name="organizationType"
                value={
                  organizationDetailInfo.organizationType ??
                  OrganizationType.Undefined
                }
                onChange={validateAndHandleOrganizationTypeChange}
                isInvalid={
                  !validationErrors
                    ? false
                    : !!validationErrors?.get('organizationType')
                }
              >
                {Object.keys(OrganizationType)
                  .filter((k) => !isNaN(Number(k)))
                  .map((key) => (
                    <option key={key} value={key}>
                      {key === OrganizationType.Undefined.toString()
                        ? ''
                        : OrganizationType[key as any]}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {validationErrors.get('organizationType')}
              </Form.Control.Feedback>
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group controlId="profiles" className="mb-2">
          <Form.Label>Profiles</Form.Label>
          {validationErrors && validationErrors.has('profiles') && (
            <div className="invalid-feedback d-block mb-1">
              {validationErrors.get('profiles')}
            </div>
          )}
          <div style={{ overflowY: 'auto', maxHeight: 400 }}>
            <EditOrganizationProfilesTable
              organizationProfiles={organizationDetailInfo.profiles ?? []}
              availableProfiles={availableProfiles}
              organizationType={
                organizationDetailInfo.organizationType === undefined
                  ? null
                  : organizationDetailInfo.organizationType
              }
              isSaveAttempted={isSaveAttempted}
              handleProfileSelectionChanged={onProfileSelectionChange}
            />
          </div>
        </Form.Group>
        <ButtonToolbar className="float-right my-1">
          <Button
            className="mr-2"
            variant="outline-secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </ButtonToolbar>
      </Form>
    </>
  );
};

export default EditOrganizationForm;
