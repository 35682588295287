import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PrivilegedRoleBadge = () => {
  return (
    <>
      {' '}
      <FontAwesomeIcon
        icon={faShieldAlt}
        title="This is a privileged role"
        aria-label="privileged-role-icon"
      />
    </>
  );
};

export default PrivilegedRoleBadge;
