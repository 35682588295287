import { useEffect } from 'react';
import { useGetMyOrganizationsQuery } from '../../api/authorizationuiApi';
import MyOrganizations from '../organizationsSummary/MyOrganizations';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import { ApplicationProblemDetails } from '../../types/AuthorizationBFFGeneratedTypes';
import LoadingSpinner from './loadingSpinner/LoadingSpinner';
import { Breadcrumb } from 'react-bootstrap';

function Home(props: any) {
  const {
    data: myOrgSummaries,
    isLoading: isLoadingMyOrgSummaries,
    isError: isErrorMyOrgs,
    error: errorMyOrgs,
  } = useGetMyOrganizationsQuery();

  const isLoading = isLoadingMyOrgSummaries;

  useEffect(() => {
    if (isErrorMyOrgs) {
      apiMessageNotification(errorMyOrgs as ApplicationProblemDetails);
    }
  }, [isErrorMyOrgs, errorMyOrgs]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Home</Breadcrumb.Item>
      </Breadcrumb>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <MyOrganizations organizations={myOrgSummaries ?? []} />
        </>
      )}
    </>
  );
}

export default Home;
