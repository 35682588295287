import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';

export const PageNotFound = () => {
  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <Container>
        <Row>
          <Col>
            <h2>Page Not Found</h2>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>The page you're looking for could not be found.</Col>
        </Row>
        <Row>
          <Col>Please try the following options:</Col>
        </Row>
        <Row>
          <Col>
            <ul>
              <li>
                Check the link or the web page address for possible typing or
                copy-paste errors.
              </li>
              <li>
                Try the links on the navigation menu or go to the{' '}
                <Link to="/">Home</Link> page.
              </li>
              <li>Contact support if you still can't find what you need.</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PageNotFound;
