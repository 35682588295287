import { Col, Container, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserOrganizationSummaryInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import { OrganizationUserStatusBadge } from '../../common/OrganizationUserStatusBadge';
interface UserOrgsProps {
  userId: string;
  userOrganizations: UserOrganizationSummaryInfo[];
}

export const UserOrganizations = ({
  userId,
  userOrganizations,
}: UserOrgsProps) => {
  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h3>Organizations</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {userOrganizations?.length ? (
            <Table className="mb-2" striped bordered responsive="md">
              <thead>
                <tr>
                  <th>Access In Organization</th>
                </tr>
              </thead>
              <tbody>
                {userOrganizations.map((org) => {
                  return (
                    <tr key={'userOrg-' + org.organizationId}>
                      <td>
                        <Link
                          to={{
                            pathname: `/Organization/${org.organizationId}/user/${userId}`,
                          }}
                        >
                          {org.name}
                        </Link>{' '}
                        {!org.isUserEnabled && (
                          <OrganizationUserStatusBadge isEnabled={false} />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <em>None found</em>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default UserOrganizations;
