import { FC } from 'react';
import { ApplicationInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import DeleteApplicationButton from './DeleteApplicationButton';
import { ButtonToolbar, Container, Row, Col } from 'react-bootstrap';
import AppPermissionsTable from './AppPermissionsTable';

export interface ApplicationDetailsInfoProps {
  appInfo: ApplicationInfo;
}

const ApplicationDetailsInfo: FC<ApplicationDetailsInfoProps> = ({
  appInfo,
}) => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h2>{appInfo.applicationName}</h2>
          </Col>
          {appInfo.canDelete && (
            <Col>
              <ButtonToolbar className="float-right">
                <DeleteApplicationButton appInfo={appInfo} />
              </ButtonToolbar>
            </Col>
          )}
        </Row>
        <Row className="mb-4">
          <Col>{appInfo.description}</Col>
        </Row>
        <Row className="mb-4">
          <Col>Application Id: {appInfo.applicationManifestId}</Col>
        </Row>
        {appInfo.permissions && (
          <AppPermissionsTable permissions={appInfo.permissions} />
        )}
      </Container>
    </>
  );
};

export default ApplicationDetailsInfo;
