// AppInsights.js
import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { APPINSIGHTSCLOUDROLE } from './properties/appInsightsProperties';

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

const createAppInsightsService = () => {
  const initialize = async (instrumentationKey: string) => {
    const browserHistory = createBrowserHistory({ basename: '' });
    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        // Enable correlation
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        correlationHeaderExcludedDomains: [],
        // End of correlation config
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.tags = envelope.tags || [];
      envelope.tags.push({ 'ai.cloud.role': APPINSIGHTSCLOUDROLE });
    });
    return { appInsights, reactPlugin };
  };
  return { initialize };
};

export const ai = createAppInsightsService();
export { appInsights, reactPlugin };
