import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import {
  ApplicationMessageSeverityType,
  ApplicationProblemDetails,
} from '../types/AuthorizationBFFGeneratedTypes';
import { getAccessToken } from './apiUtils';

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data }) => {
    const token = await getAccessToken();
    try {
      const result = await axios({
        url:
          ((window as any)['runConfig']?.REACT_APP_BFF_API_BASEURL ?? '') + url,
        method,
        data,
        headers: { Authorization: 'Bearer ' + token },
      });
      return { data: result.data };
    } catch (err) {
      let axiosError = err as AxiosError;
      let error: ApplicationProblemDetails = { messages: [] };

      if (axiosError.response) {
        error.title = axiosError.response.data.title;
        error.status = Number(axiosError.code ?? 500);
        error.messages = axiosError.response.data.messages;
        error.instance = axiosError.response.data.instance;
      } else {
        error.title = 'Application Error';
        error.messages = error.messages?.concat({
          message: 'Problem communicating with server',
          severity: ApplicationMessageSeverityType.Error,
        });
      }
      return {
        error: error,
      };
    }
  };
