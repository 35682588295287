import { useAppDispatch, useAppSelector } from '../hooks';
import {
  NotificationType,
  removeNotification,
  selectNotifications,
} from './notificationSlice';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

const getNotificationIcon = (type: NotificationType) => {
  switch (type) {
    case NotificationType.ERROR:
      return (
        <span className={'text-danger'}>
          <FontAwesomeIcon icon={faTimesCircle} title="Error" />
        </span>
      );
    case NotificationType.SUCCESS:
      return (
        <span className={'text-success'}>
          <FontAwesomeIcon icon={faCheckCircle} title="Success" />
        </span>
      );
    case NotificationType.INFO:
      return (
        <span className={'text-primary'}>
          <FontAwesomeIcon icon={faInfoCircle} title="Info" />
        </span>
      );
    case NotificationType.WARNING:
      return (
        <span className={'text-warning'}>
          <FontAwesomeIcon icon={faExclamationTriangle} title="Warning" />
        </span>
      );
    default:
      return (
        <span className={'text-primary'}>
          <FontAwesomeIcon icon={faInfoCircle} title="Info" />
        </span>
      );
  }
};

const getDelayDuration = (type: NotificationType) => {
  switch (type) {
    case NotificationType.ERROR:
      return 6000;
    default:
      return 4000;
  }
};

export const Notifications = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotifications);

  return (
    <div className="container">
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: 'relative',
          minHeight: '0px',
        }}
      >
        <div
          aria-live="polite"
          aria-atomic="true"
          style={{
            position: 'absolute',
            top: 75,
            right: 0,
            zIndex: 3000,
          }}
        >
          {notifications.map((notification) => {
            return (
              <Toast
                key={notification.id}
                autohide={true}
                delay={getDelayDuration(notification.type)}
                onClose={() => {
                  dispatch(removeNotification(notification.id));
                }}
              >
                <Toast.Header>
                  <span>
                    {getNotificationIcon(notification.type)}{' '}
                    {notification.header}
                  </span>
                </Toast.Header>
                {notification.message && (
                  <Toast.Body>{notification.message}</Toast.Body>
                )}
              </Toast>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
