import { Accordion, Badge, Card, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ProfileDefaultRoleSelectionSummary } from '../helpers/editor-types';
import PrivilegedRoleBadge from '../../common/PrivilegedRoleBadge';

export const groupRolesByProfile = (
  roles: ProfileDefaultRoleSelectionSummary[],
): ProfileRolesSelection[] => {
  return roles.reduce<ProfileRolesSelection[]>((profileRoles, roleSummary) => {
    let profileRole = profileRoles.find(
      (p) => p.profileId === roleSummary.profileId,
    );
    profileRole
      ? profileRole.rolesSelection.push(roleSummary)
      : profileRoles.push({
          profileId: roleSummary.profileId,
          profileName: roleSummary.profileName,
          rolesSelection: [roleSummary],
        });
    return profileRoles.sort((a, b) =>
      (a.profileName ?? '').localeCompare(b.profileName ?? ''),
    );
  }, []);
};

export interface ProfileRolesSelection {
  profileId?: string;
  profileName?: string;
  rolesSelection: ProfileDefaultRoleSelectionSummary[];
}

interface OrganizationUserGroupedRolesSelectionCardProps {
  profileRoleSelection: ProfileRolesSelection;
  selectable?: boolean;
  onProfileRoleSelectedChange?: (
    profileId: string,
    profileDefaultRoleId: string,
    isSelected: boolean,
  ) => void;
}

export const OrganizationUserGroupedRolesSelectionCard = ({
  profileRoleSelection: { profileName, rolesSelection },
  selectable = false,
  onProfileRoleSelectedChange = () => {},
}: OrganizationUserGroupedRolesSelectionCardProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const selectedCount = rolesSelection.filter((r) => r.isSelected).length;
  const hasAssignableRoles = rolesSelection.some(
    (r) => r.canMaintainProfileRoleAssociation,
  );

  rolesSelection.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

  return (
    <>
      <Accordion
        defaultActiveKey={
          selectedCount === 0 || (selectable && !hasAssignableRoles) ? '0' : '1'
        }
      >
        <Card>
          <span style={{ cursor: 'pointer' }}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              onClick={() => setCollapsed(!collapsed)}
            >
              {profileName}{' '}
              <Badge variant={selectedCount > 0 ? 'primary' : 'dark'}>
                {selectedCount}
              </Badge>
              <span className="float-right">
                <FontAwesomeIcon
                  icon={collapsed ? faAngleDoubleDown : faAngleDoubleUp}
                />
              </span>
            </Accordion.Toggle>
          </span>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              {rolesSelection.length === 0 ? (
                <div className="my-2 mx-4">No assignable roles.</div>
              ) : (
                <Table className="mb-0" bordered striped hover>
                  <thead>
                    <tr>
                      <th colSpan={2} className="fit-width">
                        Role Name
                      </th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolesSelection.map((role) => {
                      return (
                        <tr key={role.profileDefaultRoleId}>
                          <td className="fit-width border-right-0">
                            {selectable ? (
                              <Form.Check
                                type="checkbox"
                                id={role.profileDefaultRoleId}
                                label={role.name}
                                checked={role.isSelected}
                                onChange={(e) =>
                                  onProfileRoleSelectedChange(
                                    role.profileId ?? '',
                                    role.profileDefaultRoleId ?? '',
                                    e.target.checked,
                                  )
                                }
                                disabled={
                                  !role.canMaintainProfileRoleAssociation
                                }
                              />
                            ) : (
                              role.name
                            )}
                          </td>
                          <td className="fit-width border-left-0 pl-0">
                            {role.isPrivilegedRole && <PrivilegedRoleBadge />}
                          </td>
                          <td>{role.description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default OrganizationUserGroupedRolesSelectionCard;
