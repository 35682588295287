import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

interface DynamicOrgBreadcrumbProps {
  canListOrganizations: boolean;
}

export const DynamicOrgBreadcrumb = ({
  canListOrganizations,
}: DynamicOrgBreadcrumbProps) => {
  if (canListOrganizations) {
    return (
      <LinkContainer to="/Organizations">
        <Breadcrumb.Item>Organizations</Breadcrumb.Item>
      </LinkContainer>
    );
  } else {
    return (
      <LinkContainer to="/">
        <Breadcrumb.Item>Organizations</Breadcrumb.Item>
      </LinkContainer>
    );
  }
};

export default DynamicOrgBreadcrumb;
