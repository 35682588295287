import { Col, Container, Row } from 'react-bootstrap';
import { OrganizationUserGroupedRolesViewer } from './OrganizationUserGroupedRolesViewer';
import {
  OrganizationProfileSummaryInfo,
  ProfileDefaultRoleInfo,
} from '../../../types/AuthorizationBFFGeneratedTypes';

export interface OrganizationUserProfileRolesTableProps {
  organizationUserProfileRoles?: ProfileDefaultRoleInfo[];
  organizationProfiles: OrganizationProfileSummaryInfo[];
}

export const OrganizationUserProfileRolesTable = ({
  organizationUserProfileRoles,
  organizationProfiles,
  ...props
}: OrganizationUserProfileRolesTableProps) => {
  return (
    <Container>
      <Row>
        <Col>
          <h3>Roles</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {!organizationUserProfileRoles ||
          organizationUserProfileRoles.length === 0 ? (
            'No roles assigned.'
          ) : (
            <OrganizationUserGroupedRolesViewer
              profileDefaultRoles={organizationUserProfileRoles ?? []}
              organizationProfiles={organizationProfiles}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationUserProfileRolesTable;
