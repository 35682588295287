import { useEffect, useState } from 'react';
import { Form, Row, Container, Col } from 'react-bootstrap';
import {
  useCalculateClaimsMutation,
  useGetAllApplicationsQuery,
} from '../../../api/authorizationuiApi';
import {
  ApplicationProblemDetails,
  ExternalUserInfo,
} from '../../../types/AuthorizationBFFGeneratedTypes';
import { EXTERNAL_ID_ISSUER_SGI } from '../../common/Constants';
import LoadingSpinner from '../../common/loadingSpinner/LoadingSpinner';
import { apiMessageNotification } from '../../redux/apiMessageNotification';

interface CalculatePermissionsProps {
  userId: string;
  externalIdentifiers: ExternalUserInfo[];
}

export const CalculatePermissions = ({
  userId,
  externalIdentifiers,
}: CalculatePermissionsProps) => {
  const { data: applicationSummaries } = useGetAllApplicationsQuery();
  const [
    getClaims,
    {
      data: calculateResponse,
      isLoading: isLoadingPermissions,
      isError: isErrorCalculating,
      error: errorCalculating,
      isSuccess: isCalculatingSuccess,
    },
  ] = useCalculateClaimsMutation();
  const [calculatedPermissions, setCalculatedPermissions] = useState(
    [] as string[],
  );
  const [selectedApp, setSelectedApp] = useState('');

  const nonDefaultExternalIdentitiesExist = externalIdentifiers.some((ids) => {
    return ids.issuer !== EXTERNAL_ID_ISSUER_SGI;
  });

  const handleAppChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalculatedPermissions([]);
    setSelectedApp(event.target.value);
  };

  useEffect(() => {
    if (selectedApp !== '') {
      if (isErrorCalculating) {
        apiMessageNotification(errorCalculating as ApplicationProblemDetails);
        setSelectedApp('');
      } else {
        getClaims({ userId: userId, applicationId: selectedApp });
      }
    }
  }, [getClaims, userId, selectedApp, isErrorCalculating, errorCalculating]);

  useEffect(() => {
    if (isCalculatingSuccess) {
      const tmpPerms =
        calculateResponse?.permissions?.map((perm) => {
          return perm;
        }) ?? [];
      setCalculatedPermissions(tmpPerms);
    }
  }, [isCalculatingSuccess, calculateResponse]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h3>Permissions For Application</h3>
        </Col>
      </Row>
      {nonDefaultExternalIdentitiesExist ? (
        <Form>
          <Form.Group controlId="applications" as={Row}>
            <Col xs="auto">
              <Form.Label className="font-weight-bold">Application</Form.Label>
              <Form.Control
                as="select"
                name="applicationList"
                onChange={handleAppChange}
                value={selectedApp}
              >
                <option value="">Select an application</option>
                {applicationSummaries?.map((app) => {
                  return (
                    <option
                      key={app.applicationManifestId}
                      value={app.applicationManifestId}
                    >
                      {app.applicationName}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Row className="mb-4">
            <Col>
              {isLoadingPermissions ? (
                <LoadingSpinner />
              ) : calculatedPermissions.length > 0 ? (
                <ul>
                  {calculatedPermissions.map((perm) => {
                    return <li key={perm}>{perm}</li>;
                  })}
                </ul>
              ) : (
                selectedApp && (
                  <span className="ml-2 text-muted">
                    No permissions in application.
                  </span>
                )
              )}
            </Col>
          </Row>
        </Form>
      ) : (
        <span className="text-muted">
          Permissions Cannot Be Calculated Without An External Identity
        </span>
      )}
    </Container>
  );
};

export default CalculatePermissions;
