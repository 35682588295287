import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Table, ToggleButton, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  ProfileSummaryInfo,
  ProfileUsageType,
} from '../../../types/AuthorizationBFFGeneratedTypes';

export interface ProfileTableProps {
  profiles: ProfileSummaryInfo[];
}

export const ProfileTable = ({ profiles }: ProfileTableProps) => {
  const [sortField, setSortField] = useState('name');
  const [sortAscending, setSortAscending] = useState(true);
  const [hoveredSortField, setHoveredSortField] = useState('');

  const profilesTableData = [...profiles];

  const changeSortDirection = (newSortField: string) => {
    setSortAscending(sortField !== newSortField ? true : !sortAscending);
  };

  const displaySortIcon = (field: string) => {
    if (sortField !== field && hoveredSortField === field) {
      return <FontAwesomeIcon icon={faSort} title="Sort" />;
    } else if (sortField === field) {
      return (
        <FontAwesomeIcon
          icon={sortAscending ? faSortUp : faSortDown}
          title="Sort"
        />
      );
    } else {
      return <FontAwesomeIcon icon={faSort} style={{ visibility: 'hidden' }} />;
    }
  };

  const [usageFilter, setUsageFilter] = useState('all');

  const statuses = [
    { name: 'Show All', value: 'all' },
    { name: 'Internal', value: 'internal' },
    { name: 'External', value: 'external' },
  ];

  const showSortToolTip = (field: string) =>
    sortField !== field && setHoveredSortField(field);

  const hideSortToolTip = () => setHoveredSortField('');

  return (
    <>
      <ButtonGroup className="mb-2 float-right" toggle>
        {statuses.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="outline-secondary"
            size="sm"
            name="radio"
            value={radio.value}
            checked={usageFilter === radio.value}
            onChange={(e) => setUsageFilter(e.currentTarget.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <Table bordered striped responsive="sm">
        <thead>
          <tr>
            <th>
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('name');
                  changeSortDirection('name');
                }}
                onMouseEnter={() => showSortToolTip('name')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Profile Name {displaySortIcon('name')}
              </span>
            </th>
            <th>
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('description');
                  changeSortDirection('description');
                }}
                onMouseEnter={() => showSortToolTip('description')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Description {displaySortIcon('description')}
              </span>
            </th>
            <th>
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('sourceApplicationManifestName');
                  changeSortDirection('sourceApplicationManifestName');
                }}
                onMouseEnter={() =>
                  showSortToolTip('sourceApplicationManifestName')
                }
                onMouseLeave={() => hideSortToolTip()}
              >
                Source Application{' '}
                {displaySortIcon('sourceApplicationManifestName')}
              </span>
            </th>
            <th>
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('usageType');
                  changeSortDirection('usageType');
                }}
                onMouseEnter={() => showSortToolTip('usageType')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Usage Type {displaySortIcon('usageType')}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {profilesTableData.length > 0 &&
            profilesTableData
              .filter((profiles) => {
                switch (usageFilter) {
                  case 'all':
                    return true;
                  case 'external':
                    return (
                      profiles.usageType ===
                      ProfileUsageType.ExternalOrganization
                    );
                  case 'internal':
                    return profiles.usageType === ProfileUsageType.Internal;
                  default:
                    return true;
                }
              })
              .sort((a: any, b: any) => {
                let fieldA = a[sortField];
                let fieldB = b[sortField];
                if (sortField === 'usageType') {
                  fieldA = ProfileUsageType[a[sortField] as any].toString();
                  fieldB = ProfileUsageType[b[sortField] as any].toString();
                }
                return sortAscending
                  ? (fieldA ?? '').localeCompare(fieldB ?? '')
                  : (fieldB ?? '').localeCompare(fieldA ?? '');
              })
              .map((profile) => {
                return (
                  <tr key={profile.profileId}>
                    <td>
                      <Link
                        to={{
                          pathname: `/Profile/${profile.profileId}`,
                        }}
                      >
                        {profile.name}
                      </Link>
                    </td>
                    <td>{profile.description}</td>
                    <td>{profile.sourceApplicationManifestName}</td>
                    <td>
                      {profile.usageType === ProfileUsageType.Internal
                        ? 'Internal'
                        : 'External'}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </>
  );
};

export default ProfileTable;
