import OrganizationSummaryTable from './components/OrganizationSummaryTable';
import { OrganizationSummaryInfo } from '../../types/AuthorizationBFFGeneratedTypes';
import { Col, Container, Row } from 'react-bootstrap';

interface MyOrganizationsProps {
  organizations: OrganizationSummaryInfo[];
}

const MyOrganizations = ({ organizations }: MyOrganizationsProps) => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h2>My Organizations</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {organizations.length ? (
              <OrganizationSummaryTable organizations={organizations} />
            ) : (
              <em>None found</em>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MyOrganizations;
