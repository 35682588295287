import { Route, Switch } from 'react-router';
import Application from '../application/Application';
import ApplicationsSummary from '../applicationsSummary/ApplicationsSummary';
import AddOrganization from '../organization/AddOrganization';
import OrganizationRouter from '../organization/OrganizationRouter';
import OrganizationsSummary from '../organizationsSummary/OrganizationsSummary';
import Profile from '../profile/Profile';
import ProfilesSummary from '../profilesSummary/ProfilesSummary';
import User from '../user/User';
import UsersSummary from '../usersSummary/UsersSummary';
import Home from './Home';
import NotAuthorized from './NotAuthorized';
import PageNotFound from './PageNotFound';
import PrivateRoute from './PrivateRoute';

export const AppBody = () => {
  return (
    <div className="container" id="AppContainer">
      <div className="row mt-3 py-5">
        <div className="col">
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute
              Route
              exact
              path="/Applications"
              component={ApplicationsSummary}
              requiredPermissions={[]}
              redirectOnUnAuthorized
            />
            <PrivateRoute
              Route
              exact
              path="/Organizations"
              component={OrganizationsSummary}
              requiredPermissions={[]}
              redirectOnUnAuthorized
            />
            <PrivateRoute
              Route
              path="/Organization/:organizationId"
              component={OrganizationRouter}
              requiredPermissions={[]}
              redirectOnUnAuthorized
            />
            <PrivateRoute
              Route
              exact
              path="/Organizations/add"
              component={AddOrganization}
              requiredPermissions={[]}
              redirectOnUnAuthorized
            />
            <PrivateRoute
              Route
              exact
              path="/Profiles"
              component={ProfilesSummary}
              requiredPermissions={[]}
              redirectOnUnAuthorized
            />
            <PrivateRoute
              Route
              exact
              path="/Users"
              component={UsersSummary}
              requiredPermissions={[]}
              redirectOnUnAuthorized
            />
            <PrivateRoute
              Route
              exact
              path="/User/:userId"
              component={User}
              requiredPermissions={[]}
              redirectOnUnAuthorized
            />
            <PrivateRoute
              Route
              exact
              path="/Profile/:profileId"
              component={Profile}
              requiredPermissions={[]}
              redirectOnUnAuthorized
            />
            <PrivateRoute
              Route
              exact
              path="/Application/:applicationManifestId"
              component={Application}
              requiredPermissions={[]}
              redirectOnUnAuthorized
            />
            <Route path="/PageNotAuthorized" component={NotAuthorized} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AppBody;
