import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  OrganizationDetailInfo,
  OrganizationUserDetailsInfo,
} from '../../../types/AuthorizationBFFGeneratedTypes';
import { OrganizationUserProfileRolesTable } from './OrganizationUserProfileRolesTable';
import { OrganizationUserStatusBadge } from '../../common/OrganizationUserStatusBadge';
import { IdentityProviderBadge } from '../../common/IdentityProviderBadge';
import { EXTERNAL_ID_ISSUER_SGI } from '../../common/Constants';
import DynamicOrgBreadcrumb from '../../common/DynamicOrgBreadcrumb';

export interface OrganizationUserInfoViewProps {
  organizationUser: OrganizationUserDetailsInfo;
  organization: OrganizationDetailInfo;
  canListOrganizations: boolean;
  onEditClick: () => void;
  onRemoveClick: () => void;
}

export const OrganizationUserInfoView = ({
  organizationUser,
  organization,
  canListOrganizations,
  onEditClick,
  onRemoveClick,
  ...props
}: OrganizationUserInfoViewProps) => {
  const userDisplayName =
    organizationUser.userInfo?.displayName &&
    organizationUser.userInfo?.displayName !== ''
      ? organizationUser.userInfo?.displayName
      : organizationUser.userInfo?.email;
  return (
    <>
      <Breadcrumb>
        <DynamicOrgBreadcrumb
          canListOrganizations={canListOrganizations ?? false}
        />
        <LinkContainer to={`/Organization/${organization?.organizationId}`}>
          <Breadcrumb.Item>{organization?.name}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
          {userDisplayName ?? 'Organization User'}
        </Breadcrumb.Item>
      </Breadcrumb>
      {organizationUser.userInfo ? (
        <>
          <Container>
            <Row>
              <Col xs="auto" className="ps-2 pe-1">
                <h2>{userDisplayName}</h2>
              </Col>
              <Col xs="auto" className="p-1">
                <IdentityProviderBadge
                  externalUserInfo={
                    organizationUser.userInfo?.externalIds?.find(
                      (e) => e.issuer !== EXTERNAL_ID_ISSUER_SGI,
                    ) ?? {}
                  }
                />
              </Col>
              <Col xs="auto" className="p-1">
                {organizationUser.isEnabled !== undefined && (
                  <OrganizationUserStatusBadge
                    isEnabled={organizationUser.isEnabled}
                  />
                )}
              </Col>
              <Col>
                <ButtonToolbar className="float-right">
                  {organizationUser.securityFlags?.canRemoveUser && (
                    <Button
                      className="my-1 mr-2"
                      variant="outline-danger"
                      onClick={onRemoveClick}
                    >
                      Remove User
                    </Button>
                  )}
                  {organizationUser.securityFlags?.canEditUser && (
                    <Button
                      className="my-1"
                      variant="primary"
                      onClick={onEditClick}
                    >
                      Edit
                    </Button>
                  )}
                </ButtonToolbar>
              </Col>
            </Row>
            <Row>
              <Col>{organizationUser.userInfo?.email}</Col>
            </Row>
            <Row></Row>
          </Container>
          <div className="my-3">{''}</div>
          <OrganizationUserProfileRolesTable
            organizationUserProfileRoles={organizationUser.profileDefaultRoles}
            organizationProfiles={organization.profiles ?? []}
          />
        </>
      ) : (
        <Container>
          <Row>
            <Col>
              <h2>Organization User Not Found</h2>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              There was a problem retrieving the organization user info.
            </Col>
          </Row>
          <Row>
            <Col>Please try the following options:</Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  Check the link or the web page address for possible typing or
                  copy-paste errors.
                </li>
                <li>
                  Try refreshing the organization user page or searching from
                  the organization user list.
                </li>
                <li>Contact support if you still can't find what you need.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default OrganizationUserInfoView;
