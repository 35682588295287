import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Accordion, Badge, Card, Table } from 'react-bootstrap';
import { ApplicationPermissionSummaryInfo } from '../../../types/AuthorizationBFFGeneratedTypes';

interface ApplicationPermissionsDisplayProps {
  application: ApplicationPermissionSummaryInfo;
}
export const ApplicationPermissionsDisplay = ({
  application,
}: ApplicationPermissionsDisplayProps) => {
  const [collapsed, setCollapsed] = useState(true);

  const permissions = [...(application.permissions ?? [])];

  return (
    <>
      <Accordion defaultActiveKey="1">
        <Card>
          <span style={{ cursor: 'pointer' }}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              {application.applicationName + ' '}
              {application.permissions && application.permissions?.length > 0 && (
                <Badge variant="primary" aria-label="permission-count">
                  {application.permissions && application.permissions?.length}
                </Badge>
              )}

              <span className="float-right">
                <FontAwesomeIcon
                  icon={collapsed ? faAngleDoubleDown : faAngleDoubleUp}
                  aria-label="role-app-toggle-icon"
                />
              </span>
            </Accordion.Toggle>
          </span>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="pb-0">
              <Table bordered striped responsive="sm">
                <thead>
                  <tr>
                    <th className="fit-width">
                      <span>Description</span>
                    </th>
                    <th className="fit-width">
                      <span>Permission Name</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {permissions.map((perm) => {
                    return (
                      <tr key={perm.nameKey}>
                        <td>{perm.description}</td>
                        <td>{perm.nameKey}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default ApplicationPermissionsDisplay;
