import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import notificationReducer from '../app/notifications/notificationSlice';
import { authorizationuiApi } from '../api/authorizationuiApi';
import { featureFlagApi } from '../api/featureFlagApi';

export const store = configureStore({
  reducer: {
    notifications: notificationReducer,
    [authorizationuiApi.reducerPath]: authorizationuiApi.reducer,
    [featureFlagApi.reducerPath]: featureFlagApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authorizationuiApi.middleware)
      .concat(featureFlagApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
