import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { AppPermissionInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';

interface ApplicationPermissionsTableProps {
  permissions: AppPermissionInfo[];
}
export const AppPermissionsTable = ({
  permissions,
}: ApplicationPermissionsTableProps) => {
  const [sortField, setSortField] = useState('nameKey');
  const [sortAscending, setSortAscending] = useState(true);
  const [hoveredSortField, setHoveredSortField] = useState('');
  const changeSortDirection = (newSortField: string) =>
    setSortAscending(sortField !== newSortField ? true : !sortAscending);

  const permissionsData = [...permissions];

  const displaySortIcon = (field: string) => {
    if (sortField !== field && hoveredSortField === field) {
      return <FontAwesomeIcon icon={faSort} title="Sort" />;
    } else if (sortField === field) {
      return (
        <FontAwesomeIcon
          icon={sortAscending ? faSortUp : faSortDown}
          title="Sort"
        />
      );
    } else {
      return <FontAwesomeIcon icon={faSort} style={{ visibility: 'hidden' }} />;
    }
  };

  const showSortToolTip = (field: string) =>
    sortField !== field && setHoveredSortField(field);

  const hideSortToolTip = () => setHoveredSortField('');
  return (
    <Table bordered striped responsive="sm">
      <thead>
        <tr>
          <th>
            <span
              role="button"
              className="text-nowrap"
              onClick={() => {
                setSortField('nameKey');
                changeSortDirection('nameKey');
              }}
              onMouseEnter={() => showSortToolTip('nameKey')}
              onMouseLeave={() => hideSortToolTip()}
            >
              Permission {displaySortIcon('nameKey')}
            </span>
          </th>
          <th>
            <span>Description</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {permissionsData.length > 0 &&
          permissionsData
            .sort((a: any, b: any) => {
              let fieldA = a[sortField];
              let fieldB = b[sortField];

              return sortAscending
                ? fieldA.localeCompare(fieldB)
                : fieldB.localeCompare(fieldA);
            })
            .map((perm) => {
              return (
                <tr key={perm.nameKey}>
                  <td>{perm.nameKey}</td>
                  <td>{perm.description}</td>
                </tr>
              );
            })}
      </tbody>
    </Table>
  );
};

export default AppPermissionsTable;
