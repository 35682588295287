import { useEffect, useState } from 'react';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export interface EditableListProps {
  values: string[];
  prefix: string;
  maxLength: number;
  newItemPlaceholderText: string;
  newItemInvalidErrorText: string;
  itemPlaceholderText: string;
  itemInvalidErrorText: string;
  isItemValid: (text: string) => boolean;
  isItemInListValid: (index: number) => boolean;
  onItemAdded: (newItem: string) => void;
  onItemChanged: (newItemValue: string, index: number) => void;
  onItemDeleted: (index: number) => void;
}

const EditableList = ({
  values,
  prefix,
  maxLength,
  newItemPlaceholderText,
  newItemInvalidErrorText,
  itemPlaceholderText,
  itemInvalidErrorText,
  isItemValid,
  isItemInListValid,
  onItemAdded,
  onItemChanged,
  onItemDeleted,
  ...props
}: EditableListProps) => {
  const [newItem, setNewItem] = useState('');
  const [isNewItemValid, setIsNewItemValid] = useState(true);

  useEffect(() => {
    if (!newItem) {
      setIsNewItemValid(true);
    }
  }, [newItem]);

  const removePrefix = (item: string): string => {
    return !prefix || item.charAt(0) !== prefix ? item : item.slice(1);
  };

  const addPrefix = (item: string): string => {
    return !prefix ? item : prefix + item;
  };

  const deleteItem = (index: number) => {
    onItemDeleted(index);
  };

  const addItem = (newItem: string) => {
    let prefixedItem = addPrefix(newItem);
    if (isItemValid(prefixedItem)) {
      onItemAdded(prefixedItem);
      setNewItem('');
      setIsNewItemValid(true);
    } else {
      setIsNewItemValid(false);
    }
  };

  const handleOnNewItemChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNewItem(event.target.value);

  const handleOnItemChange = (newItemValue: string, index: number) => {
    let prefixedItem = addPrefix(newItemValue);
    onItemChanged(prefixedItem, index);
  };

  const handleDeleteButtonClick = (
    event: React.SyntheticEvent,
    index: number,
  ) => {
    event.preventDefault();
    deleteItem(index);
  };

  const handleAddButtonClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    addItem(newItem);
  };

  const handleOnKeyUp = (event: React.KeyboardEvent) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      addItem(newItem);
    }
  };

  return (
    <>
      <Container>
        <Row key="newItemInput" className="mb-4 my-2 align-items-top">
          <Col xs={10} md={8} lg={6} className="px-0">
            <InputGroup hasValidation>
              {prefix && (
                <InputGroup.Prepend>
                  <InputGroup.Text>{prefix}</InputGroup.Text>
                </InputGroup.Prepend>
              )}
              <Form.Control
                type="text"
                maxLength={maxLength}
                placeholder={newItemPlaceholderText}
                value={newItem}
                onKeyDown={(e: React.KeyboardEvent) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                onKeyUp={handleOnKeyUp}
                onChange={handleOnNewItemChange}
                isInvalid={!isNewItemValid}
              />
              <Form.Control.Feedback type="invalid">
                {newItemInvalidErrorText}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col xs={1} className="pt-2">
            <span style={{ cursor: 'pointer' }} onClick={handleAddButtonClick}>
              <FontAwesomeIcon
                icon={faPlus}
                color="green"
                title="Add"
                name="Add"
              />
            </span>
          </Col>
        </Row>
        {values &&
          values.map((item, index) => {
            return (
              <Row key={index} className="my-2 align-items-top">
                <Col xs={10} md={8} lg={6} className="px-0">
                  <InputGroup hasValidation>
                    {prefix && (
                      <InputGroup.Prepend>
                        <InputGroup.Text>@</InputGroup.Text>
                      </InputGroup.Prepend>
                    )}
                    <Form.Control
                      type="text"
                      maxLength={maxLength}
                      placeholder={itemPlaceholderText}
                      value={removePrefix(item)}
                      onChange={(e) =>
                        handleOnItemChange(e.target.value, index)
                      }
                      isInvalid={!isItemInListValid(index)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {itemInvalidErrorText}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col xs={1} className="pt-2">
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleDeleteButtonClick(e, index)}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      color="red"
                      title="Remove"
                      name="Remove"
                    />
                  </span>
                </Col>
              </Row>
            );
          })}
      </Container>
    </>
  );
};

export default EditableList;
