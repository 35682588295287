export enum RtkTagTypes {
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATIONLIST = 'ORGANIZATIONLIST',
  USER = 'USER',
  PROFILE = 'PROFILE',
  PROFILELIST = 'PROFILELIST',
  APPLICATION = 'APPLICATION',
  FEATUREFLAGS = 'FEATUREFLAGS',
}

export default RtkTagTypes;
