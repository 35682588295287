import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { ExternalUserInfo } from '../../types/AuthorizationBFFGeneratedTypes';

export interface IdentityProviderBadgeProps {
  externalUserInfo: ExternalUserInfo;
}

enum IdentityProvider {
  Unknown = 'Unknown',
  SGI = 'SGI',
  B2C = 'B2C',
}

const GetBadgeColour = (externalUserInfo: ExternalUserInfo): string => {
  let badgeColour = '';
  switch (externalUserInfo.identityProviderDisplayName) {
    // using bootstrap classes for icon colours
    case IdentityProvider.SGI:
      badgeColour = 'text-success';
      break;
    case IdentityProvider.B2C:
      badgeColour = 'text-primary';
      break;
    case IdentityProvider.Unknown:
      badgeColour = externalUserInfo.identityProvider
        ? 'text-warning'
        : 'text-secondary';
      break;
    default:
      badgeColour = 'text-secondary';
      break;
  }
  return badgeColour;
};

const GetBadgeTitle = (externalUserInfo: ExternalUserInfo): string => {
  if (
    externalUserInfo.identityProviderDisplayName &&
    externalUserInfo.identityProviderDisplayName !== IdentityProvider.Unknown
  ) {
    return `Identity provided by ${externalUserInfo.identityProviderDisplayName}`;
  } else {
    return 'Unknown Identity Provider';
  }
};

export const IdentityProviderBadge = ({
  externalUserInfo,
  ...props
}: IdentityProviderBadgeProps) => {
  return (
    <span
      className="fa-layers fa-fw"
      style={{ textAlign: 'center', width: '1.25em' }}
      title={GetBadgeTitle(externalUserInfo)}
    >
      <span className={GetBadgeColour(externalUserInfo)}>
        <FontAwesomeIcon icon={faCertificate} size="lg" />
      </span>
      <span
        className="fa-layers-text fa-inverse"
        style={{ fontSize: 'x-small' }}
      >
        {externalUserInfo.identityProviderDisplayName &&
        externalUserInfo.identityProviderDisplayName !==
          IdentityProvider.Unknown
          ? externalUserInfo.identityProviderDisplayName
          : '?'}
      </span>
    </span>
  );
};

export default IdentityProviderBadge;
