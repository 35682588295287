import { useState } from 'react';
import {
  OrganizationDetailInfo,
  OrganizationUserEditInfo,
} from '../../../types/AuthorizationBFFGeneratedTypes';
import EditorDisplay from './EditorDisplay';
import {
  UserInfo,
  ProfileDefaultRoleSummary,
  ProfileDefaultRoleKey,
} from '../helpers/editor-types';
import { augmentRoleSelection } from '../helpers/editor-utilities';

interface EditorProps {
  organization: OrganizationDetailInfo;
  user: UserInfo;
  isEnabled: boolean;
  availableProfileDefaultRoles: ProfileDefaultRoleSummary[];
  selectedProfileDefaultRoles: ProfileDefaultRoleKey[];
  handleSave: (details: OrganizationUserEditInfo) => Promise<void>;
  handleCancel: () => void;
}

const Editor = (props: EditorProps) => {
  const {
    isEnabled,
    availableProfileDefaultRoles,
    selectedProfileDefaultRoles,
    organization,
  } = props;

  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [enabled, setEnabled] = useState(isEnabled);
  const [data, setData] = useState(
    augmentRoleSelection(
      availableProfileDefaultRoles,
      selectedProfileDefaultRoles,
      organization.profiles ?? [],
    ),
  );

  const handleProfileRoleSelectedChange = (
    profileId: string,
    profileDefaultRoleId: string,
    isSelected: boolean,
  ) => {
    const entry = data.find(
      (x) =>
        x.profileId === profileId &&
        x.profileDefaultRoleId === profileDefaultRoleId,
    );

    if (entry) entry.isSelected = isSelected;

    // this could be done better
    setHasMadeChanges(true);
    setData([...data]);
  };

  const makeSavePayload = (): OrganizationUserEditInfo => ({
    isEnabled: enabled ?? false,
    organizationRoleIds: [],
    profileRoles: data
      .filter((x) => x.isSelected)
      .map((x) => {
        return {
          profileId: x.profileId ?? '',
          profileDefaultRoleId: x.profileDefaultRoleId ?? '',
        };
      }),
  });

  const handleSaveClick = async () => {
    await props.handleSave(makeSavePayload());
  };

  const handleCancelClick = props.handleCancel;

  const hasOutstandingChanges = enabled !== isEnabled || hasMadeChanges;

  return (
    <EditorDisplay
      {...props}
      profileDefaultRoles={data}
      handleSetEnabled={setEnabled}
      handleProfileRoleSelectedChange={handleProfileRoleSelectedChange}
      onSave={handleSaveClick}
      handleCancelClick={handleCancelClick}
      hasOutstandingChanges={hasOutstandingChanges}
      isEnabled={enabled}
    />
  );
};

export default Editor;
