import { Button, Dropdown } from 'react-bootstrap';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import {
  loginRequest,
  logoutRequest,
  getScopes,
} from '../../../auth/authConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import './SignInSignOutButton.css';
import GetB2CPolicyInfo from '../../../auth/b2cPolicies';

const SignInSignOutButton = () => {
  const { instance, accounts } = useMsal();
  let account: AccountInfo | null;
  account = useAccount(accounts[0] || {});

  let authority = GetB2CPolicyInfo().authorities.editProfile.authority;
  let scopes = getScopes();

  return (
    <>
      <AuthenticatedTemplate>
        <Dropdown>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-user">
            {account?.name}
            <FontAwesomeIcon icon={faUserCircle} className="ml-1" />
          </Dropdown.Toggle>
          <Dropdown.Menu align={'right'}>
            <Dropdown.Item
              onClick={() => {
                instance.loginRedirect({
                  scopes: scopes,
                  authority: authority,
                });
              }}
            >
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => instance.logoutRedirect(logoutRequest)}
            >
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button
          variant="secondary"
          className="ml-auto"
          title="Sign In"
          onClick={() => {
            instance.loginRedirect(loginRequest);
          }}
        >
          Sign In
        </Button>
      </UnauthenticatedTemplate>
    </>
  );
};

export default SignInSignOutButton;
