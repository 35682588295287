import './LoadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <ul className="loader" id="loadingImage" title="Loading...">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  );
};

export default LoadingSpinner;
