import { useEffect, useState } from 'react';
import {
  useAddUserMutation,
  useQueryUsersMutation,
} from '../../api/authorizationuiApi';
import { Breadcrumb } from 'react-bootstrap';
import {
  addSuccess,
  CreateNotificationModel,
} from '../notifications/notificationSlice';
import {
  ApplicationMessageSeverityType,
  ApplicationProblemDetails,
  UserQuery,
} from '../../types/AuthorizationBFFGeneratedTypes';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import UserTable from './components/UserTable';
import { AddUserModal } from './components/AddUserModal';
import { UserSearch } from './components/UserSearch';
import { Button, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { useGetFeatureFlagsQuery } from '../../api/featureFlagApi';
import { useAppDispatch } from '../hooks';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import LoadingSpinnerModal from '../common/loadingSpinner/LoadingSpinnerModal';
import { useHistory } from 'react-router';
import qs from 'qs';

export const UsersSummary = () => {
  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const canCreateUsers = featureFlags?.canCreateUsers;
  const canQueryUsers = featureFlags?.canQueryUsers;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showAdd, setShowAdd] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');

  const [
    addUser,
    {
      isError: isAddUserError,
      error: addUserError,
      isSuccess: isAddUserSuccess,
      isLoading: isAddUserLoading,
      data: addedUser,
    },
  ] = useAddUserMutation();
  const [
    queryUser,
    {
      isError: isQueryUserError,
      error: queryUserError,
      data: queryData,
      isLoading,
    },
  ] = useQueryUsersMutation();

  const [
    existingUserQuery,
    {
      isError: isExistingQueryUserError,
      error: existingQueryUserError,
      data: existingUserQueryData,
      isSuccess: existingQueryUserSuccess,
    },
  ] = useQueryUsersMutation();

  const handleUserAdd = (email: string) => {
    existingUserQuery({ email: email });
    setNewUserEmail(email);
  };

  const handleUserAddCancel = () => {
    setShowAdd(false);
  };

  useEffect(() => {
    if (existingQueryUserSuccess) {
      switch (existingUserQueryData?.length) {
        //The user doesn't already exist
        case 0:
          addUser({ email: newUserEmail });
          break;
        //The user already exists
        case 1:
          const notification: ApplicationProblemDetails = {
            title: 'Add User',
            messages: [
              {
                severity: ApplicationMessageSeverityType.Information,
                message: `User ${existingUserQueryData[0]?.email} already exists.`,
              },
            ],
          };
          apiMessageNotification(notification);
          setShowAdd(false);
          history.push(`/User/${existingUserQueryData[0]?.userId}`);
          break;
        //Should never fall into here since more than one user can't exist with the same email
        default:
          const notFoundNotification: ApplicationProblemDetails = {
            title: 'Add User',
            messages: [
              {
                severity: ApplicationMessageSeverityType.Error,
                message: `There was a problem determining if the user already exists.`,
              },
            ],
          };
          apiMessageNotification(notFoundNotification);
          setShowAdd(true);
      }
    }
  }, [
    existingQueryUserSuccess,
    addUser,
    existingUserQueryData,
    history,
    newUserEmail,
  ]);

  useEffect(() => {
    if (isAddUserError) {
      apiMessageNotification(addUserError as ApplicationProblemDetails);
      setShowAdd(true);
    }
    if (isQueryUserError) {
      apiMessageNotification(queryUserError as ApplicationProblemDetails);
    }
    if (isExistingQueryUserError) {
      apiMessageNotification(
        existingQueryUserError as ApplicationProblemDetails,
      );
      setShowAdd(true);
    }
  }, [
    isAddUserError,
    addUserError,
    isQueryUserError,
    queryUserError,
    isExistingQueryUserError,
    existingQueryUserError,
  ]);

  useEffect(() => {
    if (isAddUserSuccess) {
      setShowAdd(false);
      const notification: CreateNotificationModel = {
        header: 'Add User',
        message: `User added successfully.`,
      };
      dispatch(addSuccess(notification));
      history.push(`/User/${addedUser?.userId}`);
    }
  }, [dispatch, isAddUserSuccess, addedUser, history]);

  const handleQuerySearch = (query: UserQuery) => {
    history.push(`?${qs.stringify(query)}`);
    queryUser(query);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Users</Breadcrumb.Item>
      </Breadcrumb>
      <AddUserModal
        show={showAdd}
        onAdd={handleUserAdd}
        onCancel={handleUserAddCancel}
      />
      <LoadingSpinnerModal show={isAddUserLoading} />
      <Container>
        <Row className="mb-2">
          <Col>
            <h2>Users</h2>
          </Col>
          <Col>
            <ButtonToolbar className="float-right">
              {canCreateUsers && (
                <Button
                  className="my-1"
                  variant="outline-secondary"
                  onClick={() => {
                    setShowAdd(true);
                  }}
                >
                  Add User
                </Button>
              )}
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <Col>
            {canQueryUsers && <UserSearch onSearch={handleQuerySearch} />}
          </Col>
        </Row>
        <Row>
          <Col>
            {isLoading ? (
              <LoadingSpinner />
            ) : queryData ? (
              queryData.length ? (
                <UserTable users={queryData} />
              ) : (
                'No Results'
              )
            ) : (
              []
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UsersSummary;
