import {
  ApplicationProblemDetails,
  OrganizationUserEditInfo,
} from '../../types/AuthorizationBFFGeneratedTypes';
import Editor from './components/Editor';
import { useHistory, useParams } from 'react-router';
import {
  useGetOrganizationQuery,
  useGetOrganizationUserQuery,
  useUpdateOrganizationUserMutation,
} from '../../api/authorizationuiApi';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import { useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import {
  addSuccess,
  CreateNotificationModel,
} from '../notifications/notificationSlice';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import LoadingSpinnerModal from '../common/loadingSpinner/LoadingSpinnerModal';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useGetFeatureFlagsQuery } from '../../api/featureFlagApi';
import DynamicOrgBreadcrumb from '../common/DynamicOrgBreadcrumb';

interface ParamTypes {
  organizationId: string;
  userId: string;
}

const EditOrganizationUser = () => {
  const history = useHistory();
  const { organizationId, userId } = useParams<ParamTypes>();
  const dispatch = useAppDispatch();

  const {
    data: organization,
    isLoading: isGetOrganizationLoading,
    isError: isGetOrganizationError,
    error: getOrganizationError,
    isFetching: isGetOrganizationFetching,
  } = useGetOrganizationQuery(organizationId);

  const {
    data: organizationUserDetails,
    isLoading: isGetOrganizationUserLoading,
    isError: isGetOrganizationUserError,
    error: getOrganizationUserError,
  } = useGetOrganizationUserQuery({
    organizationId,
    userId,
  });

  const [
    updateOrganizationUser,
    {
      isLoading: isLoadingUpdateOrgUser,
      isError: isUpdateOrganizationUserError,
      error: updateOrganizationUserError,
      isSuccess: isUpdateOrganizationUserSuccess,
    },
  ] = useUpdateOrganizationUserMutation();

  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const { canListOrganizations } = featureFlags || {};

  useEffect(() => {
    if (isGetOrganizationError) {
      apiMessageNotification(getOrganizationError as ApplicationProblemDetails);
    }
  }, [isGetOrganizationError, getOrganizationError]);

  useEffect(() => {
    if (isGetOrganizationUserError) {
      apiMessageNotification(
        getOrganizationUserError as ApplicationProblemDetails,
      );
    }
  }, [isGetOrganizationUserError, getOrganizationUserError]);

  useEffect(() => {
    if (isUpdateOrganizationUserError) {
      apiMessageNotification(
        updateOrganizationUserError as ApplicationProblemDetails,
      );
    }
  }, [isUpdateOrganizationUserError, updateOrganizationUserError]);

  useEffect(() => {
    if (isUpdateOrganizationUserSuccess) {
      const notification: CreateNotificationModel = {
        header: 'Edit Organization User',
        message: `User updated successfully.`,
      };
      dispatch(addSuccess(notification));
      history.push(`/Organization/${organizationId}/User/${userId}`);
    }
  }, [
    dispatch,
    isUpdateOrganizationUserSuccess,
    history,
    organizationId,
    userId,
  ]);

  const handleSave = (details: OrganizationUserEditInfo) => {
    if (organizationId && userId) {
      details.versionNumber = organizationUserDetails?.versionNumber;
      updateOrganizationUser({
        organizationId: organizationId,
        userId: userId,
        editInfo: details,
      });
    }

    return Promise.resolve();
  };

  const handleCancel = () => {
    history.push(`/Organization/${organizationId}/User/${userId}`);
  };

  const userDisplayName =
    organizationUserDetails?.userInfo?.displayName &&
    organizationUserDetails.userInfo.displayName !== ''
      ? organizationUserDetails.userInfo.displayName
      : organizationUserDetails?.userInfo?.email;

  return (
    <>
      {isGetOrganizationUserLoading ||
      isGetOrganizationLoading ||
      isGetOrganizationFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <LoadingSpinnerModal show={isLoadingUpdateOrgUser} />
          <Breadcrumb>
            <DynamicOrgBreadcrumb
              canListOrganizations={canListOrganizations ?? false}
            />
            <LinkContainer to={`/Organization/${organizationId}`}>
              <Breadcrumb.Item>{organization?.name}</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>
              {userDisplayName ?? 'Organization User'}
            </Breadcrumb.Item>
          </Breadcrumb>
          {organizationUserDetails?.userInfo ? (
            <Editor
              organization={organization ?? {}}
              user={organizationUserDetails?.userInfo ?? {}}
              availableProfileDefaultRoles={
                organization?.profileDefaultRoles ?? []
              }
              isEnabled={organizationUserDetails?.isEnabled ?? false}
              selectedProfileDefaultRoles={
                organizationUserDetails?.profileDefaultRoles ?? []
              }
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          ) : (
            <Container>
              <Row>
                <Col>
                  <span className="text-muted">User not found.</span>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default EditOrganizationUser;
