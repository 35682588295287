import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ApplicationInfo } from '../../../types/AuthorizationBFFGeneratedTypes';

export interface ApplicationTableProps {
  apps: ApplicationInfo[];
}

export const ApplicationTable = ({ apps }: ApplicationTableProps) => {
  const [sortField, setSortField] = useState('applicationName');
  const [sortAscending, setSortAscending] = useState(true);
  const [hoveredSortField, setHoveredSortField] = useState('');

  const applicationsTableData = [...apps];

  const changeSortDirection = (newSortField: string) =>
    setSortAscending(sortField !== newSortField ? true : !sortAscending);

  const displaySortIcon = (field: string) => {
    if (sortField !== field && hoveredSortField === field) {
      return <FontAwesomeIcon icon={faSort} title="Sort" />;
    } else if (sortField === field) {
      return (
        <FontAwesomeIcon
          icon={sortAscending ? faSortUp : faSortDown}
          title="Sort"
        />
      );
    } else {
      return <FontAwesomeIcon icon={faSort} style={{ visibility: 'hidden' }} />;
    }
  };

  const showSortToolTip = (field: string) =>
    sortField !== field && setHoveredSortField(field);

  const hideSortToolTip = () => setHoveredSortField('');

  return (
    <Table bordered striped responsive="sm">
      <thead>
        <tr>
          <th>
            <span
              role="button"
              className="text-nowrap"
              onClick={() => {
                setSortField('applicationName');
                changeSortDirection('applicationName');
              }}
              onMouseEnter={() => showSortToolTip('applicationName')}
              onMouseLeave={() => hideSortToolTip()}
            >
              Application Name {displaySortIcon('applicationName')}
            </span>
          </th>
          <th>
            <span
              role="button"
              className="text-nowrap"
              onClick={() => {
                setSortField('description');
                changeSortDirection('description');
              }}
              onMouseEnter={() => showSortToolTip('description')}
              onMouseLeave={() => hideSortToolTip()}
            >
              Description {displaySortIcon('description')}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {applicationsTableData.length > 0 &&
          applicationsTableData
            .sort((a: any, b: any) =>
              sortAscending
                ? (a[sortField] ?? '').localeCompare(b[sortField] ?? '')
                : (b[sortField] ?? '').localeCompare(a[sortField] ?? ''),
            )
            .map((application) => {
              return (
                <tr key={application.applicationManifestId}>
                  <td>
                    <Link
                      to={{
                        pathname: `/Application/${application.applicationManifestId}`,
                        state: {
                          app: {
                            applicationManifestId:
                              application.applicationManifestId,
                            applicationName: application.applicationName,
                            description: application.description,
                            permissions: application.permissions,
                          },
                        },
                      }}
                    >
                      {application.applicationName}
                    </Link>
                  </td>
                  <td>{application.description}</td>
                </tr>
              );
            })}
      </tbody>
    </Table>
  );
};

export default ApplicationTable;
