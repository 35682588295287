import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import {
  OrganizationProfileSummaryInfo,
  OrganizationType,
} from '../../../types/AuthorizationBFFGeneratedTypes';

interface ConfirmOrganizationTypeChangeModalProps {
  show: boolean;
  newType: OrganizationType | null;
  invalidProfiles: OrganizationProfileSummaryInfo[];
  onHide: () => void;
  onCancel: (event: React.SyntheticEvent) => void;
  onConfirm: (event: React.SyntheticEvent) => void;
}

export const ConfirmOrganizationTypeChangeModal = ({
  show,
  newType,
  invalidProfiles,
  onHide,
  onCancel,
  onConfirm,
}: ConfirmOrganizationTypeChangeModalProps) => {
  return (
    <Modal show={show}>
      <Modal.Header closeButton={true} onHide={onHide}>
        <Modal.Title>Organization Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              Changing the Organization Type to{' '}
              <b>{OrganizationType[newType as any]}</b> will remove the
              following{' '}
              {invalidProfiles && invalidProfiles.length > 1
                ? 'profiles'
                : 'profile'}
              :
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                {invalidProfiles &&
                  invalidProfiles.map((profile) => <li>{profile.name}</li>)}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>Do you want to continue?</Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" className="mr-2" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Change type and remove{' '}
          {invalidProfiles && invalidProfiles.length > 1
            ? 'profiles'
            : 'profile'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmOrganizationTypeChangeModal;
