import { Route, Switch, useRouteMatch } from 'react-router';
import PrivateRoute from '../common/PrivateRoute';
import Organization from './Organization';
import OrganizationUserRouter from '../organizationUser/OrganizationUserRouter';
import EditOrganization from './EditOrganization';

const OrganizationRouter = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <PrivateRoute
          Route
          path={`${path}/User/:userId`}
          component={OrganizationUserRouter}
          requiredPermissions={[]}
          redirectOnUnAuthorized
        />
        <PrivateRoute
          Route
          exact
          path={`${path}`}
          component={Organization}
          requiredPermissions={[]}
          redirectOnUnAuthorized
        />
        <PrivateRoute
          Route
          exact
          path={`${path}/edit`}
          component={EditOrganization}
          requiredPermissions={[]}
          redirectOnUnAuthorized
        />
        <Route path="*" component={NoMatch} />
      </Switch>
    </>
  );
};

const NoMatch = () => <div>No route match</div>;

export default OrganizationRouter;
