export const Footer = () => {
  return (
    <footer className="footer fixed-bottom mt-auto py-2 bg-light">
      <div className="container text-center">
        <span className="text-muted small">
          &copy; {new Date().getFullYear()} SGI. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
