import {
  getActiveAccountPermissions,
  isAuthorized,
} from '../../auth/authUtilities';
import { Redirect } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

interface AuthorizedParams {
  requiredPermissions: string[];
  redirectOnUnAuthorized?: Boolean;
  requireAll?: boolean;
  children: JSX.Element;
}
const Authorized = ({
  requiredPermissions,
  redirectOnUnAuthorized,
  children,
  requireAll = true,
}: AuthorizedParams) => {
  const isAuthenticated = useIsAuthenticated();
  let permissions = getActiveAccountPermissions() ?? [];
  if (
    isAuthenticated &&
    isAuthorized(permissions, requiredPermissions, requireAll)
  ) {
    return children;
  } else if (redirectOnUnAuthorized) {
    return <Redirect to={{ pathname: '/PageNotAuthorized' }} />;
  } else {
    return <div data-testid="unauthorizedComponent" />;
  }
};

export default Authorized;
