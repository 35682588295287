import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { UserInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import Confirm from '../../common/Confirm';
import { EXTERNAL_ID_ISSUER_SGI } from '../../common/Constants';
import { IdentityProviderBadge } from '../../common/IdentityProviderBadge';
import LoadingSpinnerModal from '../../common/loadingSpinner/LoadingSpinnerModal';

interface UserInfoProps {
  user: UserInfo;
  isDeleteUserLoading: boolean;
  onDeleteUserConfirm: () => void;
}

export const UserInformation = ({
  user,
  isDeleteUserLoading,
  onDeleteUserConfirm,
}: UserInfoProps) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = () => {
    onDeleteUserConfirm();
    setShowDeleteConfirm(false);
  };

  const handleConfirmDeleteCancel = () => {
    setShowDeleteConfirm(false);
  };

  const confirmDeleteUserModalBody = () => {
    return (
      <>
        <Container>
          <Row>
            <Col>
              This will permanently remove the user in the authorization system
              and from all organizations.
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="my-2">
                <li>
                  <span className="font-weight-bold">
                    {user?.displayName
                      ? `${user?.displayName} (${user?.email})`
                      : user?.email}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Card border="warning" body className="my-2 py-0">
          <Container>
            <Row>
              <Col xs="auto" className="text-warning">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="2x"
                  title="Warning"
                  name="Warning"
                />
              </Col>
              <Col>
                <p>
                  If you just want to remove the user's access in an application
                  or organization, you can instead edit the user in the
                  organization.
                </p>
                <p className="mb-0">
                  The user may still be able to sign-in but they will lose all
                  currently assigned roles.
                </p>
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  };

  return (
    <>
      <LoadingSpinnerModal show={isDeleteUserLoading} />
      {showDeleteConfirm && (
        <Confirm
          danger
          title="Delete User?"
          show={showDeleteConfirm}
          bodyElement={confirmDeleteUserModalBody}
          confirmLabel="Delete User"
          onConfirm={handleDeleteConfirm}
          onCancel={handleConfirmDeleteCancel}
        />
      )}
      <Container>
        <Row>
          <Col xs="auto" className="ps-2 pe-1">
            <h2>{user?.displayName ? user?.displayName : user?.email}</h2>
          </Col>
          <Col xs="auto" className="p-1">
            <IdentityProviderBadge
              externalUserInfo={
                user?.externalIds?.find(
                  (e) => e.issuer !== EXTERNAL_ID_ISSUER_SGI,
                ) ?? {}
              }
            />
          </Col>
          <Col>
            <ButtonToolbar className="float-right">
              {user?.securityFlags?.canDelete && (
                <Button
                  className="m-1"
                  variant="outline-danger"
                  onClick={handleDeleteClick}
                >
                  Delete User
                </Button>
              )}
            </ButtonToolbar>
          </Col>
        </Row>
        <Row xs={2} md={4} lg={6} className="my-2">
          <Col>
            <span className="font-weight-bold">First Name</span>
          </Col>
          <Col>{user.givenName}</Col>
        </Row>
        <Row xs={2} md={4} lg={6} className="my-2">
          <Col>
            <span className="font-weight-bold">Last Name</span>
          </Col>
          <Col>{user.surname}</Col>
        </Row>
        <Row xs={2} md={4} lg={6} className="my-2">
          <Col>
            <span className="font-weight-bold">Email</span>
          </Col>
          <Col>{user.email}</Col>
        </Row>
      </Container>
    </>
  );
};

export default UserInformation;
