import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import { useAppDispatch } from '../../hooks';
import {
  addWarning,
  CreateNotificationModel,
} from '../../notifications/notificationSlice';
import { useHistory } from 'react-router';
import qs from 'qs';

export interface ProfileSearchQuery {
  profileName: string;
  profileSourceApp: string;
}

interface ProfileSearchFormProps {
  appNames: string[];
  onSearch: (queryParameters: ProfileSearchQuery) => void;
}

export const isValidNameCharacters = (name: string) => {
  const validName = /^[a-zA-Z0-9_.-]*$/i;
  if (name.match(validName)) {
    return true;
  } else {
    return false;
  }
};

export const ProfileSearchForm = ({
  appNames,
  onSearch,
}: ProfileSearchFormProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [autoRunFilter, setAutoRunFilter] = useState(false);
  const [profileQuery, setProfileQuery] = useState({} as ProfileSearchQuery);

  useEffect(() => {
    const filterParams = history.location.search.substring(1);
    const filtersFromParams = qs.parse(filterParams);
    if (
      filtersFromParams.profileName?.toString() ||
      filtersFromParams.profileSourceApp?.toString()
    ) {
      setProfileQuery({
        profileName: filtersFromParams.profileName?.toString() ?? '',
        profileSourceApp: filtersFromParams.profileSourceApp?.toString() ?? '',
      });
      setAutoRunFilter(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (autoRunFilter === true) {
      setAutoRunFilter(false);
      performQuerySearch();
    }
    // eslint-disable-next-line
  }, [profileQuery]);

  const handleQueryUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    let inputField = event.target.name;
    let inputValue = event.target.value;
    setProfileQuery({
      ...profileQuery,
      [inputField]: inputValue,
    });
  };

  const handleQuerySearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    performQuerySearch();
  };

  const performQuerySearch = async () => {
    if (isValidNameCharacters(profileQuery.profileName ?? '')) {
      let queryParameters = {
        profileName: (profileQuery.profileName ?? '').toString(),
        profileSourceApp: (profileQuery.profileSourceApp ?? '').toString(),
      } as ProfileSearchQuery;
      onSearch(queryParameters);
    } else {
      const notification: CreateNotificationModel = {
        header: 'Profile Search',
        message: `Please follow the field requirements.`,
      };
      dispatch(addWarning(notification));
    }
  };

  return (
    <Form noValidate onSubmit={handleQuerySearch}>
      <Form.Row className="align-items-center mb-4">
        <Col xs="auto" lg="4" className="mt-3">
          <Form.Group controlId="Profile Name">
            <Form.Label srOnly>Profile Name</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FontAwesomeIcon
                    icon={faSearch}
                    title="Search"
                    name="Search"
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                autoFocus
                type="text"
                maxLength={100}
                placeholder="Profile Name"
                isInvalid={
                  !isValidNameCharacters(profileQuery.profileName ?? '')
                }
                value={profileQuery.profileName ?? ''}
                name="profileName"
                onChange={handleQueryUpdate}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid name characters: Can only contain
                alphanumeric characters and the following symbols: _ - .
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs="auto" className="mt-3">
          <Form.Group controlId="Profile Source Application">
            <Form.Label srOnly>Profile Source Application</Form.Label>
            <Form.Control
              as="select"
              defaultValue={''}
              style={{ width: 'auto' }}
              name="profileSourceApp"
              onChange={handleQueryUpdate}
            >
              <option value={''}>All Applications</option>
              {appNames.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs="auto">
          <Button className="my-1" variant="primary" type="submit">
            Search
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};
