import { useEffect } from 'react';
import { Breadcrumb, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import {
  ApplicationProblemDetails,
  ProfileUsageType,
} from '../../types/AuthorizationBFFGeneratedTypes';
import { useGetProfileQuery } from '../../api/authorizationuiApi';
import ProfileRoleDisplay from './components/ProfileRoleDisplay';
import ApplicationPermissionsDisplay from './components/ApplicationPermissionsDisplay';

interface ParamTypes {
  profileId: string;
}
export const Profile = () => {
  const { profileId } = useParams<ParamTypes>();

  const {
    data: profileDetails,
    isLoading,
    isError,
    error,
  } = useGetProfileQuery({ profileId: profileId });

  useEffect(() => {
    if (isError) {
      apiMessageNotification(error as ApplicationProblemDetails);
    }
  }, [isError, error]);

  return (
    <>
      <Breadcrumb role="breadcrumb">
        <LinkContainer to="/Profiles">
          <Breadcrumb.Item>Profiles</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{profileDetails?.name}</Breadcrumb.Item>
      </Breadcrumb>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Container>
            <Row>
              <Col>
                <h2>{profileDetails?.name}</h2>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>{profileDetails?.description}</Col>
            </Row>
            <Row className="mb-4">
              <Col>Profile Id: {profileDetails?.profileId}</Col>
            </Row>
            {profileDetails?.usageType && (
              <Row className="mb-4">
                <Col>
                  Usage Type: {ProfileUsageType[profileDetails?.usageType]}
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Tabs
                  defaultActiveKey="defaultRoles"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="defaultRoles" title="Roles">
                    {profileDetails?.roles?.map((role) => {
                      return (
                        <ProfileRoleDisplay
                          role={role}
                          key={role.profileDefaultRoleId}
                        />
                      );
                    })}
                  </Tab>
                  <Tab
                    eventKey="applicationPermissions"
                    title="Available Application Permissions"
                  >
                    {profileDetails?.applicationPermissions?.map((app) => {
                      return (
                        <ApplicationPermissionsDisplay application={app} />
                      );
                    })}
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Profile;
