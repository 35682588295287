import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { getScopes } from '../auth/authConfig';
import { msalInstance } from '../auth/initializeMsal';
import AppBody from './common/AppBody';
import EnvironmentWatermark from './common/EnvironmentWatermark';
import Footer from './common/Footer';
import LoadingSpinnerModal from './common/loadingSpinner/LoadingSpinnerModal';
import NavigationBar from './navigationBar/NavigationBar';
import Notifications from './notifications/Notifications';

function App() {
  var scopes = getScopes();

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        loadingComponent={() => <LoadingSpinnerModal show={true} />}
        errorComponent={({ error }) => (
          <>{console.log('ERROR: ', error?.errorMessage)}</>
        )}
        authenticationRequest={{
          scopes: scopes,
        }}
      >
        <EnvironmentWatermark />
        <Notifications />
        <BrowserRouter>
          <NavigationBar />
          <AppBody />
          <Footer />
        </BrowserRouter>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
