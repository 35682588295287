import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import {
  addSuccess,
  CreateNotificationModel,
} from '../notifications/notificationSlice';
import EditOrganizationForm, {
  getProfileIds,
} from './components/EditOrganizationForm';
import {
  OrganizationDetailInfo,
  OrganizationCreateInfo,
  ApplicationProblemDetails,
  OrganizationProfileSummaryInfo,
  OrganizationType,
} from '../../types/AuthorizationBFFGeneratedTypes';
import { Breadcrumb } from 'react-bootstrap';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import LoadingSpinnerModal from '../common/loadingSpinner/LoadingSpinnerModal';
import DynamicOrgBreadcrumb from '../common/DynamicOrgBreadcrumb';
import { useAppDispatch } from '../hooks';
import {
  useAddOrganizationMutation,
  useGetOrganizationProfileSummariesQuery,
} from '../../api/authorizationuiApi';
import { useGetFeatureFlagsQuery } from '../../api/featureFlagApi';

export const AddOrganization = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [organizationDetailInfoData, setOrganizationDetailInfoData] =
    useState<OrganizationDetailInfo>({
      isEnabled: true,
      organizationType: OrganizationType.Undefined,
    });

  const {
    data: availableProfiles,
    isError: isAvailableProfilesQueryError,
    error: availableProfilesQueryError,
    isLoading: isAvailableProfilesQueryLoading,
  } = useGetOrganizationProfileSummariesQuery();

  const [
    addOrganization,
    {
      data: newOrg,
      isError: isAddOrganzationError,
      error: addOrganizationError,
      isSuccess: isAddOrganizationSuccess,
      isLoading: isAddOrganizationLoading,
    },
  ] = useAddOrganizationMutation();

  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const { canListOrganizations } = featureFlags || {};

  useEffect(() => {
    if (isAvailableProfilesQueryError) {
      apiMessageNotification(
        availableProfilesQueryError as ApplicationProblemDetails,
      );
    }
    if (isAddOrganzationError) {
      apiMessageNotification(addOrganizationError as ApplicationProblemDetails);
    }
  }, [
    isAvailableProfilesQueryError,
    availableProfilesQueryError,
    isAddOrganzationError,
    addOrganizationError,
  ]);

  useEffect(() => {
    if (isAddOrganizationSuccess) {
      const notification: CreateNotificationModel = {
        header: 'Add Organization',
        message: `${organizationDetailInfoData.name} created.`,
      };
      dispatch(addSuccess(notification));
      history.push(`/Organization/${newOrg?.organizationId}`);
    }
  }, [
    dispatch,
    history,
    isAddOrganizationSuccess,
    organizationDetailInfoData.name,
    newOrg?.organizationId,
  ]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleOrganizationTypeAndProfilesChange = (
    newOrganizationType: OrganizationType | null,
    invalidProfiles: OrganizationProfileSummaryInfo[],
  ) => {
    const updatedProfiles = organizationDetailInfoData.profiles?.filter(
      (profile) =>
        !invalidProfiles.map((p) => p.profileId).includes(profile.profileId),
    );
    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      organizationType: newOrganizationType,
      profiles: updatedProfiles,
    });
  };

  const handleOrganizationTypeChange = (
    newOrganizationType: OrganizationType | null,
  ) => {
    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      organizationType: newOrganizationType,
    });
  };

  const handleOnEmailDomainAdded = (emailDomain: string) => {
    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      autoEnrollEmailSuffixes: [
        ...(organizationDetailInfoData.autoEnrollEmailSuffixes ?? []),
        emailDomain,
      ],
    });
  };

  const handleOnEmailDomainChanged = (emailDomain: string, index: number) => {
    const emailDomains = [
      ...(organizationDetailInfoData.autoEnrollEmailSuffixes ?? []),
    ];

    emailDomains[index] && (emailDomains[index] = emailDomain);

    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      autoEnrollEmailSuffixes: emailDomains,
    });
  };

  const handleOnEmailDomainDeleted = (index: number) => {
    const newList = [
      ...(organizationDetailInfoData.autoEnrollEmailSuffixes ?? []),
    ];
    newList.splice(index, 1);

    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      autoEnrollEmailSuffixes: newList,
    });
  };

  const handleProfileSelectionChange = (
    profileId: string,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      //add to list
      setOrganizationDetailInfoData((currentData) => ({
        ...currentData,
        profiles: [...(currentData.profiles ?? []), { profileId: profileId }],
      }));
    } else {
      //remove from list
      const updatedProfiles = organizationDetailInfoData.profiles?.filter(
        (profile) => profile.profileId !== profileId,
      );
      setOrganizationDetailInfoData((currentData) => ({
        ...currentData,
        profiles: updatedProfiles,
      }));
    }
  };

  const handleCancel = (event: React.SyntheticEvent) => {
    history.goBack();
  };

  const handleSaveOrganization = () => {
    let organizationCreateInfo: OrganizationCreateInfo = {
      name: organizationDetailInfoData.name ?? '', //change bff model to nullable
      isEnabled: organizationDetailInfoData.isEnabled ?? false, //change bff model to nullable
      autoEnrollEmailSuffixes:
        organizationDetailInfoData.autoEnrollEmailSuffixes,
      profiles: getProfileIds(organizationDetailInfoData.profiles ?? []),
      organizationType:
        organizationDetailInfoData.organizationType ??
        OrganizationType.Undefined,
    };
    addOrganization(organizationCreateInfo);
  };

  return (
    <>
      {isAvailableProfilesQueryLoading && !isAvailableProfilesQueryError ? (
        <LoadingSpinner />
      ) : (
        <>
          <LoadingSpinnerModal show={isAddOrganizationLoading} />
          <Breadcrumb>
            <DynamicOrgBreadcrumb
              canListOrganizations={canListOrganizations ?? false}
            />
            <Breadcrumb.Item active>New Organization</Breadcrumb.Item>
          </Breadcrumb>
          <EditOrganizationForm
            organizationDetailInfo={organizationDetailInfoData}
            availableProfiles={availableProfiles ?? []}
            onInputChange={handleInputChange}
            onOrganizationTypeChange={handleOrganizationTypeChange}
            onOrganizationTypeAndProfilesChange={
              handleOrganizationTypeAndProfilesChange
            }
            onProfileSelectionChange={handleProfileSelectionChange}
            onSaveOrganization={handleSaveOrganization}
            onCancel={handleCancel}
            onEmailDomainAdded={handleOnEmailDomainAdded}
            onEmailDomainChanged={handleOnEmailDomainChanged}
            onEmailDomainDeleted={handleOnEmailDomainDeleted}
          />
        </>
      )}
    </>
  );
};

export default AddOrganization;
