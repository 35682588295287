import {
  Button,
  Col,
  Container,
  Form,
  Row,
  ButtonToolbar,
  Modal,
} from 'react-bootstrap';
import { useState } from 'react';
import {
  OrganizationInfo,
  ProfileDefaultRoleSelectionSummary,
  UserInfo,
} from '../helpers/editor-types';
import { IdentityProviderBadge } from '../../common/IdentityProviderBadge';
import { EXTERNAL_ID_ISSUER_SGI } from '../../common/Constants';
import { OrganizationUserGroupedRolesEditor } from './OrganizationUserGroupedRolesEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface EditorDisplayProps {
  organization: OrganizationInfo;
  user: UserInfo;
  isEnabled: boolean;
  profileDefaultRoles: ProfileDefaultRoleSelectionSummary[];
  hasOutstandingChanges: boolean;
  handleSetEnabled: (isEnabled: boolean) => void;
  handleProfileRoleSelectedChange: (
    profileId: string,
    profileDefaultRoleId: string,
    isSelected: boolean,
  ) => void;
  onSave: () => void;
  handleCancelClick: () => void;
}

const EditorDisplay = ({
  organization,
  user,
  isEnabled,
  profileDefaultRoles,
  handleSetEnabled,
  handleProfileRoleSelectedChange,
  ...props
}: EditorDisplayProps) => {
  const handleSaveClick = (e: any) => {
    e.preventDefault();
    props.onSave();
  };
  const [show, setShow] = useState(false);
  const handleInfoClick = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal animation={false} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Disabling a user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            A disabled user will still show on the organization's user list, and
            the organization will keep a record of the user's permissions that
            will be re-applied if they are re-enabled.
          </span>
          <br />
          <span>
            If a disabled user attempts to log in they will not see the
            disabling organization in their list, and they will not get any
            permissions from the disabling organization.
          </span>
        </Modal.Body>
      </Modal>
      <Form>
        <Container>
          <Row>
            <Col xs="auto" className="ps-2 pe-1">
              <h2>{user.displayName !== '' ? user.displayName : user.email}</h2>
            </Col>
            <Col xs="auto" className="p-1">
              <IdentityProviderBadge
                externalUserInfo={
                  user.externalIds?.find(
                    (e) => e.issuer !== EXTERNAL_ID_ISSUER_SGI,
                  ) ?? {}
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>{user.email}</Col>
          </Row>
          <Form.Row className="my-2">
            <Col xs="auto">
              <Form.Check
                type="checkbox"
                data-testid="isEnabledCheckbox"
                label={isEnabled ? 'User is enabled.' : ' User is disabled.'}
                checked={isEnabled}
                onChange={(e) => handleSetEnabled(e.target.checked)}
              />
            </Col>
            <Col xs="auto">
              <FontAwesomeIcon
                onClick={() => handleInfoClick()}
                icon={faInfoCircle}
              />
            </Col>
          </Form.Row>
          <Row>
            <Col>
              <h3>Roles</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <OrganizationUserGroupedRolesEditor
                profileDefaultRoles={profileDefaultRoles}
                onProfileRoleSelectedChange={handleProfileRoleSelectedChange}
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="d-flex justify-content-end">
              <ButtonToolbar className="float-right">
                <Button
                  className="mr-2"
                  variant="outline-secondary"
                  onClick={() => props.handleCancelClick()}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="saveButton"
                  variant="primary"
                  onClick={(e) => {
                    handleSaveClick(e);
                  }}
                  disabled={!props.hasOutstandingChanges}
                >
                  Save
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default EditorDisplay;
