import { msalInstance } from './initializeMsal';

export function isAuthorized(
  permissions: string[],
  requiredPerms: string[],
  requireAll = true,
): Boolean {
  if (!requiredPerms || requiredPerms.length === 0) return true;
  if (!permissions || permissions.length === 0) return false;
  let authorized: Boolean;
  requireAll
    ? (authorized = permissions.every((permission) =>
        requiredPerms.includes(permission),
      ))
    : (authorized = permissions.some((permission) =>
        requiredPerms.includes(permission),
      ));
  return authorized;
}

interface IdTokenClaims {
  permissions?: string[];
}

export function getActiveAccountPermissions() {
  return (msalInstance.getActiveAccount()?.idTokenClaims as IdTokenClaims)
    ?.permissions
    ? (msalInstance.getActiveAccount()?.idTokenClaims as IdTokenClaims)
        ?.permissions
    : [];
}
