import { useCallback, useEffect, useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { IdentityProviderBadge } from '../../common/IdentityProviderBadge';
import { EXTERNAL_ID_ISSUER_SGI } from '../../common/Constants';

const defaultPageSize = 20;

export interface UserTableProps {
  users: UserInfo[];
  pageSize?: number;
}

export function UserTable({
  users,
  pageSize = defaultPageSize,
}: UserTableProps) {
  const [sortField, setSortField] = useState('email');
  const [sortAscending, setSortAscending] = useState(true);
  const [hoveredSortField, setHoveredSortField] = useState('');

  const changeSortDirection = (newSortField: string) =>
    setSortAscending(sortField !== newSortField ? true : !sortAscending);

  // Needed because we want to sort the list and we can't
  // modify the array that's passed in as a prop
  const tableUsers = [...users];

  const displaySortIcon = (field: string) => {
    if (sortField !== field && hoveredSortField === field) {
      return <FontAwesomeIcon icon={faSort} title="Sort" />;
    } else if (sortField === field) {
      return (
        <FontAwesomeIcon
          icon={sortAscending ? faSortUp : faSortDown}
          title="Sort"
        />
      );
    } else {
      return <FontAwesomeIcon icon={faSort} style={{ visibility: 'hidden' }} />;
    }
  };

  const showSortToolTip = (field: string) =>
    sortField !== field && setHoveredSortField(field);
  const hideSortToolTip = () => setHoveredSortField('');

  // ===== Pagination - START =====
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);

  const computeEndIndex = useCallback(
    (currentPage: number, pageSize: number): number => {
      let endIndex = pageSize * currentPage;
      let itemsCount = tableUsers.length;
      return itemsCount < endIndex ? itemsCount : endIndex;
    },
    [tableUsers.length],
  );

  const computeLastPage = useCallback(
    (): number =>
      tableUsers.length === 0 ? 1 : Math.ceil(tableUsers.length / pageSize),
    [tableUsers.length, pageSize],
  );

  const [endIndex, setEndIndex] = useState(
    computeEndIndex(currentPage, pageSize),
  );

  useEffect(() => {
    setStartIndex(
      currentPage === 1 ? 0 : computeEndIndex(currentPage - 1, pageSize),
    );
    setEndIndex(computeEndIndex(currentPage, pageSize));
  }, [computeEndIndex, currentPage, pageSize]);

  useEffect(() => {
    let lastPage = computeLastPage();
    if (currentPage > lastPage) {
      setCurrentPage(lastPage);
    }
  }, [computeLastPage, currentPage]);
  // ===== Pagination - END =====

  return (
    <>
      <Table bordered striped responsive="sm">
        <thead>
          <tr>
            <th colSpan={2} className="fit-width">
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('email');
                  changeSortDirection('email');
                }}
                onMouseEnter={() => showSortToolTip('email')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Email {displaySortIcon('email')}
              </span>
            </th>
            <th>
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('displayName');
                  changeSortDirection('displayName');
                }}
                onMouseEnter={() => showSortToolTip('displayName')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Display Name {displaySortIcon('displayName')}
              </span>
            </th>
            <th>
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('givenName');
                  changeSortDirection('givenName');
                }}
                onMouseEnter={() => showSortToolTip('givenName')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Given Name {displaySortIcon('givenName')}
              </span>
            </th>
            <th>
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('surname');
                  changeSortDirection('surname');
                }}
                onMouseEnter={() => showSortToolTip('surname')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Surname {displaySortIcon('surname')}
              </span>
            </th>
            <th>User Id</th>
          </tr>
        </thead>
        <tbody>
          {tableUsers.length > 0 &&
            tableUsers
              .sort((a: any, b: any) =>
                sortAscending
                  ? a[sortField].localeCompare(b[sortField])
                  : b[sortField].localeCompare(a[sortField]),
              )
              .slice(startIndex, endIndex)
              .map((user) => {
                return (
                  <tr key={user.userId}>
                    <td className="fit-width border-right-0">
                      <Link
                        to={{
                          pathname: `/User/${user.userId}`,
                        }}
                      >
                        {user.email}
                      </Link>
                    </td>
                    <td className="fit-width border-left-0 pl-0">
                      <IdentityProviderBadge
                        externalUserInfo={
                          user?.externalIds?.find(
                            (e) => e.issuer !== EXTERNAL_ID_ISSUER_SGI,
                          ) ?? {}
                        }
                      />
                    </td>
                    <td>{user.displayName}</td>
                    <td>{user.givenName}</td>
                    <td>{user.surname}</td>
                    <td>{user.userId}</td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
      {tableUsers && tableUsers.length > 0 && computeLastPage() > 1 && (
        <Pagination className="mb-2 float-right">
          <Pagination.Item
            title="First page"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(1)}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} title="First page" />
          </Pagination.Item>
          <Pagination.Item
            title="Previous page"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            {<FontAwesomeIcon icon={faAngleLeft} title="Previous page" />}
          </Pagination.Item>
          <Pagination.Item title="Current page" active>
            {currentPage} of {computeLastPage()}
          </Pagination.Item>
          <Pagination.Item
            title="Next page"
            disabled={endIndex === tableUsers.length}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            {<FontAwesomeIcon icon={faAngleRight} title="Next page" />}
          </Pagination.Item>
          <Pagination.Item
            title="Last page"
            disabled={endIndex === tableUsers.length}
            onClick={() => setCurrentPage(computeLastPage())}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} title="Last page" />
          </Pagination.Item>
        </Pagination>
      )}
    </>
  );
}

export default UserTable;
