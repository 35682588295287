import { Button, Modal } from 'react-bootstrap';

interface ConfirmProps {
  show?: boolean;
  title?: string;
  body?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  danger?: boolean;
  bodyElement?: () => JSX.Element;
  onConfirm: () => void;
  onCancel?: () => void;
  onHide?: () => void;
}

export const Confirm = ({
  show = false,
  title,
  body,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  danger = false,
  bodyElement,
  onConfirm,
  onCancel,
  onHide,
}: ConfirmProps) => {
  return (
    <Modal show={show} backdrop="static" animation={false}>
      <Modal.Header closeButton onHide={onHide ?? onCancel}>
        <Modal.Title>{title ? title : 'Confirm'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body ?? (bodyElement && bodyElement())}</Modal.Body>
      <Modal.Footer>
        <Button className="mr-2" variant="outline-secondary" onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button variant={danger ? 'danger' : 'primary'} onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default Confirm;
