import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { OrganizationProfileSummaryInfo } from '../../../types/AuthorizationBFFGeneratedTypes';

interface OrganizationProfileSummaryProps {
  shouldLinkToProfiles: boolean;
  profiles: OrganizationProfileSummaryInfo[];
}

export const OrganizationProfileSummary: React.FC<OrganizationProfileSummaryProps> =
  ({ shouldLinkToProfiles, profiles }) => {
    const [sortField, setSortField] = useState('name');
    const [sortAscending, setSortAscending] = useState(true);
    const [hoveredSortField, setHoveredSortField] = useState('');

    const profilesTableData = [...profiles];

    const changeSortDirection = (newSortField: string) =>
      setSortAscending(sortField !== newSortField ? true : !sortAscending);

    const displaySortIcon = (field: string) => {
      if (sortField !== field && hoveredSortField === field) {
        return <FontAwesomeIcon icon={faSort} title="Sort" />;
      } else if (sortField === field) {
        return (
          <FontAwesomeIcon
            icon={sortAscending ? faSortUp : faSortDown}
            title="Sort"
          />
        );
      } else {
        return (
          <FontAwesomeIcon icon={faSort} style={{ visibility: 'hidden' }} />
        );
      }
    };

    const showSortToolTip = (field: string) =>
      sortField !== field && setHoveredSortField(field);

    const hideSortToolTip = () => setHoveredSortField('');

    return (
      <Table bordered striped responsive="sm">
        <thead>
          <tr>
            <th>
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('name');
                  changeSortDirection('name');
                }}
                onMouseEnter={() => showSortToolTip('name')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Profile Name {displaySortIcon('name')}
              </span>
            </th>
            <th>
              <span
                role="button"
                className="text-nowrap"
                onClick={() => {
                  setSortField('description');
                  changeSortDirection('description');
                }}
                onMouseEnter={() => showSortToolTip('description')}
                onMouseLeave={() => hideSortToolTip()}
              >
                Description {displaySortIcon('description')}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {profilesTableData.length > 0 &&
            profilesTableData
              .sort((a: any, b: any) =>
                sortAscending
                  ? (a[sortField] ?? '').localeCompare(b[sortField] ?? '')
                  : (b[sortField] ?? '').localeCompare(a[sortField] ?? ''),
              )
              .map((profile) => {
                return (
                  <tr key={profile.profileId}>
                    <td>
                      {shouldLinkToProfiles ? (
                        <Link
                          to={{
                            pathname: `/Profile/${profile.profileId}`,
                          }}
                        >
                          {profile.name}
                        </Link>
                      ) : (
                        profile.name
                      )}
                    </td>
                    <td>{profile.description}</td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
    );
  };

export default OrganizationProfileSummary;
