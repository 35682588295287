import { OrganizationProfileSummaryInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import {
  ProfileDefaultRoleKey,
  ProfileDefaultRoleSelectionSummary,
  ProfileDefaultRoleSummary,
} from './editor-types';

export const augmentRoleSelection = (
  availableProfileDefaultRoles: ProfileDefaultRoleSummary[],
  selectedProfileDefaultRoles: ProfileDefaultRoleKey[],
  organizationProfiles: OrganizationProfileSummaryInfo[],
): ProfileDefaultRoleSelectionSummary[] => {
  return availableProfileDefaultRoles.map((x) => {
    // Add an 'isSelected' indicator to each of the availableProfileDefaultRoles
    const isSelected = selectedProfileDefaultRoles.some(
      (d) =>
        d.profileId === x.profileId &&
        d.profileDefaultRoleId === x.profileDefaultRoleId,
    );

    // Add profileName for display grouping
    const profileName =
      organizationProfiles.find((p) => p.profileId === x.profileId)?.name ?? '';

    return { ...x, isSelected, profileName };
  });
};
