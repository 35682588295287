import {
  ApplicationMessageSeverityType,
  ApplicationProblemDetails,
} from '../../types/AuthorizationBFFGeneratedTypes';
import {
  addError,
  addInfo,
  addWarning,
  addSuccess,
  CreateNotificationModel,
} from '../notifications/notificationSlice';
import { store } from '../store';

export const apiMessageNotification = (error: ApplicationProblemDetails) => {
  error.messages
    ? error.messages?.forEach((msg) => {
        const notification: CreateNotificationModel = {
          header: error.title as string,
          message: msg.message,
        };
        switch (msg.severity) {
          case ApplicationMessageSeverityType.Success:
            store.dispatch(addSuccess(notification));
            break;
          case ApplicationMessageSeverityType.Information:
            store.dispatch(addInfo(notification));
            break;
          case ApplicationMessageSeverityType.Warning:
            store.dispatch(addWarning(notification));
            break;
          case ApplicationMessageSeverityType.Error:
            store.dispatch(addError(notification));
            break;
        }
      })
    : store.dispatch(addError({ header: error.title as string }));
};
