import {
  OrganizationProfileSummaryInfo,
  ProfileDefaultRoleInfo,
} from '../../../types/AuthorizationBFFGeneratedTypes';
import { ProfileDefaultRoleSelectionSummary } from '../helpers/editor-types';
import {
  groupRolesByProfile,
  OrganizationUserGroupedRolesSelectionCard,
} from './OrganizationUserGroupedRolesSelectionCard';

interface OrganizationUserGroupedRolesViewerProps {
  profileDefaultRoles: ProfileDefaultRoleInfo[];
  organizationProfiles: OrganizationProfileSummaryInfo[];
}

export const OrganizationUserGroupedRolesViewer = ({
  profileDefaultRoles,
  organizationProfiles,
}: OrganizationUserGroupedRolesViewerProps) => {
  const profileRolesSelection = groupRolesByProfile(
    profileDefaultRoles
      .filter((role) =>
        organizationProfiles.some((p) => p.profileId === role.profileId),
      )
      .map<ProfileDefaultRoleSelectionSummary>((role) => ({
        profileId: role.profileId,
        profileName:
          organizationProfiles.find((p) => p.profileId === role.profileId)
            ?.name ?? '',
        profileDefaultRoleId: role.profileDefaultRoleId,
        name: role.name,
        description: role.description,
        isSelected: true,
        isPrivilegedRole: role?.isPrivilegedRole,
      })),
  );
  return (
    <>
      {profileRolesSelection.map((selection) => (
        <OrganizationUserGroupedRolesSelectionCard
          key={selection.profileId}
          profileRoleSelection={selection}
        />
      ))}
    </>
  );
};

export default OrganizationUserGroupedRolesViewer;
