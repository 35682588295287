import { useEffect } from 'react';
import { useParams } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { useGetApplicationByIdQuery } from '../../api/authorizationuiApi';
import ApplicationDetailsInfo from './components/ApplicationDetailsInfo';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import { Breadcrumb } from 'react-bootstrap';
import { ApplicationProblemDetails } from '../../types/AuthorizationBFFGeneratedTypes';
import { apiMessageNotification } from '../redux/apiMessageNotification';

interface ParamTypes {
  applicationManifestId: string;
}

const Application = () => {
  const { applicationManifestId } = useParams<ParamTypes>();

  const {
    data: app,
    isLoading: isLoadingApp,
    isError: isErrorApp,
    error: errorApp,
  } = useGetApplicationByIdQuery(applicationManifestId);

  const isAnyApiLoading = isLoadingApp;

  useEffect(() => {
    if (isErrorApp) {
      apiMessageNotification(errorApp as ApplicationProblemDetails);
    }
  }, [isErrorApp, errorApp]);

  return (
    <>
      <Breadcrumb role="breadcrumb">
        <LinkContainer to="/Applications">
          <Breadcrumb.Item>Applications</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{app?.applicationName}</Breadcrumb.Item>
      </Breadcrumb>
      {isAnyApiLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ApplicationDetailsInfo appInfo={app ?? {}} />
        </>
      )}
    </>
  );
};

export default Application;
