import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersSlash, faUsers } from '@fortawesome/free-solid-svg-icons';

interface OrganizationStatusBadgeProps {
  isEnabled: boolean;
}

export const OrganizationStatusBadge = ({
  isEnabled,
}: OrganizationStatusBadgeProps) => {
  if (isEnabled) {
    return (
      <FontAwesomeIcon
        icon={faUsers}
        color="green"
        title="Organization is Enabled"
      />
    );
  } else {
    return (
      <FontAwesomeIcon
        icon={faUsersSlash}
        color="red"
        title="Organization is Disabled"
      />
    );
  }
};

export default OrganizationStatusBadge;
