import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Button, ButtonToolbar, Form } from 'react-bootstrap';
import { UserQuery } from '../../../types/AuthorizationBFFGeneratedTypes';
import { useAppDispatch } from '../../hooks';
import {
  addWarning,
  CreateNotificationModel,
} from '../../notifications/notificationSlice';
import { useHistory } from 'react-router';
import qs from 'qs';

interface UserSearchProps {
  onSearch: (queryParameters: UserQuery) => void;
}

export const isGuidValid = (value: string) => {
  const validGuid =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  if (value.toString() === '') {
    return false;
  } else {
    return !validGuid.test(value);
  }
};

export const UserSearch = ({ onSearch }: UserSearchProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [autoRunFilter, setAutoRunFilter] = useState(false);
  const [userQuery, setUserQuery] = useState({} as UserQuery);

  useEffect(() => {
    const filterParams = history.location.search.substring(1);
    const filtersFromParams = qs.parse(filterParams);
    if (
      filtersFromParams.b2cId?.toString() ||
      filtersFromParams.userId?.toString() ||
      filtersFromParams.email?.toString() ||
      filtersFromParams.displayName?.toString() ||
      filtersFromParams.surname?.toString() ||
      filtersFromParams.givenName?.toString()
    ) {
      setUserQuery(filtersFromParams);
      setAutoRunFilter(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (autoRunFilter === true) {
      setAutoRunFilter(false);
      performQuerySearch();
    }
    // eslint-disable-next-line
  }, [userQuery]);

  const handleQueryUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    let inputField = event.target.name;
    let inputValue = event.target.value;
    setUserQuery({ ...userQuery, [inputField]: inputValue });
  };

  const handleQuerySearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    performQuerySearch();
  };

  const performQuerySearch = async () => {
    if (
      Object.keys(userQuery).length === 0 ||
      ((userQuery.b2cId == null || userQuery.b2cId === '') &&
        (userQuery.userId == null || userQuery.userId === '') &&
        (userQuery.email == null || userQuery.email === '') &&
        (userQuery.displayName == null || userQuery.displayName === '') &&
        (userQuery.surname == null || userQuery.surname === '') &&
        (userQuery.givenName == null || userQuery.givenName === ''))
    ) {
      const notification: CreateNotificationModel = {
        header: 'User Search',
        message: `Need at least one criteria for a search.`,
      };
      dispatch(addWarning(notification));
    } else if (
      isGuidValid(userQuery.b2cId ?? '') ||
      isGuidValid(userQuery.userId ?? '')
    ) {
      const notification: CreateNotificationModel = {
        header: 'User Search',
        message: `Please enter a valid GUID.`,
      };
      dispatch(addWarning(notification));
    } else {
      let queryParameters = {
        b2cId: (userQuery.b2cId ?? '').toString(),
        userId: (userQuery.userId ?? '').toString(),
        email: (userQuery.email ?? '').toString(),
        displayName: (userQuery.displayName ?? '').toString(),
        surname: (userQuery.surname ?? '').toString(),
        givenName: (userQuery.givenName ?? '').toString(),
      } as UserQuery;
      onSearch(queryParameters);
    }
  };

  const handleClearQuery = () => {
    setUserQuery({} as UserQuery);
  };

  return (
    <Form noValidate onSubmit={handleQuerySearch} onReset={handleClearQuery}>
      <Form.Row className="my-4">
        <Col>
          <Form.Control
            autoFocus
            type="text"
            maxLength={100}
            placeholder="Email"
            value={userQuery.email ?? ''}
            name="email"
            onChange={handleQueryUpdate}
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            maxLength={100}
            placeholder="Display Name"
            value={userQuery.displayName ?? ''}
            name="displayName"
            onChange={handleQueryUpdate}
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            maxLength={100}
            placeholder="Given Name"
            value={userQuery.givenName ?? ''}
            name="givenName"
            onChange={handleQueryUpdate}
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            maxLength={100}
            placeholder="Surname"
            value={userQuery.surname ?? ''}
            name="surname"
            onChange={handleQueryUpdate}
          />
        </Col>
      </Form.Row>
      <Form.Row className="mb-4">
        <Col>
          <Form.Control
            type="text"
            maxLength={36}
            placeholder="User Id"
            value={userQuery.userId ?? ''}
            name="userId"
            onChange={handleQueryUpdate}
            isInvalid={isGuidValid(userQuery.userId ?? '')}
          />
          <Form.Control.Feedback type="invalid">
            Please enter valid Guid.
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Control
            type="text"
            maxLength={36}
            placeholder="B2C Id"
            value={userQuery.b2cId ?? ''}
            name="b2cId"
            onChange={handleQueryUpdate}
            isInvalid={isGuidValid(userQuery.b2cId ?? '')}
          />
          <Form.Control.Feedback type="invalid">
            Please enter valid Guid.
          </Form.Control.Feedback>
        </Col>
        <Col></Col>
        <Col>
          <ButtonToolbar className="float-right">
            <Button
              className="my-1 mr-2"
              variant="outline-secondary"
              type="reset"
            >
              Clear
            </Button>
            <Button className="my-1" variant="primary" type="submit">
              Search
            </Button>
          </ButtonToolbar>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default UserSearch;
