import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ApplicationInfo,
  CalculateClaimsRequest,
  ExternalUserEditInfo,
  OrganizationCreateInfo,
  OrganizationDetailInfo,
  OrganizationProfileSummaryInfo,
  OrganizationSummaryInfo,
  OrganizationUpdateInfo,
  OrganizationUserDetailsInfo,
  OrganizationUserEditInfo,
  ProfileDetailsInfo,
  ProfileSummaryInfo,
  UserClaimsInfo,
  UserInfo,
  UserOrganizationSummaryInfo,
  UserQuery,
} from '../types/AuthorizationBFFGeneratedTypes';
import { axiosBaseQuery } from './axiosBaseQuery';
import RtkTagTypes from './rtkTags';

export const authorizationuiApi = createApi({
  reducerPath: 'authorizationuiApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    RtkTagTypes.ORGANIZATION,
    RtkTagTypes.ORGANIZATIONLIST,
    RtkTagTypes.USER,
    RtkTagTypes.PROFILE,
    RtkTagTypes.PROFILELIST,
    RtkTagTypes.APPLICATION,
  ],
  endpoints: (builder) => ({
    getAllOrganizations: builder.query<OrganizationSummaryInfo[], void>({
      query: () => ({ url: 'organization', method: 'GET' }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ organizationId }) =>
                  ({
                    type: RtkTagTypes.ORGANIZATION,
                    id: organizationId,
                  } as const),
              ),
              { type: RtkTagTypes.ORGANIZATIONLIST, id: 'LIST' },
            ]
          : [],
    }),

    getMyOrganizations: builder.query<OrganizationSummaryInfo[], void>({
      query: () => ({ url: 'organization/my', method: 'GET' }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ organizationId }) =>
                  ({
                    type: RtkTagTypes.ORGANIZATION,
                    id: organizationId,
                  } as const),
              ),
              { type: RtkTagTypes.ORGANIZATIONLIST, id: 'LIST' },
            ]
          : [],
    }),

    getOrganization: builder.query<OrganizationDetailInfo, string>({
      query: (organizationId: string) => ({
        url: `organization/${organizationId}`,
        method: 'GET',
      }),
      providesTags: (result) => {
        const tags: {
          type: RtkTagTypes.ORGANIZATION | RtkTagTypes.USER;
          id: string;
        }[] = [];
        result &&
          result.organizationId &&
          tags.push({
            type: RtkTagTypes.ORGANIZATION,
            id: result.organizationId,
          });
        result?.organizationUsers &&
          tags.push(
            ...result.organizationUsers.map(
              (orgUser) =>
                ({
                  type: RtkTagTypes.USER,
                  id: `${orgUser.userId}`,
                } as const),
            ),
          );
        return [...tags];
      },
    }),

    addOrganization: builder.mutation<
      OrganizationDetailInfo,
      OrganizationCreateInfo
    >({
      query: (newOrg: OrganizationCreateInfo) => ({
        url: 'organization',
        method: 'POST',
        data: newOrg,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RtkTagTypes.ORGANIZATIONLIST, id: 'LIST' }] : [],
    }),

    deleteOrganization: builder.mutation<void, string>({
      query: (organizationId: string) => ({
        url: `organization/${organizationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, organizationId) => [
        { type: RtkTagTypes.ORGANIZATION, id: organizationId },
      ],
    }),

    updateOrganization: builder.mutation<
      OrganizationDetailInfo,
      { organizationId: string; updateInfo: OrganizationUpdateInfo }
    >({
      query: ({ organizationId, updateInfo }) => ({
        url: `organization/${organizationId}`,
        method: 'PUT',
        data: updateInfo,
      }),
      invalidatesTags: (result) => [
        { type: RtkTagTypes.ORGANIZATION, id: result?.organizationId },
      ],
    }),

    getOrganizationUser: builder.query<
      OrganizationUserDetailsInfo,
      { organizationId: string; userId: string }
    >({
      query: ({ organizationId, userId }) => ({
        url: `organization/${organizationId}/users/${userId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { organizationId, userId }) =>
        result
          ? [
              {
                type: RtkTagTypes.USER,
                id: userId,
              } as const,
            ]
          : [],
    }),

    addOrganizationUser: builder.mutation<
      OrganizationUserDetailsInfo,
      { organizationId: string; email: string }
    >({
      query: ({ organizationId, email }) => ({
        url: `organization/${organizationId}/users`,
        method: 'POST',
        data: { email: email },
      }),
      invalidatesTags: (result, error, { organizationId, email }) =>
        result
          ? [
              {
                type: RtkTagTypes.USER,
                id: result?.userInfo?.userId,
              },
              {
                type: RtkTagTypes.ORGANIZATION,
                id: organizationId,
              },
            ]
          : [],
    }),

    removeOrganizationUser: builder.mutation<
      void,
      { organizationId: string; userId: string }
    >({
      query: ({ organizationId, userId }) => ({
        url: `organization/${organizationId}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { organizationId, userId }) => [
        {
          type: RtkTagTypes.USER,
          id: userId,
        },
        {
          type: RtkTagTypes.ORGANIZATION,
          id: organizationId,
        },
      ],
    }),

    updateOrganizationUser: builder.mutation<
      OrganizationUserDetailsInfo,
      {
        organizationId: string;
        userId: string;
        editInfo: OrganizationUserEditInfo;
      }
    >({
      query: ({ organizationId, userId, editInfo }) => ({
        url: `organization/${organizationId}/users/${userId}`,
        method: 'PUT',
        data: editInfo,
      }),
      invalidatesTags: (result, error, { organizationId, userId, editInfo }) =>
        result
          ? [
              {
                type: RtkTagTypes.USER,
                id: result?.userInfo?.userId,
              },
              {
                type: RtkTagTypes.ORGANIZATION,
                id: organizationId,
              },
            ]
          : [
              {
                type: RtkTagTypes.USER,
                id: userId,
              },
              {
                type: RtkTagTypes.ORGANIZATION,
                id: organizationId,
              },
            ],
    }),

    getUser: builder.query<UserInfo, { userId: string }>({
      query: ({ userId }) => ({ url: `user/${userId}`, method: 'GET' }),
      providesTags: (result) =>
        result
          ? // Generate a tag for the user
            [{ type: RtkTagTypes.USER, id: result.userId }]
          : // An error occured but we don't want to generate a tag for this user id
            [],
    }),

    getAllUserOrganizations: builder.query<
      UserOrganizationSummaryInfo[],
      string
    >({
      query: (userId: string) => ({
        url: `user/${userId}/organizations`,
        method: 'GET',
      }),
      providesTags: (result, error, userId) =>
        result
          ? [
              ...result.map(
                (org) =>
                  ({
                    type: RtkTagTypes.ORGANIZATION,
                    id: org.organizationId,
                  } as const),
              ),
              { type: RtkTagTypes.USER, id: userId },
            ]
          : [],
    }),

    addUser: builder.mutation<UserInfo, { email: string }>({
      query: ({ email }) => ({ url: `user/${email}`, method: 'POST' }),
      invalidatesTags: (result) =>
        result
          ? // Invalidates the tag for this user (if it already exists) and the list of all users
            [{ type: RtkTagTypes.USER, id: result.userId }]
          : // An error occured but we don't want to invalidates any tags
            [],
    }),

    deleteUser: builder.mutation<void, string>({
      query: (userId: string) => ({ url: `user/${userId}`, method: 'DELETE' }),
      invalidatesTags: (result, error, userId) => [
        { type: RtkTagTypes.USER, id: userId },
      ],
    }),

    updateUserIdentifier: builder.mutation<
      void,
      { userId: string; externalIdentity: ExternalUserEditInfo }
    >({
      query: ({ userId, externalIdentity }) => ({
        url: `user/${userId}/updateidentifier`,
        method: 'PUT',
        data: externalIdentity,
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: RtkTagTypes.USER, id: userId },
      ],
    }),

    deleteExternalIdentity: builder.mutation<
      void,
      { userId: string; externalIdentity: ExternalUserEditInfo }
    >({
      query: ({ userId, externalIdentity }) => ({
        url: `user/${userId}/deleteexternalidentity`,
        method: 'PUT',
        data: externalIdentity,
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: RtkTagTypes.USER, id: userId },
      ],
    }),

    queryUsers: builder.mutation<UserInfo[], UserQuery>({
      query: (userInfo) => ({
        url: `user/query`,
        method: 'POST',
        data: userInfo,
      }),
    }),

    calculateClaims: builder.mutation<UserClaimsInfo, CalculateClaimsRequest>({
      query: (request) => ({
        url: `user/${request.userId}/calculate`,
        method: 'POST',
        data: request,
      }),
    }),

    getProfileSummaries: builder.query<ProfileSummaryInfo[], void>({
      query: () => ({ url: 'profile', method: 'GET' }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ profileId }) =>
                  ({ type: RtkTagTypes.PROFILE, profileId } as const),
              ),
            ]
          : [],
    }),

    getProfile: builder.query<ProfileDetailsInfo, { profileId: string }>({
      query: ({ profileId }) => ({
        url: `profile/${profileId}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: RtkTagTypes.PROFILE, id: result.profileId }] : [],
    }),

    getOrganizationProfileSummaries: builder.query<
      OrganizationProfileSummaryInfo[],
      void
    >({
      query: () => ({
        url: `organizationprofilesummary`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: RtkTagTypes.PROFILELIST, id: 'LIST' }] : [],
    }),

    getAllApplications: builder.query<ApplicationInfo[], void>({
      query: () => ({ url: 'application', method: 'GET' }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ applicationManifestId }) =>
                  ({
                    type: RtkTagTypes.APPLICATION,
                    id: applicationManifestId,
                  } as const),
              ),
            ]
          : [],
    }),

    getApplicationById: builder.query<ApplicationInfo, string>({
      query: (applicationId: string) => ({
        url: `application/${applicationId}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: RtkTagTypes.APPLICATION,
                id: result.applicationManifestId,
              },
            ]
          : [],
    }),

    deleteApplication: builder.mutation<void, string>({
      query: (applicationManifestId: string) => ({
        url: `application/${applicationManifestId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, applicationManifestId) => [
        { type: RtkTagTypes.APPLICATION, id: applicationManifestId },
      ],
    }),
  }),
});

export const {
  useGetAllOrganizationsQuery,
  useGetMyOrganizationsQuery,
  useGetOrganizationQuery,
  useAddOrganizationMutation,
  useDeleteOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetOrganizationUserQuery,
  useAddOrganizationUserMutation,
  useRemoveOrganizationUserMutation,
  useUpdateOrganizationUserMutation,
  useGetUserQuery,
  useGetAllUserOrganizationsQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserIdentifierMutation,
  useDeleteExternalIdentityMutation,
  useQueryUsersMutation,
  useCalculateClaimsMutation,
  useGetProfileSummariesQuery,
  useGetProfileQuery,
  useGetOrganizationProfileSummariesQuery,
  useGetAllApplicationsQuery,
  useGetApplicationByIdQuery,
  useDeleteApplicationMutation,
} = authorizationuiApi;
