import { useContext } from 'react';
import { EnvironmentVariableContext } from '../../store/EnvironmentVariableContext';
import './EnvironmentWatermark.css';

const EnvironmentWatermark = () => {
  const environmentContext = useContext(EnvironmentVariableContext);
  const environment: string = environmentContext.REACT_APP_ENVIRONMENT;

  return environment === 'PRD' ? (
    <></>
  ) : (
    <div className="environment-watermark">{environment}</div>
  );
};

export default EnvironmentWatermark;
