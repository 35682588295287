import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../store';

export enum NotificationType {
  'INFO',
  'WARNING',
  'SUCCESS',
  'ERROR',
}

export interface CreateNotificationModel {
  header: string;
  message?: string;
}

interface Notification {
  id: string;
  header: string;
  message?: string;
  type: NotificationType;
}

export interface NotificationState {
  notifications: Notification[];
}

const initialState: NotificationState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    addError: (
      state: NotificationState,
      action: PayloadAction<CreateNotificationModel>,
    ) => {
      state.notifications.push({
        ...action.payload,
        id: uuidv4(),
        type: NotificationType.ERROR,
      });
    },
    addInfo: (
      state: NotificationState,
      action: PayloadAction<CreateNotificationModel>,
    ) => {
      state.notifications.push({
        ...action.payload,
        id: uuidv4(),
        type: NotificationType.INFO,
      });
    },
    addWarning: (
      state: NotificationState,
      action: PayloadAction<CreateNotificationModel>,
    ) => {
      state.notifications.push({
        ...action.payload,
        id: uuidv4(),
        type: NotificationType.WARNING,
      });
    },
    addSuccess: (
      state: NotificationState,
      action: PayloadAction<CreateNotificationModel>,
    ) => {
      state.notifications.push({
        ...action.payload,
        id: uuidv4(),
        type: NotificationType.SUCCESS,
      });
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notificiation) => notificiation.id !== action.payload,
      );
    },
  },
});
export const { addError, addInfo, addWarning, addSuccess, removeNotification } =
  notificationSlice.actions;

// Define Selectors
export const selectNotifications = (state: RootState): Notification[] => {
  return state.notifications.notifications;
};
export default notificationSlice.reducer;
