import { ProfileDefaultRoleSelectionSummary } from '../helpers/editor-types';
import {
  groupRolesByProfile,
  OrganizationUserGroupedRolesSelectionCard,
} from './OrganizationUserGroupedRolesSelectionCard';

interface OrganizationUserGroupedRolesEditorProps {
  profileDefaultRoles: ProfileDefaultRoleSelectionSummary[];
  onProfileRoleSelectedChange: (
    profileId: string,
    profileDefaultRoleId: string,
    isSelected: boolean,
  ) => void;
}

export const OrganizationUserGroupedRolesEditor = ({
  profileDefaultRoles,
  onProfileRoleSelectedChange,
}: OrganizationUserGroupedRolesEditorProps) => {
  const profileRolesSelection = groupRolesByProfile(profileDefaultRoles);
  return (
    <>
      {!profileRolesSelection || profileRolesSelection.length === 0
        ? 'No assignable roles.'
        : profileRolesSelection.map((selection) => (
            <OrganizationUserGroupedRolesSelectionCard
              key={selection.profileId}
              profileRoleSelection={selection}
              selectable
              onProfileRoleSelectedChange={onProfileRoleSelectedChange}
            />
          ))}
    </>
  );
};

export default OrganizationUserGroupedRolesEditor;
