import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useDeleteApplicationMutation } from '../../../api/authorizationuiApi';
import { ApplicationProblemDetails } from '../../../types/AuthorizationBFFGeneratedTypes';
import { Confirm } from '../../common/Confirm';
import { useAppDispatch } from '../../hooks';
import {
  addSuccess,
  CreateNotificationModel,
} from '../../notifications/notificationSlice';
import { apiMessageNotification } from '../../redux/apiMessageNotification';

interface ApplicationInfo {
  applicationManifestId?: string;
  applicationName?: string;
}

export interface DeleteApplicationProps {
  appInfo: ApplicationInfo;
}

const DeleteApplicationButton: FC<DeleteApplicationProps> = ({ appInfo }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleConfirm = () => {
    if (appInfo.applicationManifestId) {
      deleteApplication(appInfo.applicationManifestId);
    }
  };

  const handleConfirmCancel = () => {
    setShowDeleteConfirm(false);
  };

  const [
    deleteApplication,
    {
      isError: isErrorDeleteApp,
      error: errorDeleteApp,
      isSuccess: isDeleteAppSuccess,
    },
  ] = useDeleteApplicationMutation();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  // On error, display returned errors & stay open.
  useEffect(() => {
    if (isErrorDeleteApp) {
      apiMessageNotification(errorDeleteApp as ApplicationProblemDetails);
    }
  }, [isErrorDeleteApp, errorDeleteApp]);

  // On success, display notice & navigate back to application's list.
  useEffect(() => {
    if (isDeleteAppSuccess) {
      const notification: CreateNotificationModel = {
        header: 'Delete Application',
        message: `${appInfo.applicationName} deleted.`,
      };
      dispatch(addSuccess(notification));
      history.push(`/Applications`);
    }
  }, [isDeleteAppSuccess, appInfo, dispatch, history]);

  const confirmDeleteApplicationModalBody = () => {
    return (
      <>
        <Container>
          <Row>
            <Col>
              This will permanently delete the application in the authorization
              system.
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="my-2">
                <li>
                  <span className="font-weight-bold">
                    {appInfo.applicationName}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Card border="warning" body className="my-2 py-0">
          <Container>
            <Row>
              <Col xs="auto" className="text-warning">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="2x"
                  title="Warning"
                  name="Warning"
                />
              </Col>
              <Col>
                <p className="mb-0">
                  Users of this application may still be able to sign-in but
                  they will lose all currently assigned roles in the
                  application.
                </p>
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  };

  return (
    <>
      {showDeleteConfirm && (
        <Confirm
          danger
          title="Delete Application?"
          show={showDeleteConfirm}
          bodyElement={confirmDeleteApplicationModalBody}
          confirmLabel="Delete Application"
          onConfirm={handleConfirm}
          onCancel={handleConfirmCancel}
        />
      )}
      <Button
        className="my-1"
        variant="outline-danger"
        onClick={handleDeleteClick}
      >
        Delete Application
      </Button>
    </>
  );
};

export default DeleteApplicationButton;
