import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import {
  useAddOrganizationUserMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationQuery,
} from '../../api/authorizationuiApi';
import { useGetFeatureFlagsQuery } from '../../api/featureFlagApi';
import {
  ApplicationMessageSeverityType,
  ApplicationProblemDetails,
  OrganizationType,
  OrganizationUserSummaryInfo,
} from '../../types/AuthorizationBFFGeneratedTypes';
import Confirm from '../common/Confirm';
import DynamicOrgBreadcrumb from '../common/DynamicOrgBreadcrumb';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import LoadingSpinnerModal from '../common/loadingSpinner/LoadingSpinnerModal';
import { OrganizationStatusBadge } from '../common/OrganizationStatusBadge';
import { useAppDispatch } from '../hooks';
import {
  addSuccess,
  CreateNotificationModel,
} from '../notifications/notificationSlice';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import AddOrganizationUserModal from './components/AddOrganizationUserModal';
import OrganizationProfileSummary from './components/OrganizationProfileSummary';
import { OrganizationRoleSummary } from './components/OrganizationRoleSummary';
import {
  OrganizationUserSearchForm,
  OrganizationUserSearchQuery,
} from './components/OrganizationUserSearchForm';
import OrganizationUserSummaryTable from './components/OrganizationUserSummaryTable';
import qs from 'qs';

interface ParamTypes {
  organizationId: string;
}

export const Organization = () => {
  const history = useHistory();
  const { organizationId } = useParams<ParamTypes>();
  const dispatch = useAppDispatch();

  const { data: featureFlags, isLoading: isGetFeatureFlagsLoading } =
    useGetFeatureFlagsQuery();

  const {
    canListProfiles,
    canListOrganizations,
    isAutoEnrolledEmailDomainsFeatureEnabled,
  } = featureFlags ?? {};

  const [skipGetOrganizationQuery, setSkipGetOrganizationQuery] =
    useState(false);
  const {
    data: organizationDetails,
    isError: isGetOrganizationError,
    error: getOrganizationQueryError,
    isLoading: isGetOrganizationLoading,
    isFetching: isGetOrganizationFetching,
  } = useGetOrganizationQuery(organizationId, {
    skip: skipGetOrganizationQuery,
  });

  const [
    addOrganizationUser,
    {
      data: newUser,
      isError: isAddOrganizationUserError,
      error: addOrganizationUserError,
      isSuccess: isAddOrganizationUserSuccess,
      isLoading: isAddOrganizationUserLoading,
    },
  ] = useAddOrganizationUserMutation();

  const [
    deleteOrganzation,
    {
      isError: isDeleteOrganizationError,
      error: deleteOrganizationError,
      isSuccess: isDeleteOrganizationSuccess,
      isLoading: isDeleteOrganizationLoading,
    },
  ] = useDeleteOrganizationMutation();

  const [showAdd, setShowAdd] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [filteredUserList, setFilteredUserList] = useState(
    [] as OrganizationUserSummaryInfo[],
  );
  let filteredList = [] as OrganizationUserSummaryInfo[];

  const [isSearchAttemped, setIsSearchAttempted] = useState(false);

  const handleOrganizationUserAdd = (email: string) => {
    let orgUser = organizationDetails?.organizationUsers?.find(
      (user) => user.emailAddress?.toLowerCase() === email.toLowerCase(),
    );
    if (orgUser) {
      const notification: ApplicationProblemDetails = {
        title: 'Add Organization User',
        messages: [
          {
            severity: ApplicationMessageSeverityType.Information,
            message: `User ${email} is already in ${organizationDetails?.name}.`,
          },
        ],
      };
      apiMessageNotification(notification);
      setShowAdd(false);
      history.push(`/Organization/${organizationId}/User/${orgUser.userId}`);
    } else {
      addOrganizationUser({ organizationId, email: email });
    }
  };

  const handleOrganizationUserAddCancel = () => {
    setShowAdd(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  const handleConfirm = () => {
    setSkipGetOrganizationQuery(true);
    deleteOrganzation(organizationId);
  };

  const handleConfirmCancel = () => {
    setShowDeleteConfirm(false);
  };

  useEffect(() => {
    if (isGetOrganizationError) {
      apiMessageNotification(
        getOrganizationQueryError as ApplicationProblemDetails,
      );
    }
    if (isAddOrganizationUserError) {
      apiMessageNotification(
        addOrganizationUserError as ApplicationProblemDetails,
      );
      setShowAdd(true);
    }
    if (isDeleteOrganizationError) {
      apiMessageNotification(
        deleteOrganizationError as ApplicationProblemDetails,
      );
    }
  }, [
    isGetOrganizationError,
    getOrganizationQueryError,
    isAddOrganizationUserError,
    addOrganizationUserError,
    isDeleteOrganizationError,
    deleteOrganizationError,
  ]);

  useEffect(() => {
    if (isAddOrganizationUserSuccess) {
      setShowAdd(false);
      if (newUser?.isNewUser === true) {
        const notification: ApplicationProblemDetails = {
          title: 'Add Organization User',
          messages: [
            {
              severity: ApplicationMessageSeverityType.Success,
              message: `New user ${newUser?.userInfo?.email} created`,
            },
          ],
        };
        apiMessageNotification(notification);
      }
      const notification: ApplicationProblemDetails = {
        title: 'Add Organization User',
        messages: [
          {
            severity: ApplicationMessageSeverityType.Success,
            message: `User ${newUser?.userInfo?.email} added to ${organizationDetails?.name}.`,
          },
        ],
      };
      apiMessageNotification(notification);
      history.push(
        `/Organization/${organizationId}/User/${newUser?.userInfo?.userId}`,
      );
    }
  }, [
    dispatch,
    isAddOrganizationUserSuccess,
    organizationDetails?.name,
    newUser?.isNewUser,
    newUser?.userInfo?.email,
    newUser?.userInfo?.userId,
    history,
    organizationId,
  ]);

  useEffect(() => {
    if (isDeleteOrganizationSuccess) {
      setShowDeleteConfirm(false);
      const notification: CreateNotificationModel = {
        header: 'Delete Organization',
        message: `${organizationDetails?.name} deleted successfully.`,
      };
      dispatch(addSuccess(notification));
      history.push('/Organizations');
    } else {
      setSkipGetOrganizationQuery(false);
    }
  }, [
    dispatch,
    history,
    isDeleteOrganizationSuccess,
    organizationDetails?.name,
  ]);

  const handleOnSearch = (queryParameters: OrganizationUserSearchQuery) => {
    setIsSearchAttempted(true);
    filteredList = organizationDetails?.organizationUsers?.length
      ? organizationDetails.organizationUsers.filter((orgUsers) => {
          return queryParameters.userEmail.length > 0
            ? orgUsers?.emailAddress
                ?.toLowerCase()
                .includes(queryParameters.userEmail.toLowerCase())
            : queryParameters.userName.length > 0
            ? orgUsers?.displayName
                ?.toLowerCase()
                .includes(queryParameters.userName.toLowerCase())
            : orgUsers;
        })
      : [];
    setFilteredUserList(filteredList);
    history.push(`?${qs.stringify(queryParameters)}`);
  };

  const confirmDeleteOrgModalBody = () => {
    return (
      <>
        <Container>
          <Row>
            <Col>
              This will permanently delete the organization in the authorization
              system.
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="my-2">
                <li>
                  <span className="font-weight-bold">
                    {organizationDetails?.name}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Card border="warning" body className="my-2 py-0">
          <Container>
            <Row>
              <Col xs="auto" className="text-warning">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="2x"
                  title="Warning"
                  name="Warning"
                />
              </Col>
              <Col>
                <p>
                  If you just want to temporarily remove the access of all users
                  in the organization, you can instead disable the organization.
                </p>
                <p>
                  This will also remove the roles assigned to users in this
                  organization.
                </p>
                <p className="mb-0">
                  Users will retain roles assigned from other organizations.
                </p>
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  };

  return (
    <>
      {isGetFeatureFlagsLoading ||
      isGetOrganizationLoading ||
      isGetOrganizationFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <LoadingSpinnerModal
            show={isDeleteOrganizationLoading || isAddOrganizationUserLoading}
          />
          {showDeleteConfirm && (
            <Confirm
              danger
              title="Delete Organization?"
              show={showDeleteConfirm}
              bodyElement={confirmDeleteOrgModalBody}
              confirmLabel="Delete Organization"
              onConfirm={handleConfirm}
              onCancel={handleConfirmCancel}
            />
          )}
          <Breadcrumb role="breadcrumb">
            <DynamicOrgBreadcrumb
              canListOrganizations={canListOrganizations ?? false}
            />
            <Breadcrumb.Item active>
              {organizationDetails?.name ?? 'Organization'}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Container>
            {organizationDetails ? (
              <>
                <Row>
                  <Col xs="auto" className="ps-2 pe-1">
                    <h2>{organizationDetails?.name}</h2>
                  </Col>
                  <Col xs="auto" className="p-1">
                    <OrganizationStatusBadge
                      isEnabled={organizationDetails?.isEnabled ?? false}
                    />
                  </Col>
                  <Col>
                    <ButtonToolbar className="float-right">
                      {organizationDetails?.securityFlags?.canDelete && (
                        <Button
                          className="my-1 mr-2"
                          variant="outline-danger"
                          onClick={handleDeleteClick}
                        >
                          Delete Organization
                        </Button>
                      )}
                      {organizationDetails?.securityFlags?.canEdit && (
                        <LinkContainer to={`${organizationId}/edit`}>
                          <Button className="my-1" variant="primary">
                            Edit
                          </Button>
                        </LinkContainer>
                      )}
                    </ButtonToolbar>
                  </Col>
                </Row>
                <Row>
                  {isAutoEnrolledEmailDomainsFeatureEnabled && (
                    <Col>
                      <p>Auto-Enrolled Email Domains:</p>
                      <ul>
                        {organizationDetails?.autoEnrollEmailSuffixes &&
                          organizationDetails?.autoEnrollEmailSuffixes.map(
                            (suffix) => {
                              return <li key={suffix}>{suffix}</li>;
                            },
                          )}
                      </ul>
                    </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Col>
                    Organization Type:{' '}
                    {
                      OrganizationType[
                        organizationDetails?.organizationType as any
                      ]
                    }
                  </Col>
                </Row>
                <Tabs
                  defaultActiveKey="organizationUsers"
                  id="organization-page-tabs"
                  className="mb-3"
                >
                  <Tab eventKey="organizationUsers" title="Users">
                    <Container>
                      <Row>
                        <Col>
                          <AddOrganizationUserModal
                            show={showAdd}
                            onAdd={handleOrganizationUserAdd}
                            onCancel={handleOrganizationUserAddCancel}
                            organizationName={organizationDetails?.name ?? ''}
                          />
                          <ButtonToolbar className="float-right mb-2">
                            {organizationDetails?.securityFlags
                              ?.canAddUsers && (
                              <Button
                                variant="outline-secondary"
                                onClick={() => {
                                  setShowAdd(true);
                                }}
                              >
                                Add Organization User
                              </Button>
                            )}
                          </ButtonToolbar>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          {organizationDetails?.securityFlags?.canListUsers && (
                            <OrganizationUserSearchForm
                              onSearch={handleOnSearch}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {organizationDetails?.securityFlags?.canListUsers &&
                            (filteredUserList.length ? (
                              <OrganizationUserSummaryTable
                                orgUsers={filteredUserList}
                              />
                            ) : isSearchAttemped ? (
                              <span className="text-muted">
                                No results found.
                              </span>
                            ) : organizationDetails?.organizationUsers &&
                              organizationDetails?.organizationUsers?.length >
                                0 ? (
                              <OrganizationUserSummaryTable
                                orgUsers={
                                  organizationDetails?.organizationUsers
                                }
                              />
                            ) : (
                              <span className="text-muted">
                                No organization users found.
                              </span>
                            ))}
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="roles" title="Roles By Profile">
                    <OrganizationRoleSummary
                      organizationDetails={organizationDetails ?? {}}
                    />
                  </Tab>
                  <Tab eventKey="profiles" title="Profiles">
                    <OrganizationProfileSummary
                      shouldLinkToProfiles={canListProfiles ?? false}
                      profiles={
                        organizationDetails?.profiles
                          ? organizationDetails?.profiles
                          : []
                      }
                    />
                  </Tab>
                </Tabs>
              </>
            ) : (
              <Container>
                <Row>
                  <Col>
                    <h2>Organization Not Found</h2>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    There was a problem retrieving the organization info.
                  </Col>
                </Row>
                <Row>
                  <Col>Please try the following options:</Col>
                </Row>
                <Row>
                  <Col>
                    <ul>
                      <li>
                        Check the link or the web page address for possible
                        typing or copy-paste errors.
                      </li>
                      <li>
                        Try refreshing the organization page or searching from
                        the organization list.
                      </li>
                      <li>
                        Contact support if you still can't find what you need.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default Organization;
