import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAltSlash, faUserCheck } from '@fortawesome/free-solid-svg-icons';

interface OrganizationUserStatusBadgeProps {
  isEnabled: boolean;
}

export const OrganizationUserStatusBadge = ({
  isEnabled,
}: OrganizationUserStatusBadgeProps) => {
  if (isEnabled) {
    return (
      <FontAwesomeIcon
        icon={faUserCheck}
        color="green"
        title="User is Enabled in the Organization"
      />
    );
  } else {
    return (
      <FontAwesomeIcon
        icon={faUserAltSlash}
        color="red"
        title="User is Disabled in the Organization"
      />
    );
  }
};

export default OrganizationUserStatusBadge;
