import { useEffect, useState } from 'react';
import { Breadcrumb, Tab, Tabs, Col, Row, Container } from 'react-bootstrap';
import { useParams } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import {
  useDeleteExternalIdentityMutation,
  useGetAllUserOrganizationsQuery,
  useGetUserQuery,
  useUpdateUserIdentifierMutation,
  useDeleteUserMutation,
} from '../../api/authorizationuiApi';
import { useGetFeatureFlagsQuery } from '../../api/featureFlagApi';
import {
  ApplicationProblemDetails,
  ExternalUserInfo,
} from '../../types/AuthorizationBFFGeneratedTypes';
import { EXTERNAL_ID_ISSUER_SGI } from '../common/Constants';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import LoadingSpinnerModal from '../common/loadingSpinner/LoadingSpinnerModal';
import { useAppDispatch } from '../hooks';
import {
  addInfo,
  addSuccess,
  CreateNotificationModel,
} from '../notifications/notificationSlice';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import { CalculatePermissions } from './components/CalculatePermissions';
import { UserIdentity } from './components/UserIdentity';
import { UserInformation } from './components/UserInformation';
import UserOrganizations from './components/UserOrganizations';
import { useHistory } from 'react-router';

interface ParamTypes {
  userId: string;
}

export const User = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { userId } = useParams<ParamTypes>();
  const [showChangeIdentifierModal, setShowChangeIdentifierModal] =
    useState(false);

  const { isLoading: isGetFeatureFlagsLoading } = useGetFeatureFlagsQuery();

  const [skipUserQuery, setSkipGetUserQuery] = useState(false);
  const {
    data: user,
    isLoading: isGetUserLoading,
    isError: isGetUserError,
    error: getUserError,
    isFetching: isGetUserFetching,
  } = useGetUserQuery(
    {
      userId: userId,
    },
    { skip: skipUserQuery },
  );

  useEffect(() => {
    if (isGetUserError) {
      apiMessageNotification(getUserError as ApplicationProblemDetails);
    }
  }, [isGetUserError, getUserError]);

  const [
    skipGetAllUserOrganizationsQuery,
    setSkipGetAllUserOrganizationsQuery,
  ] = useState(false);
  const {
    data: userOrganizations,
    isLoading: isGetUserOrganizationsLoading,
    isError: isGetUserOrganizationsError,
    error: getUserOrganizationsError,
  } = useGetAllUserOrganizationsQuery(userId, {
    skip: skipGetAllUserOrganizationsQuery,
  });

  useEffect(() => {
    if (isGetUserOrganizationsError) {
      apiMessageNotification(
        getUserOrganizationsError as ApplicationProblemDetails,
      );
    }
  }, [isGetUserOrganizationsError, getUserOrganizationsError]);

  const [
    updateUserIdentifier,
    {
      isError: isUpdateUserIdentifierError,
      error: updateUserIdentifierError,
      isSuccess: isUpdateUserIdentifierSuccess,
      isLoading: isUpdateUserIdentifierLoading,
    },
  ] = useUpdateUserIdentifierMutation();

  useEffect(() => {
    if (isUpdateUserIdentifierError) {
      apiMessageNotification(
        updateUserIdentifierError as ApplicationProblemDetails,
      );
    }
  }, [isUpdateUserIdentifierError, updateUserIdentifierError]);

  useEffect(() => {
    if (isUpdateUserIdentifierSuccess) {
      const notification: CreateNotificationModel = {
        header: 'User information saved',
        message: "User's email identifier was updated.",
      };
      dispatch(addSuccess(notification));
      setShowChangeIdentifierModal(false);
    }
  }, [dispatch, isUpdateUserIdentifierSuccess]);

  const [
    deleteExternalIdentity,
    {
      isError: isDeleteExternalIdentityError,
      error: deleteExternalIdentityError,
      isSuccess: isDeleteExternalIdentitySuccess,
      isLoading: isDeleteExternalIdentityLoading,
    },
  ] = useDeleteExternalIdentityMutation();

  const [
    deleteUser,
    {
      isError: isDeleteError,
      error: deleteError,
      isLoading: isDeleteUserLoading,
      isSuccess: isDeleteUserSuccess,
    },
  ] = useDeleteUserMutation();

  useEffect(() => {
    if (isDeleteExternalIdentityError) {
      apiMessageNotification(
        deleteExternalIdentityError as ApplicationProblemDetails,
      );
    }
  }, [isDeleteExternalIdentityError, deleteExternalIdentityError]);

  useEffect(() => {
    if (isDeleteError) {
      apiMessageNotification(deleteError as ApplicationProblemDetails);
    }
  }, [isDeleteError, deleteError]);

  const handleUserDelete = () => {
    setSkipGetUserQuery(true);
    setSkipGetAllUserOrganizationsQuery(true);
    deleteUser(user?.userId ?? '');
  };

  useEffect(() => {
    if (isDeleteUserSuccess) {
      const notification: CreateNotificationModel = {
        header: 'Delete User',
        message: `User deleted successfully.`,
      };
      dispatch(addSuccess(notification));
      history.push('/Users');
    } else {
      setSkipGetUserQuery(false);
      setSkipGetAllUserOrganizationsQuery(false);
    }
  }, [dispatch, history, isDeleteUserSuccess]);

  useEffect(() => {
    if (isDeleteExternalIdentitySuccess) {
      const notification: CreateNotificationModel = {
        header: 'User information saved',
        message: "User's identity was unlinked.",
      };
      dispatch(addSuccess(notification));
    }
  }, [dispatch, isDeleteExternalIdentitySuccess]);

  const handleOnSaveChangeIdentifier = (newIdentifier: string) => {
    if (
      newIdentifier ===
      user?.externalIds
        ?.filter((identity) => identity.issuer === EXTERNAL_ID_ISSUER_SGI)
        .map((identity) => identity.id ?? '')[0]
    ) {
      const notification: CreateNotificationModel = {
        header: 'User information not saved',
        message: "User's email identifier did not change.",
      };
      dispatch(addInfo(notification));
      setShowChangeIdentifierModal(false);
    } else {
      updateUserIdentifier({
        userId: userId,
        externalIdentity: {
          issuer: EXTERNAL_ID_ISSUER_SGI,
          id: newIdentifier,
        },
      });
    }
  };

  const handleOnClickChangeIdentifier = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setShowChangeIdentifierModal(true);
  };

  const handleOnCancelEditIdentifierModal = () => {
    setShowChangeIdentifierModal(false);
  };

  const handleOnHideEditIdentifierModal = () => {
    setShowChangeIdentifierModal(false);
  };

  const handleOnUnlinkIdentity = (identity: ExternalUserInfo) => {
    deleteExternalIdentity({
      userId: userId,
      externalIdentity: {
        issuer: identity.issuer,
        id: identity.id,
      },
    });
  };

  return (
    <>
      {isGetUserLoading ||
      isGetUserFetching ||
      isGetUserOrganizationsLoading ||
      isGetFeatureFlagsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <LoadingSpinnerModal
            show={
              isUpdateUserIdentifierLoading || isDeleteExternalIdentityLoading
            }
          />
          <Breadcrumb role="breadcrumb">
            <LinkContainer to="/Users">
              <Breadcrumb.Item>Users</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>
              {user?.displayName ? user?.displayName : user?.email ?? 'User'}
            </Breadcrumb.Item>
          </Breadcrumb>
          {user ? (
            <>
              <UserInformation
                user={user ?? {}}
                isDeleteUserLoading={isDeleteUserLoading}
                onDeleteUserConfirm={handleUserDelete}
              />
              <Tabs
                defaultActiveKey="userOrganizations"
                id="user-page-tabs"
                className="mb-3"
              >
                <Tab eventKey="userOrganizations" title="Organizations">
                  <UserOrganizations
                    userId={user?.userId ?? ''}
                    userOrganizations={userOrganizations ?? []}
                  />
                </Tab>
                <Tab eventKey="userIdentity" title="Identities">
                  <UserIdentity
                    user={user ?? {}}
                    showChangeIdentifierModal={showChangeIdentifierModal}
                    canUpdateUsers={user?.securityFlags?.canUpdate ?? false}
                    onCancelEditIdentifierModal={
                      handleOnCancelEditIdentifierModal
                    }
                    onClickChangeIdentifier={handleOnClickChangeIdentifier}
                    onHideEditIdentifierModal={handleOnHideEditIdentifierModal}
                    onSaveChangeIdentifier={handleOnSaveChangeIdentifier}
                    onUnlinkIdentity={handleOnUnlinkIdentity}
                  />
                </Tab>
                {user?.securityFlags?.canCalculateClaims && (
                  <Tab eventKey="userPermissions" title="Permissions">
                    <CalculatePermissions
                      userId={user?.userId ?? ''}
                      externalIdentifiers={user?.externalIds ?? []}
                    />
                  </Tab>
                )}
              </Tabs>
            </>
          ) : (
            <Container>
              <Row>
                <Col>
                  <h2>User Not Found</h2>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>There was a problem retrieving the user info.</Col>
              </Row>
              <Row>
                <Col>Please try the following options:</Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    <li>
                      Check the link or the web page address for possible typing
                      or copy-paste errors.
                    </li>
                    <li>
                      Try refreshing the user page or searching from the user
                      list.
                    </li>
                    <li>
                      Contact support if you still can't find what you need.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default User;
