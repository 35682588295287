import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { OrganizationDetailInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import { OrganizationRoleUserDisplay } from './OrganizationRoleUserDisplay';

interface OrganizationRoleSummaryProps {
  organizationDetails: OrganizationDetailInfo;
}
export const OrganizationRoleSummary = ({
  organizationDetails,
}: OrganizationRoleSummaryProps) => {
  const sortedProfiles = [...(organizationDetails?.profiles || [])].sort(
    (a, b) => (a.name ?? '').localeCompare(b.name ?? ''),
  );
  return (
    <Container>
      {sortedProfiles &&
        sortedProfiles.map((profile) => {
          const profileRoles = organizationDetails.profileDefaultRoles?.filter(
            (role) => {
              return role.profileId === profile.profileId;
            },
          );
          return (
            <React.Fragment key={profile.profileId}>
              <Row>
                <Col>
                  <h4 key={profile.profileId}>{profile.name}</h4>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  {profileRoles &&
                    profileRoles
                      ?.sort((a, b) =>
                        (a.name ?? '').localeCompare(b.name ?? ''),
                      )
                      .map((role) => {
                        const users =
                          organizationDetails.organizationUsers?.filter(
                            (user) => {
                              return user.profileDefaultRoleIds?.includes(
                                role.profileDefaultRoleId ?? '',
                              );
                            },
                          );
                        return (
                          <OrganizationRoleUserDisplay
                            roleName={role.name ?? ''}
                            isPrivilegedRole={role.isPrivilegedRole ?? false}
                            users={users ?? []}
                            key={role.profileDefaultRoleId}
                            canListUsers={
                              organizationDetails.securityFlags?.canListUsers ??
                              false
                            }
                          />
                        );
                      })}
                </Col>
              </Row>
            </React.Fragment>
          );
        })}
    </Container>
  );
};

export default OrganizationRoleSummary;
