import { Nav, Navbar, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SignInSignOutButton from './components/SignInSignOutButton';
import { useGetFeatureFlagsQuery } from '../../api/featureFlagApi';
import { ReactComponent as Logo } from '../../assets/sgi-logo.svg';
import './NavigationBar.css';

function NavigationBar() {
  const {
    data: featureFlags,
    isLoading: isGetFeatureFlagsQueryLoading,
    isFetching: isGetFeatureFlagsQueryFetching,
  } = useGetFeatureFlagsQuery();
  const {
    canListProfiles,
    canListApplications,
    canListUsers,
    canListOrganizations,
  } = featureFlags || {};

  return (
    <div className="container-fluid p-0">
      <Navbar bg="light" expand="lg" fixed="top">
        <div className="container">
          <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
            <Logo
              title="SGI Authorization Administration"
              className="pr-2 navbar-logo"
            />
            {/* Display app name based on screen size */}
            <span className="d-none d-xl-inline">
              Authorization Administration
            </span>
            <span className="d-none d-md-inline d-xl-none">
              Authorization Admin
            </span>
            <span className="d-inline d-md-none">Auth Admin</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {isGetFeatureFlagsQueryLoading ||
              isGetFeatureFlagsQueryFetching ? (
                <Spinner
                  variant="secondary"
                  size="sm"
                  animation="border"
                  role="status"
                  className="my-2"
                >
                  <span className="sr-only">Loading menu...</span>
                </Spinner>
              ) : (
                <>
                  {canListApplications && (
                    <Link
                      aria-current="page"
                      className="nav-link"
                      to="/Applications"
                    >
                      Applications
                    </Link>
                  )}
                  {canListProfiles && (
                    <Link
                      aria-current="page"
                      className="nav-link"
                      to="/Profiles"
                    >
                      Profiles
                    </Link>
                  )}
                  {canListOrganizations ? (
                    <Link
                      aria-current="page"
                      className="nav-link"
                      to="/Organizations"
                    >
                      Organizations
                    </Link>
                  ) : (
                    <Link aria-current="page" className="nav-link" to="/">
                      Organizations
                    </Link>
                  )}
                  {canListUsers && (
                    <Link aria-current="page" className="nav-link" to="/Users">
                      Users
                    </Link>
                  )}
                </>
              )}
            </Nav>
            <Nav>
              <SignInSignOutButton />
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
}

export default NavigationBar;
