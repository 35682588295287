import { Col, Row } from 'react-bootstrap';

export default function NotAuthorized() {
  return (
    <Col md={{ span: 8, offset: 2 }}>
      <Row>
        <Col>
          <h2>Unauthorized</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>You are not authorized to view this page</h3>
        </Col>
      </Row>
    </Col>
  );
}
