import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import {
  useGetOrganizationQuery,
  useGetOrganizationUserQuery,
  useRemoveOrganizationUserMutation,
} from '../../api/authorizationuiApi';
import { useGetFeatureFlagsQuery } from '../../api/featureFlagApi';
import { ApplicationProblemDetails } from '../../types/AuthorizationBFFGeneratedTypes';
import { Confirm } from '../common/Confirm';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import LoadingSpinnerModal from '../common/loadingSpinner/LoadingSpinnerModal';
import { useAppDispatch } from '../hooks';
import {
  addSuccess,
  CreateNotificationModel,
} from '../notifications/notificationSlice';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import { OrganizationUserInfoView } from './components/OrganizationUserInfoView';

interface ParamTypes {
  organizationId: string;
  userId: string;
}

export const OrganizationUser = () => {
  const { organizationId, userId } = useParams<ParamTypes>();
  const dispatch = useAppDispatch();

  const [skipGetOrganizationUserQuery, setSkipGetOrganizationUserQuery] =
    useState(false);
  const {
    data: organizationUserDetails,
    isLoading: isGetOrganizationUserLoading,
    isError: isGetOrganizationUserError,
    error: getOrganizationUserError,
    isFetching: isGetOrganizationUserFetching,
  } = useGetOrganizationUserQuery(
    {
      organizationId,
      userId,
    },
    { skip: skipGetOrganizationUserQuery },
  );

  const {
    data: organization,
    isLoading: isGetOrganizationLoading,
    isError: isGetOrganizationError,
    error: getOrganizationError,
    isFetching: isGetOrganizationFetching,
  } = useGetOrganizationQuery(organizationId);

  const [
    removeOrganziationUser,
    {
      isError: isErrorRemoveOrgUser,
      error: errorRemoveOrgUser,
      isLoading: isRemoveOrgUserLoading,
      isSuccess: isRemoveOrgUserSuccess,
    },
  ] = useRemoveOrganizationUserMutation();
  const history = useHistory();
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);

  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const { canListOrganizations } = featureFlags || {};

  useEffect(() => {
    if (isGetOrganizationError) {
      apiMessageNotification(getOrganizationError as ApplicationProblemDetails);
    }
  }, [isGetOrganizationError, getOrganizationError]);

  useEffect(() => {
    if (isGetOrganizationUserError) {
      apiMessageNotification(
        getOrganizationUserError as ApplicationProblemDetails,
      );
    }
  }, [isGetOrganizationUserError, getOrganizationUserError]);

  useEffect(() => {
    if (isErrorRemoveOrgUser) {
      apiMessageNotification(errorRemoveOrgUser as ApplicationProblemDetails);
    }
  }, [isErrorRemoveOrgUser, errorRemoveOrgUser]);

  const handleEditClick = () => {
    history.push(
      `/Organization/${organization?.organizationId}/User/${organizationUserDetails?.userInfo?.userId}/edit`,
    );
  };

  const handleRemoveClick = () => {
    setShowRemoveConfirm(true);
  };

  const handleRemoveConfirm = () => {
    setSkipGetOrganizationUserQuery(true);
    removeOrganziationUser({
      organizationId: organization?.organizationId as string,
      userId: organizationUserDetails?.userInfo?.userId as string,
    });
  };

  useEffect(() => {
    if (isRemoveOrgUserSuccess) {
      setShowRemoveConfirm(false);
      const notification: CreateNotificationModel = {
        header: 'Remove User from Organization',
        message: `${
          organizationUserDetails?.userInfo?.displayName
            ? organizationUserDetails?.userInfo?.displayName
            : organizationUserDetails?.userInfo?.email
        } removed from ${organization?.name} successfully.`,
      };
      dispatch(addSuccess(notification));
      history.push(`/Organization/${organization?.organizationId}`);
    } else {
      setSkipGetOrganizationUserQuery(false);
    }
  }, [
    dispatch,
    history,
    isRemoveOrgUserSuccess,
    organization?.name,
    organization?.organizationId,
    organizationUserDetails?.userInfo?.displayName,
    organizationUserDetails?.userInfo?.email,
  ]);

  const handleRemoveCancel = () => {
    setShowRemoveConfirm(false);
  };

  const confirmRemoveUserModalBody = () => {
    return (
      <>
        <Container>
          <Row>
            <Col>
              This will remove the user from the organization in the
              authorization system.
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="my-2">
                <li>
                  <span className="font-weight-bold">
                    {organizationUserDetails?.userInfo?.displayName
                      ? `${organizationUserDetails?.userInfo?.displayName} (${organizationUserDetails?.userInfo?.email})`
                      : organizationUserDetails?.userInfo?.email}
                  </span>{' '}
                  from{' '}
                  <span className="font-weight-bold">{organization?.name}</span>{' '}
                  organization.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Card border="warning" body className="my-2 py-0">
          <Container>
            <Row>
              <Col xs="auto" className="text-warning">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="2x"
                  title="Warning"
                  name="Warning"
                />
              </Col>
              <Col>
                <p>
                  If you just want to temporarily remove the access of this user
                  in the organization, you can instead disable the user.
                </p>
                <p>
                  This will also remove the roles assigned to the user in this
                  organization.
                </p>
                <p className="mb-0">
                  User will retain roles assigned from other organizations.
                </p>
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  };

  return (
    <>
      {isGetOrganizationUserLoading ||
      isGetOrganizationUserFetching ||
      isGetOrganizationLoading ||
      isGetOrganizationFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <LoadingSpinnerModal show={isRemoveOrgUserLoading} />
          <OrganizationUserInfoView
            organizationUser={organizationUserDetails ?? {}}
            organization={organization ?? {}}
            canListOrganizations={canListOrganizations ?? false}
            onEditClick={handleEditClick}
            onRemoveClick={handleRemoveClick}
          />
          <Confirm
            danger
            show={showRemoveConfirm}
            title="Remove User from Organization?"
            bodyElement={confirmRemoveUserModalBody}
            confirmLabel="Remove User"
            onConfirm={handleRemoveConfirm}
            onCancel={handleRemoveCancel}
          />
        </>
      )}
    </>
  );
};

export default OrganizationUser;
