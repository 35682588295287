import { Modal } from 'react-bootstrap';
import './LoadingSpinner.css';

interface LoadingSpinnerModalProps {
  show: boolean;
}

const LoadingSpinnerModal = ({ show }: LoadingSpinnerModalProps) => {
  return (
    <Modal
      show={show}
      animation={false}
      backdrop="static"
      centered
      contentClassName="bg-transparent border-0"
      backdropClassName="bg-light loading-modal-backdrop"
      dialogClassName="loading-modal-dialog"
      className="loading-modal"
    >
      <Modal.Body>
        <div>
          <ul className="loader" id="loadingImage" title="Loading...">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingSpinnerModal;
