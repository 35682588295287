import { useEffect, useState } from 'react';
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import ProfileTable from './components/ProfileTable';
import {
  ProfileSearchForm,
  ProfileSearchQuery,
} from './components/ProfileSearchForm';
import {
  useGetProfileSummariesQuery,
  useGetAllApplicationsQuery,
} from '../../api/authorizationuiApi';
import {
  ApplicationProblemDetails,
  ProfileSummaryInfo,
} from '../../types/AuthorizationBFFGeneratedTypes';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import qs from 'qs';
import { useHistory } from 'react-router';

export const ProfilesSummary = () => {
  const history = useHistory();
  const {
    data: profiles,
    isError: isProfileError,
    error: profileError,
    isLoading: isProfileLoading,
  } = useGetProfileSummariesQuery();
  const {
    data: applications,
    isError: isApplicationError,
    error: applicationError,
    isLoading: isAppLoading,
  } = useGetAllApplicationsQuery();
  const [filteredProfileList, setFilteredProfileList] = useState(
    [] as ProfileSummaryInfo[],
  );
  const [isSearchAttemped, setIsSearchAttempted] = useState(false);
  let filteredList = [] as ProfileSummaryInfo[];

  useEffect(() => {
    if (isProfileError) {
      apiMessageNotification(profileError as ApplicationProblemDetails);
    }
    if (isApplicationError) {
      apiMessageNotification(applicationError as ApplicationProblemDetails);
    }
  }, [isProfileError, profileError, isApplicationError, applicationError]);

  const handleOnSearch = (queryParameters: ProfileSearchQuery) => {
    setIsSearchAttempted(true);
    filteredList =
      profiles && profiles.length > 0
        ? profiles.filter((profile) => {
            return queryParameters.profileName.length > 0
              ? profile?.name
                  ?.toLowerCase()
                  .includes(queryParameters.profileName.toLowerCase())
              : queryParameters.profileSourceApp.length > 0
              ? profile?.sourceApplicationManifestName
                  ?.toLowerCase()
                  .includes(queryParameters.profileSourceApp.toLowerCase())
              : profile;
          })
        : [];
    setFilteredProfileList(filteredList);
    history.push(`?${qs.stringify(queryParameters)}`);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Profiles</Breadcrumb.Item>
      </Breadcrumb>
      {isProfileLoading || isAppLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Container>
            <Row className="mb-2">
              <Col>
                <h2>Profiles</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <ProfileSearchForm
                  appNames={
                    applications
                      ? applications?.map((apps) => apps.applicationName ?? '')
                      : []
                  }
                  onSearch={handleOnSearch}
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                {filteredProfileList && filteredProfileList.length > 0 ? (
                  <ProfileTable profiles={filteredProfileList} />
                ) : isSearchAttemped ? (
                  <span className="text-muted">No profiles found.</span>
                ) : (
                  []
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ProfilesSummary;
