import { useEffect } from 'react';
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap';
import { useGetAllApplicationsQuery } from '../../api/authorizationuiApi';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import ApplicationTable from './components/ApplicationTable';
import { ApplicationProblemDetails } from '../../types/AuthorizationBFFGeneratedTypes';
import { apiMessageNotification } from '../redux/apiMessageNotification';

function ApplicationsSummary() {
  const {
    data: applications,
    isLoading,
    isError,
    error,
  } = useGetAllApplicationsQuery();
  useEffect(() => {
    if (isError) {
      apiMessageNotification(error as ApplicationProblemDetails);
    }
  }, [isError, error]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Applications</Breadcrumb.Item>
      </Breadcrumb>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Container>
            <Row className="mb-2">
              <Col>
                <h2>Applications</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <ApplicationTable apps={applications ?? []} />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default ApplicationsSummary;
