import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import {
  OrganizationProfileSummaryInfo,
  ProfileUsageType,
} from '../../../types/AuthorizationBFFGeneratedTypes';
import { useHistory } from 'react-router';
import qs from 'qs';

export interface OrganizationSearchQuery {
  organizationName: string;
  profileId: string;
}

interface OrganizationSearchProps {
  profiles: OrganizationProfileSummaryInfo[];
  onSearch: (queryParameters: OrganizationSearchQuery) => void;
}

export const OrganizationSearchForm = ({
  profiles,
  onSearch,
}: OrganizationSearchProps) => {
  const history = useHistory();
  const [autoRunFilter, setAutoRunFilter] = useState(false);
  const [organizationQuery, setOrganizationQuery] = useState(
    {} as OrganizationSearchQuery,
  );

  useEffect(() => {
    const filterParams = history.location.search.substring(1);
    const filtersFromParams = qs.parse(filterParams);
    if (
      filtersFromParams.organizationName?.toString() ||
      filtersFromParams.profileId?.toString()
    ) {
      setOrganizationQuery({
        organizationName: filtersFromParams.organizationName?.toString() ?? '',
        profileId: filtersFromParams.profileId?.toString() ?? '',
      });
      setAutoRunFilter(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (autoRunFilter === true) {
      setAutoRunFilter(false);
      performQuerySearch();
    }
    // eslint-disable-next-line
  }, [organizationQuery]);

  const handleQueryUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    let inputField = event.target.name;
    let inputValue = event.target.value;
    setOrganizationQuery({ ...organizationQuery, [inputField]: inputValue });
  };

  const handleQuerySearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    performQuerySearch();
  };

  const performQuerySearch = async () => {
    let queryParameters = {
      organizationName: (organizationQuery.organizationName ?? '').toString(),
      profileId: (organizationQuery.profileId ?? '').toString(),
    } as OrganizationSearchQuery;
    onSearch(queryParameters);
  };

  return (
    <>
      <Form noValidate onSubmit={handleQuerySearch}>
        <Form.Row className="align-items-center mb-4">
          <Col xs="auto" lg="4" className="mt-3">
            <Form.Group controlId="Organization name">
              <Form.Label srOnly>Organization Name</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      icon={faSearch}
                      title="Search"
                      name="Search"
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  autoFocus
                  type="text"
                  maxLength={255}
                  placeholder="Organization Name"
                  value={organizationQuery.organizationName ?? ''}
                  name="organizationName"
                  onChange={handleQueryUpdate}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs="auto" className="mt-3">
            <Form.Group controlId="Profile Name">
              <Form.Label srOnly>Profile Name</Form.Label>
              <Form.Control
                as="select"
                value={organizationQuery.profileId?.toString() ?? ''}
                style={{ width: 'auto' }}
                name="profileId"
                onChange={handleQueryUpdate}
              >
                <option value={''}>All profiles</option>
                <optgroup label="External">
                  {profiles
                    .filter(
                      (p) =>
                        p.usageType === ProfileUsageType.ExternalOrganization ||
                        p.usageType === ProfileUsageType.ExternalIndividual,
                      true,
                    )
                    .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
                    .map((profile) => (
                      <option key={profile.profileId} value={profile.profileId}>
                        {profile.name}
                      </option>
                    ))}
                </optgroup>
                <optgroup label="Internal">
                  {profiles
                    .filter(
                      (p) => p.usageType === ProfileUsageType.Internal,
                      true,
                    )
                    .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
                    .map((profile) => (
                      <option key={profile.profileId} value={profile.profileId}>
                        {profile.name}
                      </option>
                    ))}
                </optgroup>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Button className="m-1" variant="primary" type="submit">
              Search
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </>
  );
};
