import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { apiMessageNotification } from '../redux/apiMessageNotification';
import {
  addSuccess,
  CreateNotificationModel,
} from '../notifications/notificationSlice';
import EditOrganizationForm, {
  getProfileIds,
} from './components/EditOrganizationForm';
import {
  ApplicationProblemDetails,
  OrganizationDetailInfo,
  OrganizationProfileSummaryInfo,
  OrganizationType,
  OrganizationUpdateInfo,
} from '../../types/AuthorizationBFFGeneratedTypes';
import { Breadcrumb } from 'react-bootstrap';
import DynamicOrgBreadcrumb from '../common/DynamicOrgBreadcrumb';
import LoadingSpinner from '../common/loadingSpinner/LoadingSpinner';
import LoadingSpinnerModal from '../common/loadingSpinner/LoadingSpinnerModal';
import { useAppDispatch } from '../hooks';
import {
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
  useGetOrganizationProfileSummariesQuery,
} from '../../api/authorizationuiApi';
import { useGetFeatureFlagsQuery } from '../../api/featureFlagApi';

interface ParamTypes {
  organizationId: string;
}

export const EditOrganization = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { organizationId } = useParams<ParamTypes>();

  const {
    data: organizationDetailInfo,
    isError: isGetOrganizationQueryError,
    error: getOrganizationQueryError,
    isLoading: isGetOrganizationQueryLoading,
    isSuccess: isGetOrganizationQuerySuccess,
  } = useGetOrganizationQuery(organizationId);

  const [organizationDetailInfoData, setOrganizationDetailInfoData] =
    useState<OrganizationDetailInfo>(organizationDetailInfo ?? {});

  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const { canListOrganizations } = featureFlags || {};

  useEffect(() => {
    if (isGetOrganizationQueryError) {
      apiMessageNotification(
        getOrganizationQueryError as ApplicationProblemDetails,
      );
    }
  }, [isGetOrganizationQueryError, getOrganizationQueryError]);

  useEffect(() => {
    if (isGetOrganizationQuerySuccess && organizationDetailInfo) {
      setOrganizationDetailInfoData(organizationDetailInfo);
    }
  }, [isGetOrganizationQuerySuccess, organizationDetailInfo]);

  const {
    data: availableProfiles,
    isError: isAvailableProfilesQueryError,
    error: availableProfilesQueryError,
    isLoading: isAvailableProfilesQueryLoading,
  } = useGetOrganizationProfileSummariesQuery();

  useEffect(() => {
    if (isAvailableProfilesQueryError) {
      apiMessageNotification(
        availableProfilesQueryError as ApplicationProblemDetails,
      );
    }
  }, [isAvailableProfilesQueryError, availableProfilesQueryError]);

  const [
    updateOrganization,
    {
      isError: isUpdateOrganizationError,
      error: updateOrganizationError,
      isSuccess: isUpdateOrganizationSuccess,
      isLoading: isUpdateOrganizationLoading,
    },
  ] = useUpdateOrganizationMutation();

  useEffect(() => {
    if (isUpdateOrganizationError) {
      apiMessageNotification(
        updateOrganizationError as ApplicationProblemDetails,
      );
    }
  }, [isUpdateOrganizationError, updateOrganizationError]);

  useEffect(() => {
    if (isUpdateOrganizationSuccess) {
      const notification: CreateNotificationModel = {
        header: 'Edit Organization',
        message: `${organizationDetailInfoData?.name} saved.`,
      };
      dispatch(addSuccess(notification));
      history.push(`/Organization/${organizationId}`);
    }
  }, [
    dispatch,
    history,
    isUpdateOrganizationSuccess,
    organizationDetailInfoData?.name,
    organizationId,
  ]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleOrganizationTypeAndProfilesChange = (
    newOrganizationType: OrganizationType | null,
    invalidProfiles: OrganizationProfileSummaryInfo[],
  ) => {
    const updatedProfiles = organizationDetailInfoData?.profiles?.filter(
      (profile) =>
        !invalidProfiles.map((p) => p.profileId).includes(profile.profileId),
    );
    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      organizationType: newOrganizationType,
      profiles: updatedProfiles,
    });
  };

  const handleOrganizationTypeChange = (
    newOrganizationType: OrganizationType | null,
  ) => {
    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      organizationType: newOrganizationType,
    });
  };

  const handleOnEmailDomainAdded = (emailDomain: string) => {
    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      autoEnrollEmailSuffixes: [
        ...(organizationDetailInfoData.autoEnrollEmailSuffixes ?? []),
        emailDomain,
      ],
    });
  };

  const handleOnEmailDomainChanged = (emailDomain: string, index: number) => {
    const emailDomains = [
      ...(organizationDetailInfoData.autoEnrollEmailSuffixes ?? []),
    ];

    emailDomains[index] && (emailDomains[index] = emailDomain);

    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      autoEnrollEmailSuffixes: emailDomains,
    });
  };

  const handleOnEmailDomainDeleted = (index: number) => {
    const newList = [
      ...(organizationDetailInfoData.autoEnrollEmailSuffixes ?? []),
    ];
    newList.splice(index, 1);

    setOrganizationDetailInfoData({
      ...organizationDetailInfoData,
      autoEnrollEmailSuffixes: newList,
    });
  };

  const handleProfileSelectionChange = (
    profileId: string,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      //add to list
      setOrganizationDetailInfoData((currentData) => ({
        ...currentData,
        profiles: [...(currentData?.profiles ?? []), { profileId: profileId }],
      }));
    } else {
      //remove from list
      const updatedProfiles = organizationDetailInfoData?.profiles?.filter(
        (profile) => profile.profileId !== profileId,
      );
      setOrganizationDetailInfoData((currentData) => ({
        ...currentData,
        profiles: updatedProfiles,
      }));
    }
  };

  const handleCancel = (event: React.SyntheticEvent) => {
    history.goBack();
  };

  const handleSaveOrganization = () => {
    let organizationUpdateInfo: OrganizationUpdateInfo = {
      organizationId: organizationId ?? '',
      name: organizationDetailInfoData?.name ?? '',
      isEnabled: organizationDetailInfoData?.isEnabled ?? false,
      autoEnrollEmailSuffixes:
        organizationDetailInfoData?.autoEnrollEmailSuffixes,
      profiles: getProfileIds(organizationDetailInfoData?.profiles ?? []),
      organizationType:
        organizationDetailInfoData?.organizationType ??
        OrganizationType.Undefined,
      versionNumber: organizationDetailInfo?.versionNumber,
    };

    if (organizationId) {
      updateOrganization({
        organizationId: organizationId,
        updateInfo: organizationUpdateInfo,
      });
    }
  };

  return (
    <>
      {isAvailableProfilesQueryLoading || isGetOrganizationQueryLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <LoadingSpinnerModal show={isUpdateOrganizationLoading} />
          <Breadcrumb>
            <DynamicOrgBreadcrumb
              canListOrganizations={canListOrganizations ?? false}
            />
            <Breadcrumb.Item active>
              {organizationDetailInfo?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
          {organizationDetailInfoData && (
            <EditOrganizationForm
              organizationDetailInfo={organizationDetailInfoData}
              availableProfiles={availableProfiles ?? []}
              onInputChange={handleInputChange}
              onOrganizationTypeChange={handleOrganizationTypeChange}
              onOrganizationTypeAndProfilesChange={
                handleOrganizationTypeAndProfilesChange
              }
              onProfileSelectionChange={handleProfileSelectionChange}
              onSaveOrganization={handleSaveOrganization}
              onCancel={handleCancel}
              onEmailDomainAdded={handleOnEmailDomainAdded}
              onEmailDomainChanged={handleOnEmailDomainChanged}
              onEmailDomainDeleted={handleOnEmailDomainDeleted}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditOrganization;
