import { Switch, useRouteMatch } from 'react-router';
import PrivateRoute from '../common/PrivateRoute';
import EditOrganizationUser from './EditOrganizationUser';
import OrganizationUser from './OrganizationUser';

const OrganizationUserRouter = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <PrivateRoute
          Route
          exact
          path={`${path}/edit`}
          component={EditOrganizationUser}
          requiredPermissions={[]}
          redirectOnUnAuthorized
        />
        <PrivateRoute
          Route
          exact
          path={`${path}`}
          component={OrganizationUser}
          requiredPermissions={[]}
          redirectOnUnAuthorized
        />
      </Switch>
    </>
  );
};

export default OrganizationUserRouter;
