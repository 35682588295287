import { FeatureFlagModel } from '../types/AuthorizationBFFGeneratedTypes';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import RtkTagTypes from './rtkTags';
import { axiosBaseQuery } from './axiosBaseQuery';

export const featureFlagApi = createApi({
  reducerPath: 'featureFlagApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [RtkTagTypes.FEATUREFLAGS],
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<FeatureFlagModel, void>({
      query: () => ({ url: 'featureflags', method: 'GET' }),
      providesTags: (result) =>
        result ? [{ type: RtkTagTypes.FEATUREFLAGS, id: 'LIST' }] : [],
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featureFlagApi;
