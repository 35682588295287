import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface AddUserModalPros {
  show: boolean;
  onAdd: (email: string) => void;
  onCancel: () => void;
}

export const isValidEmail = (email: string) => {
  const validEmailFormat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  if (!email || !email.match(validEmailFormat)) {
    return false;
  } else {
    return true;
  }
};

export const AddUserModal = ({ show, onAdd, onCancel }: AddUserModalPros) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const onEmailChange = (email: string) => {
    setEmail(email);
    setError(false);
  };

  const handleAddClick = (e: any) => {
    if (isValidEmail(email)) {
      e.preventDefault();
      onAdd(email);
      setEmail('');
    } else {
      e.preventDefault();
      setError(true);
    }
  };

  const handleCancel = () => {
    onCancel();
    setEmail('');
    setError(false);
  };

  return (
    <Modal animation={false} show={show}>
      <Form onSubmit={handleAddClick}>
        <Modal.Header closeButton onHide={handleCancel}>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} controlId="addEmail">
            <Form.Label column sm="2">
              Email
            </Form.Label>
            <Col>
              <Form.Control
                autoFocus
                isInvalid={error}
                onChange={(event) => {
                  onEmailChange(event.target.value);
                }}
              />
              {error && (
                <Form.Text className="text-danger">
                  Please enter a valid email address
                </Form.Text>
              )}
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="mr-2"
            variant="outline-secondary"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={(e) => {
              handleAddClick(e);
            }}
            disabled={error}
          >
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
