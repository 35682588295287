import { Accordion, Card, Badge } from 'react-bootstrap';
import { OrganizationUserSummaryInfo } from '../../../types/AuthorizationBFFGeneratedTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { OrganizationUserSummaryTable } from './OrganizationUserSummaryTable';
import PrivilegedRoleBadge from '../../common/PrivilegedRoleBadge';

interface OrganizationRoleUserDisplayProps {
  roleName: string;
  isPrivilegedRole?: boolean;
  users: OrganizationUserSummaryInfo[];
  key?: string;
  canListUsers: boolean;
}

export const OrganizationRoleUserDisplay = ({
  roleName,
  isPrivilegedRole,
  users,
  canListUsers,
}: OrganizationRoleUserDisplayProps) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <Accordion defaultActiveKey="1">
        <Card>
          {canListUsers ? (
            users.length > 0 ? (
              <span style={{ cursor: 'pointer' }}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                >
                  {roleName}
                  {isPrivilegedRole && <PrivilegedRoleBadge />}
                  {users.length > 0 && (
                    <>
                      {' '}
                      <FontAwesomeIcon icon={faUser} />{' '}
                      <Badge variant="primary">{users.length}</Badge>
                      <span className="float-right">
                        <FontAwesomeIcon
                          icon={collapsed ? faAngleDoubleDown : faAngleDoubleUp}
                          aria-label="toggle-icon"
                        />
                      </span>
                    </>
                  )}
                </Accordion.Toggle>
              </span>
            ) : (
              <Card.Header>
                {roleName}
                {isPrivilegedRole && <PrivilegedRoleBadge />}
              </Card.Header>
            )
          ) : (
            <Card.Header className="bg-white">{roleName}</Card.Header>
          )}

          <Accordion.Collapse eventKey="0">
            <Card.Body className="pb-0">
              {canListUsers && (
                <OrganizationUserSummaryTable orgUsers={users} pageSize={10} />
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default OrganizationRoleUserDisplay;
