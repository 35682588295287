import { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import {
  OrganizationProfileSummaryInfo,
  OrganizationType,
  ProfileUsageType,
} from '../../../types/AuthorizationBFFGeneratedTypes';

export interface ProfileSummaryInfoSelection
  extends OrganizationProfileSummaryInfo {
  isSelected: boolean;
  isValid: boolean;
}

export interface EditOrganizationProfileTableProps {
  organizationProfiles: OrganizationProfileSummaryInfo[];
  availableProfiles: OrganizationProfileSummaryInfo[];
  organizationType: OrganizationType | null;
  isSaveAttempted: boolean;
  handleProfileSelectionChanged: (
    profileId: string,
    isSelected: boolean,
  ) => void;
}

const isValidProfile = (
  profileUsageType: ProfileUsageType,
  organizationType: OrganizationType,
): boolean =>
  organizationType === OrganizationType.Undefined ||
  (profileUsageType === ProfileUsageType.Internal &&
    organizationType === OrganizationType.Internal) ||
  (profileUsageType === ProfileUsageType.ExternalOrganization &&
    organizationType === OrganizationType.External);

const mapToSelectableProfiles = (
  organizationProfiles: OrganizationProfileSummaryInfo[],
  availableProfiles: OrganizationProfileSummaryInfo[],
  organizationType: OrganizationType | null,
  isSaveAttempted: boolean,
): ProfileSummaryInfoSelection[] => {
  return availableProfiles.map((availableProfile) => {
    const isSelected = organizationProfiles.some(
      (orgProfile) => orgProfile.profileId === availableProfile.profileId,
    );

    const isValid =
      !isSaveAttempted ||
      organizationType === null ||
      (!!availableProfile.usageType &&
        isValidProfile(availableProfile.usageType, organizationType));

    return { ...availableProfile, isSelected, isValid };
  });
};

export const EditOrganizationProfilesTable = ({
  organizationProfiles,
  availableProfiles,
  organizationType,
  isSaveAttempted,
  handleProfileSelectionChanged,
  ...props
}: EditOrganizationProfileTableProps) => {
  const [selectableProfiles, setSelectableProfiles] =
    useState<ProfileSummaryInfoSelection[]>();

  useEffect(() => {
    setSelectableProfiles(
      mapToSelectableProfiles(
        organizationProfiles,
        availableProfiles,
        organizationType,
        isSaveAttempted,
      ),
    );
  }, [
    organizationProfiles,
    availableProfiles,
    organizationType,
    isSaveAttempted,
  ]);

  const showProfile = (profile: ProfileSummaryInfoSelection): boolean =>
    organizationType !== null &&
    profile.usageType !== ProfileUsageType.ExternalIndividual &&
    (profile.isSelected ||
      (organizationType !== OrganizationType.Undefined &&
        !!profile.usageType &&
        isValidProfile(profile.usageType, organizationType)));

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Description</th>
            <th>Usage Type</th>
          </tr>
        </thead>
        <tbody>
          {selectableProfiles &&
            selectableProfiles.filter(showProfile).map((profile) => {
              return (
                <tr
                  key={profile.profileId}
                  className={!profile.isValid ? 'text-danger' : ''}
                >
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={profile.isSelected}
                      onChange={(e) =>
                        handleProfileSelectionChanged(
                          profile.profileId ?? '',
                          e.target.checked,
                        )
                      }
                      disabled={
                        !profile.canMaintainOrganizationProfileAssociation
                      }
                      isInvalid={!profile.isValid}
                    />
                  </td>
                  <td>{profile.name}</td>
                  <td>{profile.description}</td>
                  <td>
                    {profile.usageType === ProfileUsageType.Internal
                      ? 'Internal'
                      : 'External'}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

export default EditOrganizationProfilesTable;
