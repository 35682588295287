import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';

interface EditUserIdentifierModalProps {
  show: boolean;
  userIdentifier: string;
  onHide: () => void;
  onCancel: () => void;
  onSave: (newIdentifier: string) => void;
}

export const EditUserIdentifierModal = ({
  show,
  userIdentifier,
  onSave,
  onCancel,
  onHide,
}: EditUserIdentifierModalProps) => {
  const [validated, setValidated] = useState(false);
  const [identifier, setIdentifier] = useState(userIdentifier);

  const handleOnCancel = () => {
    setIdentifier(userIdentifier);
    setValidated(false);
    onCancel();
  };

  const handleOnHide = () => {
    setIdentifier(userIdentifier);
    setValidated(false);
    onHide();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdentifier(event.target.value);
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      onSave(identifier);
      setValidated(false);
    }
  };

  return (
    <>
      <Modal show={show}>
        <Form noValidate validated={validated} onSubmit={handleOnSubmit}>
          <Modal.Header closeButton={true} onHide={handleOnHide}>
            <Modal.Title>Change Email Identifier</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form.Group controlId="identifier">
                <Form.Label className="font-weight-bold">
                  Email Identifier
                </Form.Label>
                <Form.Row>
                  <Col>
                    <Form.Control
                      autoFocus
                      required
                      type="email"
                      maxLength={255}
                      placeholder=""
                      value={identifier}
                      name="identifier"
                      onChange={handleOnChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Row>
              </Form.Group>
            </Container>
            <Card border="warning" body className="my-2 py-0">
              <Container>
                <Row>
                  <Col xs="auto" className="text-warning">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      size="2x"
                      title="Warning"
                      name="Warning"
                    />
                  </Col>
                  <Col>
                    <p className="mb-0">
                      On next sign in, the user will be linked to the identity
                      with the new email address.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              className="mr-2"
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditUserIdentifierModal;
