import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { EnvironmentVariableConfig } from './types/EnvironmentVariableConfig';
import { EnvironmentVariableContext } from './store/EnvironmentVariableContext';
import { initializeMsalInstance } from './auth/initializeMsal';
import { ai } from './AppInsights';
import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import './styles/custom.css';

fetch('/config.json')
  .then((response) => response.json())
  .then((config) => {
    (window as any)['runConfig'] = config;
    initializeMsalInstance();
    ai.initialize(
      (window as any)['runConfig']?.REACT_APP_APPINSIGHTS_INSTR_KEY,
    ).then(({ appInsights, reactPlugin }) => {
      renderApp(config, reactPlugin);
    });
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });

function renderApp(
  runtimeConfig?: EnvironmentVariableConfig,
  reactPlugin?: ReactPlugin,
) {
  ReactDOM.render(
    <React.StrictMode>
      {runtimeConfig !== undefined ? (
        <Provider store={store}>
          <EnvironmentVariableContext.Provider value={runtimeConfig!}>
            <AppInsightsContext.Provider value={reactPlugin!}>
              <App />
            </AppInsightsContext.Provider>
          </EnvironmentVariableContext.Provider>
        </Provider>
      ) : (
        <div>
          There was an error loading the application. Please contact support.
        </div>
      )}
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
